import {
  Autocomplete,
  Avatar,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Tooltip,
  Box,
  Button,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SyncIcon from "@mui/icons-material/Sync";

import EditIcon from "@mui/icons-material/Edit";
import { makeStyles } from "@mui/styles";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import CloseIcon from "@mui/icons-material/Close";
import { ExerciseListingApi } from "src/DAL/exercise/exercise";

import { s3baseUrl } from "src/config/config";

import faker from "faker";

// import CustomPopovers from "src/components/GeneralComponents/CustomPopovers";
// import ToshowExercise from "./components/modalToshowExercise";
// import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";

import Label from "src/components/Label";
import ToshowExercise from "./component/modalToshowExercise";

import ConfirmationWithDescription from "src/components/ModalPopover/ConfirmationWithDescription";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 20,
    },
  },
};
const Input = styled("input")({
  display: "none",
});
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  customInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed

      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },

  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      backgroundColor: "#ebebeb",
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
}));
const finalSpaceCharacters = [
  {
    id: "gary",
    name: "Gary Goodspeed",
    thumb: "/images/gary.png",
  },
  {
    id: "cato",
    name: "Little Cato",
    thumb: "/images/cato.png",
  },
  {
    id: "kvn",
    name: "KVN",
    thumb: "/images/kvn.png",
  },
  {
    id: "mooncake",
    name: "Mooncake",
    thumb: "/images/mooncake.png",
  },
  {
    id: "quinn",
    name: "Quinn Ergon",
    thumb: "/images/quinn.png",
  },
];

const WorkoutExercisesUIClient = ({
  addArray,
  setaddArray,
  setModalChangeExe,
  message,
}) => {
  const [characters, updateCharacters] = useState(finalSpaceCharacters);
  const { state } = useLocation();
  const myInputRef = useRef(null);
  const [serchText, setSerchText] = useState("");
  const [superAlterIndex, setSuperAlterIndex] = useState("");
  const [alterChanges, setAlterChanges] = useState("");
  const [toAlterChanges, setToAlterChanges] = useState("");
  const [openExerciseAlter, setOpenExerciseAlter] = useState(false);
  const [openSuperSetAlter, setOpenSuperSetAlter] = useState(false);
  const [alterIndex, setalterIndex] = useState();

  const [navItems, setNavitems] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isReaload, setIsReaload] = useState(false);
  const [exercise, setExercise] = useState([]);
  const [workoutTitle, setWorkoutTitle] = useState("Workout Name !");
  const [file, setImage] = React.useState({});
  const [previews, setPreviews] = useState("");
  const [imageStatus, setImageStatus] = useState(false);
  const [oldProfile, setOldProfile] = useState();
  const [detailsData, setDetailsData] = useState("");
  const [exerIndex, setExerIndex] = useState("");

  const [openDetails, setOpenDetails] = useState(false);
  const [workoutDescription, setWorkoutDescription] = useState(
    "Workout description"
  );
  const [isHovered, setIsHovered] = useState("");
  const [isHoveredExercise, setIsHoveredExercise] = useState("");
  const [isExerciseForSuperSet, setIsExerciseForSuperSet] = useState(false);
  const handleUpload = (event) => {
    setImageStatus(true);
    // setImage(event.target.files[0]);
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();

  const [isFocused, setIsFocused] = useState(false);
  const [isFocused1, setIsFocused1] = useState(false);
  const handleMouseEnter = (setIndex, exerciseIndex) => {
    setIsHovered(setIndex);
    setIsHoveredExercise(exerciseIndex);
  };
  const timeoutRef = useRef(null);

  useEffect(() => {
    // Cleanup function to clear the timeout on component unmount
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);
  const handleMouseLeave = () => {
    // Introduce a delay of 100 milliseconds before updating state on hover out

    const delay = 0;
    timeoutRef.current = setTimeout(() => {
      setIsHovered("");
    }, delay);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleFocus1 = () => {
    setIsFocused1(true);
  };

  const handleBlur1 = () => {
    setIsFocused1(false);
  };
  function durationToSeconds(durationString) {
    // Regular expression to match a number and a time unit (sec, min, hour, etc.)
    const regex = /(\d+)\s+(sec|min|hour|hr|day|week|month|year)s?/i;

    // Use the regex to extract the number and unit from the input string

    const match = durationString && durationString.match(regex);

    if (match) {
      const value = parseInt(match[1], 10);
      const unit = match[2].toLowerCase();

      // Define conversion factors for different time units
      const unitToSeconds = {
        sec: 1,
        min: 60,
        hour: 3600,
        hr: 3600,
        day: 86400,
        week: 604800,
        month: 2592000,
        year: 31536000,
      };

      if (unitToSeconds.hasOwnProperty(unit)) {
        return value * unitToSeconds[unit];
      }
    }

    return 0; // Invalid duration format
  }
  const handleAdd = (i) => {
    let list = addArray;
    list.splice(i + 1, 0, {
      exercise: "",
      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          distance: "",
          distanceType: "m",
          reps: "",
          rest: "",
        },
      ],
      description: "",
      superSet: [],
      superset_sets: "",
    });

    setModalChangeExe(true);
    setaddArray([...list]);
  };
  const handleAddSet = (mainindex, index) => {
    let list = addArray;

    list[mainindex]["set"].splice(index + 1, 0, {
      time: "",
      weight: "",
      weightType: "kg",
      distance: "",
      distanceType: "m",
      reps: "",
      rest: "",
    });

    setModalChangeExe(true);
    setaddArray([...list]);
  };
  const handleDeleteSet = (mainindex, index) => {
    let list = addArray;

    list[mainindex]["set"].splice(index, 1);

    setModalChangeExe(true);
    setaddArray([...list]);
  };

  const handleAddSupersetSets = (mainindex, index) => {
    let list = addArray;

    list[mainindex]["set"].splice(index + 1, 0, {
      time: "",
      weight: "",
      weightType: "kg",
      distance: "",
      distanceType: "m",
      reps: "",
      rest: "",
    });

    setModalChangeExe(true);
    setaddArray([...list]);
  };

  const addSuperSet2 = (index) => {
    //  const { name, value } = e.target;

    let current = { ...addArray[index] };
    current.superSet = [];
    let array = [current];

    if (addArray[index + 1].superSet.length > 0) {
      addArray[index + 1].superSet.map((item) => {
        array.push(item);
      });
      const list = [...addArray];
      list[index]["superSet"] = array;

      setModalChangeExe(true);
      setaddArray(list);
      handleDelete(index + 1);
    } else {
      if (addArray[index + 1].set.length > addArray[index].set.length) {
        const setDifference =
          addArray[index + 1].set.length - addArray[index].set.length;
        addArray[index + 1].set.splice(0, setDifference);
      } else {
        const setDifference =
          addArray[index].set.length - addArray[index + 1].set.length;
        if (setDifference > 0) {
          addArray[index + 1].set.push(
            ...Array.from({ length: setDifference }, () => ({
              time: "",
              weight: "",
              weightType: "kg",
              distance: "",
              distanceType: "m",
              reps: "",
              rest: "",
            }))
          );
        }
      }
      console.log(
        addArray[index + 1].set.length,
        "fdhskdjhfkja",
        addArray[index].set.length
      );
      array.push(addArray[index + 1]);
      const list = [...addArray];
      list[index]["superSet"] = array;

      setModalChangeExe(true);
      setaddArray(list);
      handleDelete(index + 1);
    }
  };
  const handleDeleteExer = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);

    setModalChangeExe(true);
    setaddArray(delValue);
  };
  const removeSuperSet = (mainIndex, index) => {
    //  const { name, value } = e.target;
    const list = [...addArray];
    let firstPart = list[mainIndex]["superSet"].slice(0, index + 1);
    let secondPart = list[mainIndex]["superSet"].slice(index + 1);
    console.log(firstPart, secondPart, "dlfkjksdhfjas");
    let firstObject = { ...firstPart[0] };
    if (index > 0) {
      firstObject.superSet = firstPart;
      firstObject.superset_sets = list[mainIndex].superset_sets;
      console.log(firstObject, "fdjhsjkdfjkajds1", list[mainIndex]);
      // superset_sets;
    }

    let secondObject = { ...secondPart[0] };
    if (secondPart.length > 1) {
      secondObject.superSet = secondPart;
      secondObject.superset_sets = list[mainIndex].superset_sets;
      console.log(firstObject, "fdjhsjkdfjkajds2", list[mainIndex]);
    }

    let list1 = addArray;
    list1.splice(mainIndex + 1, 0, secondObject);
    list1.splice(mainIndex + 1, 0, firstObject);

    setModalChangeExe(true);
    setaddArray([...list1]);
    handleDeleteExer(mainIndex);
  };

  const handleAgreeSuperSet = (mainindex) => {
    setSuperAlterIndex(mainindex);

    setAlterChanges(
      addArray[mainindex + 1].superSet.length
        ? addArray[mainindex + 1].superset_sets
        : addArray[mainindex + 1].set.length
    );

    setToAlterChanges(
      addArray[mainindex].superSet.length
        ? addArray[mainindex].superset_sets
        : addArray[mainindex].set.length
    );

    const first = addArray[mainindex].superSet.length
      ? addArray[mainindex].superset_sets
      : addArray[mainindex].set.length;
    const second = addArray[mainindex + 1].superSet.length
      ? addArray[mainindex + 1].superset_sets
      : addArray[mainindex + 1].set.length;

    if (first == second) {
      addInSuperSet(mainindex);
      console.log(alterChanges, toAlterChanges, "dfjkhakjsdhfa");
    } else setOpenSuperSetAlter(true);
  };
  const handleAgreeExercise = (mainindex) => {
    setalterIndex(mainindex);

    setAlterChanges(
      addArray[mainindex + 1].superSet.length
        ? addArray[mainindex + 1].superset_sets
        : addArray[mainindex + 1].set.length
    );

    setToAlterChanges(
      addArray[mainindex].superSet.length
        ? addArray[mainindex].superSet.length
        : addArray[mainindex].set.length
    );

    const first = addArray[mainindex].superSet.length
      ? addArray[mainindex].superSet.length
      : addArray[mainindex].set.length;
    const second = addArray[mainindex + 1].superSet.length
      ? addArray[mainindex + 1].superset_sets
      : addArray[mainindex + 1].set.length;

    if (first == second) {
      addSuperSet(mainindex);
    } else {
      setOpenExerciseAlter(true);
    }
  };
  const addAgreeSuperSet = (index) => {
    //  const { name, value } = e.target;

    let current = { ...addArray[alterIndex] };
    current.superSet = [];
    let array = [current];

    if (
      addArray[alterIndex + 1]?.superSet &&
      addArray[alterIndex + 1].superSet.length > 0
    ) {
      addArray[alterIndex + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[alterIndex + 1]);
    }

    const list = [...addArray];
    list[alterIndex]["superSet"] = array;

    setModalChangeExe(true);
    setaddArray(list);

    let e = {
      target: {
        name: "superset_sets",
        value: addArray[alterIndex].set.length,
      },
    };
    handleChangeSuperSetCount(e, alterIndex);
    handleDelete(alterIndex + 1);
    setOpenExerciseAlter(false);
  };
  const addSuperSet = (index) => {
    //  const { name, value } = e.target;

    console.log(index, "dsjkhdkfgs", alterIndex);
    let current = { ...addArray[index] };
    current.superSet = [];
    let array = [current];

    if (
      addArray[index + 1]?.superSet &&
      addArray[index + 1].superSet.length > 0
    ) {
      addArray[index + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[index + 1]);
    }

    const list = [...addArray];
    list[index]["superSet"] = array;

    setModalChangeExe(true);
    setaddArray(list);

    let e = {
      target: {
        name: "superset_sets",
        value: addArray[index].set.length,
      },
    };
    handleChangeSuperSetCount(e, index);
    handleDelete(index + 1);
    setOpenExerciseAlter(false);
  };
  const addInSuperSet = (index) => {
    let array = addArray[index].superSet;
    if (addArray[index + 1].superSet.length > 0) {
      addArray[index + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[index + 1]);
    }
    const list = [...addArray];
    list[index]["superSet"] = array;

    setModalChangeExe(true);
    setaddArray(list);
    let e = {
      target: {
        name: "superset_sets",
        value: list[index].superset_sets,
      },
    };
    handleChangeSuperSetCount(e, index);
    handleDelete(index + 1);
    setOpenSuperSetAlter(false);
  };
  const addAgreeInSuperSet = () => {
    let array = addArray[superAlterIndex].superSet;
    if (addArray[superAlterIndex + 1].superSet.length > 0) {
      addArray[superAlterIndex + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[superAlterIndex + 1]);
    }
    const list = [...addArray];
    list[superAlterIndex]["superSet"] = array;

    setModalChangeExe(true);
    setaddArray(list);
    let e = {
      target: {
        name: "superset_sets",
        value: list[superAlterIndex].superset_sets,
      },
    };
    handleChangeSuperSetCount(e, superAlterIndex);
    handleDelete(superAlterIndex + 1);
    setOpenSuperSetAlter(false);
  };
  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);

    setModalChangeExe(true);
    setaddArray(delValue);
  };
  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };

  const get_accessment_setting = async () => {
    setIsReaload(true);
    const result = await _get_attitude_accessment_setting();
    if (result.code == 200) {
      if (result.assessment_setting[level]?.length > 0) {
        const config_array = result.assessment_setting[level]?.map(
          (config, i) => {
            return {
              ...config,
              id: "coin" + i,
            };
          }
        );

        setModalChangeExe(true);
        setaddArray(config_array);
      }
      setIsReaload(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsReaload(false);
    }
  };

  const handleArrow = (value, index) => {
    //  const { name, value } = e.target;

    const list = [...addArray];
    list[index]["open"] = value;

    // setModalChangeExe(true);
    setaddArray(list);
  };
  const handleArrowSuperSet = (value, mainindex, exerIndex) => {
    //  const { name, value } = e.target;

    const list = [...addArray];
    list[mainindex]["superSet"][exerIndex]["open"] = value;

    // setModalChangeExe(true);
    setaddArray(list);
  };
  const handleChangeOther = (e, index) => {
    const list = [...addArray];

    list[index]["exercise"] = e;
    list[index]["description"] = e?.instructions;

    setModalChangeExe(true);
    setaddArray(list);
  };
  function handleOnDragEnd(result) {
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setModalChangeExe(true);
    setaddArray(items);
  }
  const handleChangeNavItem = (event, index) => {
    let list = [...addArray];
    const {
      target: { value },
    } = event;
    list[index]["nav_items"] =
      typeof value === "string" ? value.split(",") : value;

    setModalChangeExe(true);
    setaddArray(list);
    setNavitems(typeof value === "string" ? value.split(",") : value);
  };
  const handleChange = (e, index, index1) => {
    const { name, value } = e.target;

    const list = [...addArray];
    console.log(list, "sdflkjsdak", list[index]["set"][index1][name]);
    list[index]["set"][index1][name] = value;

    setModalChangeExe(true);
    setaddArray(list);
  };
  const handleChangeSuperSetDes = (e, index, index1) => {
    const { name, value } = e.target;
    const list = [...addArray];
    list[index]["superSet"][index1][name] = value;

    setModalChangeExe(true);
    setaddArray(list);
  };
  const handleChangeDes = (e, index) => {
    const { name, value } = e.target;

    const list = [...addArray];
    list[index][name] = value;

    setModalChangeExe(true);
    setaddArray(list);
  };
  const handleChangeSuperSet = (e, mainindex, exerIndex, setIndex) => {
    const { name, value } = e.target;

    const list = [...addArray];

    list[mainindex]["superSet"][exerIndex]["set"][setIndex][name] = value;

    setModalChangeExe(true);
    setaddArray(list);
  };
  const handleAddSetCount = (index) => {
    const list = [...addArray];
    const name = "superset_sets";
    const value = parseInt(list[index].superset_sets) + 1;
    // console.log(list[index].superset_sets, "ksdfjhkjsdnfkjs");
    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    list[index]?.superSet.forEach((superSetItem) => {
      const currentSetLength = superSetItem.set.length;

      if (currentSetLength < targetValue) {
        const objectsToAdd = targetValue - currentSetLength;

        for (let i = 0; i < objectsToAdd; i++) {
          superSetItem.set.push({
            time: "",
            weight: "",
            weightType: "kg",
            distance: "",
            distanceType: "m",
            reps: "",
            rest: "",
          });
        }
      } else if (currentSetLength > targetValue) {
        // If the new count is less than the current count, remove excess objects
        superSetItem.set.splice(targetValue);
      }
    });

    setModalChangeExe(true);
    setaddArray(list);
  };
  const handleMinusSetCount = (index) => {
    const list = [...addArray];
    const name = "superset_sets";
    const value = parseInt(list[index].superset_sets) - 1;
    // console.log(list[index].superset_sets, "ksdfjhkjsdnfkjs");
    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    list[index]?.superSet.forEach((superSetItem) => {
      const currentSetLength = superSetItem.set.length;

      if (currentSetLength < targetValue) {
        const objectsToAdd = targetValue - currentSetLength;

        for (let i = 0; i < objectsToAdd; i++) {
          superSetItem.set.push({
            time: "",
            weight: "",
            weightType: "kg",
            distance: "",
            distanceType: "m",
            reps: "",
            rest: "",
          });
        }
      } else if (currentSetLength > targetValue) {
        // If the new count is less than the current count, remove excess objects
        superSetItem.set.splice(targetValue);
      }
    });

    setModalChangeExe(true);
    setaddArray(list);
  };
  const handleChangeSuperSetCount = (e, index) => {
    const { name, value } = e.target;
    const list = [...addArray];

    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    list[index]?.superSet.forEach((superSetItem) => {
      const currentSetLength = superSetItem.set.length;

      if (currentSetLength < targetValue) {
        const objectsToAdd = targetValue - currentSetLength;

        for (let i = 0; i < objectsToAdd; i++) {
          superSetItem.set.push({
            time: "",
            weight: "",
            weightType: "kg",
            distance: "",
            distanceType: "m",
            reps: "",
            rest: "",
          });
        }
      } else if (currentSetLength > targetValue) {
        // If the new count is less than the current count, remove excess objects
        superSetItem.set.splice(targetValue);
      }
    });

    setModalChangeExe(true);
    setaddArray(list);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let exerciesArray = addArray.map((item) => {
      let data = {
        exercise_id: item?.superSet[0]?.exercise?._id
          ? item?.superSet[0]?.exercise?._id
          : item.exercise?._id,
        title: item?.superSet[0]?.exercise?.title
          ? item?.superSet[0]?.exercise?.title
          : item.exercise?.title,
        description: item.description,
        unit: item.weightType,
      };
      if (item.superset_sets) {
        data["superset_sets"] = item.superset_sets;
      }
      if (item.set) {
        data["sets"] = item.set;
      }
      if (item.reps) {
        data["reps"] = item.reps;
      }
      if (item.rest) {
        data["rest"] = item.rest;
      }
      if (item.weight) {
        data["weight"] = item.weight;
      }
      if (item.time) {
        data["duration"] = item.time;
      }
      if (item.superSet) {
        data["sub_exercises"] = [];
        if (item.superSet.length > 0) {
          data["sub_exercises"] = item.superSet.map((item) => {
            let data = {
              exercise_id: item.exercise?._id,
              title: item.exercise?.title,
              description: item.description,
              unit: item.weightType,
            };

            if (item.set) {
              data["sets"] = item.set;
            }
            if (item.reps) {
              data["reps"] = item.reps;
            }
            if (item.rest) {
              data["rest"] = item.rest;
            }
            if (item.weight) {
              data["weight"] = item.weight;
            }
            if (item.time) {
              data["duration"] = item.time;
            }

            return data;
          });
        }
      }
      return data;
      return {
        // sets: item.set,
        // reps: item.reps,
        // rest: item.rest,
        // weight: item.weight,
        // duration: item.time,
      };
    });
    const formData = new FormData();
    formData.append("title", workoutTitle);

    // formData.append("image", state.image);
    if (imageStatus) {
      formData.append("image", file);
    }
    formData.append("type", state.type);
    formData.append("description", workoutDescription);
    formData.append("status", state.status);
    formData.append("exercises", JSON.stringify(exerciesArray));
  };
  const MENU_OPTIONS = [
    {
      label: "Add Exercise",
      icon: "mingcute:add-fill",
      // handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      // handleClick: handleAgreeDelete,
    },
  ];
  const handleChangeUp = (value) => {
    if (value == 0) {
      enqueueSnackbar("You are already on Top !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value - 1, 0, reorderedItem);

    setModalChangeExe(true);
    setaddArray(items);
  };
  const handleExerciseList = (value) => {
    setIsExerciseForSuperSet(false);
    setDetailsData(value);
    setOpenDetails(true);
  };
  const handleSupersetExerciseList = (mainIndex, exerInde) => {
    setDetailsData(mainIndex);
    setExerIndex(exerInde);
    setIsExerciseForSuperSet(true);
  };
  const handleSelectExercise = (value) => {
    const list = [...addArray];
    list[detailsData]["exercise"] = value;
    list[detailsData]["description"] = value?.instructions;

    setModalChangeExe(true);
    setaddArray(list);
  };
  const handleSelectSupersetExercise = (value) => {
    const list = [...addArray];

    list[detailsData]["superSet"][exerIndex]["exercise"] = value;
    list[detailsData]["superSet"][exerIndex]["description"] =
      value?.instructions;

    setModalChangeExe(true);
    setaddArray(list);
  };
  const handleChangeDown = (value) => {
    if (value >= addArray.length - 1) {
      enqueueSnackbar("You are already on Bottom !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value + 1, 0, reorderedItem);

    setModalChangeExe(true);
    setaddArray(items);
  };
  const memueOptions = (value) => {
    const MENU_OPTIONS = [];

    MENU_OPTIONS.unshift(
      {
        label: "Move Up",
        icon: "mdi:arrow-up",
        handleClick: handleChangeUp,
        disabled: value == 0 ? true : false,
      },
      {
        label: "Move Down",
        icon: "ic:outline-arrow-downward",
        handleClick: handleChangeDown,
        disabled: value >= addArray.length - 1 ? true : false,
      }
    );

    return MENU_OPTIONS;
  };

  if (isReaload === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-md">
      <ConfirmationWithDescription
        open={openSuperSetAlter}
        setOpen={setOpenSuperSetAlter}
        title={"Are you sure you want to take this action?"}
        descriotion={`You are trying to alternate an exercise with  ${toAlterChanges} sets with an exercise with ${alterChanges} sets. If you proceed, we’ll modify the exercise to have  ${toAlterChanges} sets.`}
        handleAgree={addAgreeInSuperSet}
      />
      <ConfirmationWithDescription
        open={openExerciseAlter}
        setOpen={setOpenExerciseAlter}
        title={"Are you sure you want to take this action?"}
        descriotion={`You are trying to alternate an exercise with  ${toAlterChanges} sets with an exercise with ${alterChanges} sets. If you proceed, we’ll modify the exercise to have  ${toAlterChanges} sets.`}
        handleAgree={addAgreeSuperSet}
      />
      <ToshowExercise
        open={openDetails}
        setOpen={setOpenDetails}
        handleChange={handleSelectExercise}
      />
      <ToshowExercise
        open={isExerciseForSuperSet}
        setOpen={setIsExerciseForSuperSet}
        handleChange={handleSelectSupersetExercise}
      />
      <div className="mt-2 ">
        <div className="characters">
          {/* <form onSubmit={handleSubmit}> */}

          <form onSubmit={() => {}}>
            {addArray?.map((data, mainindex) => {
              return (
                <>
                  {data?.superSet && data?.superSet.length > 0 ? (
                    <>
                      <div
                        style={{ position: "relative" }}
                        className="workout-Superset-card my-2"
                      >
                        {/* <SuperSets length={data?.superset_sets} data={data} /> */}
                        <Box>
                          <div className="d-flex align-items-start justify-content-between ">
                            <div className="d-flex align-items-center w-100  mb-2">
                              <div>Superset of</div>
                              <div className="ms-2 cross-icon ">
                                <RemoveCircleIcon
                                  onClick={() => handleMinusSetCount(mainindex)}
                                  className="diary-icon-plus-minus"
                                />
                              </div>

                              <TextField
                                id="outlined-basic"
                                size="small"
                                variant="outlined"
                                placeholder="Sets"
                                type="number"
                                value={data.superset_sets}
                                name="superset_sets"
                                disabled
                                onChange={(e) =>
                                  handleChangeSuperSetCount(e, mainindex)
                                }
                                InputLabelProps={{
                                  shrink: true,
                                  style: { fontSize: "14px" },
                                }}
                                //   label="Set"
                                InputProps={{
                                  style: { fontSize: "13px", padding: "0" },
                                }}
                                sx={{
                                  mx: 1,
                                  borderRadius: "5px",
                                  "& legend": { display: "none" },
                                  "& fieldset": { top: 0 },
                                  fontSize: "13px",
                                  "& input": {
                                    padding: "3px 5px",
                                    textAlign: "center",
                                  },
                                  width: "4%",
                                }}
                              />

                              <div className=" cross-icon me-2">
                                <AddCircleIcon
                                  onClick={() => handleAddSetCount(mainindex)}
                                  className="diary-icon-plus-minus"
                                />
                              </div>
                              <div>sets</div>
                            </div>

                            {/* <div className=" cross-icon ">
                              {addArray.length > 1 ? (
                                <>
                                  <Tooltip title="Remove">
                                    <RemoveCircleOutlineIcon
                                      onClick={() => handleDelete(mainindex)}
                                      className="diary-icon-remove"
                                    />
                                  </Tooltip>
                                </>
                              ) : (
                                ""
                              )}
                              <Tooltip title="Add">
                                <AddCircleOutlineIcon
                                  onClick={() => handleAdd(mainindex)}
                                  className="diary-icon-add"
                                />
                              </Tooltip>

                              <CustomPopover
                                menu={memueOptions(mainindex)}
                                data={mainindex}
                              />
                            </div> */}
                          </div>

                          <div className=" mb-2 ">
                            {data?.superSet?.map((exerData, exerIndex) => (
                              <div className="d-flex  workout-set-card">
                                <Box
                                  sx={{
                                    position: "relative",
                                    width: "100%",
                                  }}
                                >
                                  <div className="d-flex align-items-center ">
                                    <TextField
                                      id="outlined-basic"
                                      variant="outlined"
                                      placeholder="Exercise"
                                      value={exerData?.exercise?.title}
                                      name="Exercise"
                                      onClick={() =>
                                        handleSupersetExerciseList(
                                          mainindex,
                                          exerIndex
                                        )
                                      }
                                      // onChange={(e) => {}}
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { fontSize: "14px" },
                                      }}
                                      label="Exercise"
                                      InputProps={{
                                        style: {
                                          fontSize: "13px",
                                          paddingLeft: "6px",
                                        },
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            {exerData?.exercise?.image
                                              ?.thumbnail_3 && (
                                              <Avatar
                                                sx={{ borderRadius: "5px" }}
                                                alt="User Image"
                                                src={
                                                  s3baseUrl +
                                                  exerData?.exercise?.image
                                                    ?.thumbnail_3
                                                }
                                              />
                                            )}
                                          </InputAdornment>
                                        ),
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <div style={{ fontSize: "16px" }}>
                                              {exerData?.exercise ? (
                                                <EditIcon
                                                  fontSize="12px"
                                                  sx={{ opacity: 0.5 }}
                                                />
                                              ) : (
                                                <AddIcon
                                                  sx={{ opacity: 0.5 }}
                                                />
                                              )}
                                            </div>
                                          </InputAdornment>
                                        ),
                                        inputProps: {
                                          readOnly: true, // Disable editing
                                        },
                                      }}
                                      sx={{
                                        borderRadius: "5px",

                                        fontSize: "13px",
                                        width: "50%",
                                      }}
                                    />
                                    <div className="d-flex align-items-center mx-1">
                                      <div className="text-center">
                                        {!exerData?.open ? (
                                          <KeyboardArrowDownIcon
                                            sx={{
                                              cursor: "pointer",
                                              opacity: 0.5,
                                              fontSize: "20px",
                                            }}
                                            onClick={() => {
                                              handleArrowSuperSet(
                                                true,
                                                mainindex,
                                                exerIndex
                                              );
                                            }}
                                          />
                                        ) : (
                                          <KeyboardArrowUpIcon
                                            sx={{
                                              cursor: "pointer",
                                              opacity: 0.5,
                                              fontSize: "20px",
                                            }}
                                            onClick={() => {
                                              handleArrowSuperSet(
                                                false,
                                                mainindex,
                                                exerIndex
                                              );
                                            }}
                                          />
                                        )}
                                      </div>
                                      <Typography
                                        variant="body2"
                                        sx={{ opacity: 0.6 }}
                                      >
                                        {` Sets ${exerData?.set?.length}`}
                                      </Typography>
                                    </div>
                                  </div>

                                  {exerData?.open &&
                                    exerData?.set?.map((item, setIndex) => (
                                      <div className="d-flex align-items-center my-3 workout-card-inset">
                                        <Stack
                                          direction="row"
                                          spacing={1}
                                          alignItems="center"
                                          justifyContent="start"
                                        >
                                          <Label
                                            variant={"ghost"}
                                            sx={{ padding: "25px 4px" }}
                                          >
                                            {setIndex + 1}
                                          </Label>
                                          {/* <TextField
                                            id="outlined-basic"
                                            size="small"
                                            variant="outlined"
                                            placeholder="Set"
                                            type="number"
                                            // value={item.set}
                                            name="set"
                                            disabled
                                            InputLabelProps={{
                                              shrink: true,
                                              style: { fontSize: "14px" },
                                            }}
                                            label="Set"
                                            InputProps={{
                                              style: { fontSize: "13px" },
                                            }}
                                            sx={{
                                              borderRadius: "5px",
                                              fontSize: "13px",
                                              width: "13%",
                                            }}
                                          /> */}
                                          <CloseIcon sx={{ opacity: 0.3 }} />
                                          <TextField
                                            id="outlined-basic"
                                            size="small"
                                            variant="outlined"
                                            placeholder="0"
                                            type="number"
                                            value={item?.time}
                                            name="time"
                                            onChange={(e) => {
                                              handleChangeSuperSet(
                                                e,
                                                mainindex,
                                                exerIndex,
                                                setIndex
                                              );
                                            }}
                                            InputLabelProps={{
                                              shrink: true,
                                              style: {
                                                fontSize: "14px",
                                              },
                                            }}
                                            label="Duration"
                                            InputProps={{
                                              style: {
                                                fontSize: "13px",
                                                paddingRight: 0,
                                              },
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <Select
                                                    id="select-option"
                                                    variant="outlined"
                                                    size="small"
                                                    name="timeType"
                                                    value={item?.timeType}
                                                    label="Weight Type"
                                                    inputProps={{
                                                      style: {
                                                        padding: 0,
                                                      },
                                                    }}
                                                    onChange={(e) => {
                                                      handleChangeSuperSet(
                                                        e,
                                                        mainindex,
                                                        exerIndex,
                                                        setIndex
                                                      );
                                                    }}
                                                    sx={{
                                                      "& legend": {
                                                        display: "none",
                                                      },
                                                      "& fieldset": {
                                                        top: 0,
                                                      },
                                                      fontSize: "13px",
                                                      border: "none",
                                                      "& fieldset": {
                                                        border: "none",
                                                      },
                                                      "& .MuiOutlinedInput-input":
                                                        {
                                                          padding: 0,
                                                        },
                                                    }}
                                                  >
                                                    <MenuItem value="m">
                                                      Min
                                                    </MenuItem>
                                                    <MenuItem value="km">
                                                      sec
                                                    </MenuItem>
                                                  </Select>
                                                </InputAdornment>
                                              ),
                                              // endAdornment: (
                                              //   <InputAdornment position="end">
                                              //     <div
                                              //       style={{
                                              //         fontSize: "11px",
                                              //       }}
                                              //     >
                                              //       sec
                                              //     </div>
                                              //   </InputAdornment>
                                              // ),
                                            }}
                                            sx={{
                                              borderRadius: "5px",
                                              fontSize: "13px",
                                              width: "20%",
                                            }}
                                          />
                                          <TextField
                                            id="outlined-basic"
                                            size="small"
                                            variant="outlined"
                                            placeholder="Reps"
                                            type="number"
                                            label="Reps"
                                            value={item?.reps}
                                            name="reps"
                                            onChange={(e) => {
                                              handleChangeSuperSet(
                                                e,
                                                mainindex,
                                                exerIndex,
                                                setIndex
                                              );
                                            }}
                                            InputLabelProps={{
                                              shrink: true,
                                              style: { fontSize: "14px" },
                                            }}
                                            InputProps={{
                                              style: { fontSize: "13px" },
                                            }}
                                            sx={{
                                              borderRadius: "5px",
                                              // "& legend": { display: "none" },
                                              // "& fieldset": { top: 0 },
                                              fontSize: "13px",
                                              width: "13%",
                                            }}
                                          />
                                          <TextField
                                            sx={{
                                              borderRadius: "5px",
                                              // "& legend": { display: "none" },
                                              // "& fieldset": { top: 0 },
                                              paddingRight: 0,
                                              width: "45%",
                                            }}
                                            // className={classes.customInput}
                                            size="small"
                                            id="custom-input"
                                            variant="outlined"
                                            placeholder="Weight"
                                            name="weight"
                                            label="Weight"
                                            value={item.weight}
                                            onChange={(e) => {
                                              handleChangeSuperSet(
                                                e,
                                                mainindex,
                                                exerIndex,
                                                setIndex
                                              );
                                            }}
                                            type="number"
                                            InputLabelProps={{
                                              shrink: true,
                                              style: { fontSize: "14px" },
                                            }}
                                            InputProps={{
                                              style: {
                                                fontSize: "13px",
                                                paddingRight: 0,
                                              },
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <Select
                                                    id="select-option"
                                                    variant="outlined"
                                                    size="small"
                                                    name="weightType"
                                                    value={item?.weightType}
                                                    label="Weight Type"
                                                    onChange={(e) => {
                                                      handleChangeSuperSet(
                                                        e,
                                                        mainindex,
                                                        exerIndex,
                                                        setIndex
                                                      );
                                                    }}
                                                    sx={{
                                                      "& legend": {
                                                        display: "none",
                                                      },
                                                      "& fieldset": { top: 0 },
                                                      fontSize: "13px",
                                                      border: "none",
                                                      "& fieldset": {
                                                        border: "none",
                                                      },
                                                    }}
                                                  >
                                                    <MenuItem value="1rm">
                                                      % 1RM
                                                    </MenuItem>
                                                    <MenuItem value="body_weight">
                                                      % Bodyweight
                                                    </MenuItem>
                                                    <MenuItem value="kg">
                                                      kilogram
                                                    </MenuItem>
                                                    <MenuItem value="lb">
                                                      Pound
                                                    </MenuItem>
                                                    <MenuItem value="poods">
                                                      Poods
                                                    </MenuItem>
                                                  </Select>
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                          <TextField
                                            sx={{
                                              borderRadius: "5px",
                                              // "& legend": { display: "none" },
                                              // "& fieldset": { top: 0 },
                                              paddingRight: 0,
                                              width: "45%",
                                            }}
                                            // className={classes.customInput}
                                            size="small"
                                            id="custom-input"
                                            variant="outlined"
                                            placeholder="Distance"
                                            name="distance"
                                            label="Distance"
                                            value={item?.distance}
                                            onChange={(e) => {
                                              handleChangeSuperSet(
                                                e,
                                                mainindex,
                                                exerIndex,
                                                setIndex
                                              );
                                            }}
                                            type="number"
                                            InputLabelProps={{
                                              shrink: true,
                                              style: { fontSize: "14px" },
                                            }}
                                            InputProps={{
                                              style: {
                                                fontSize: "13px",
                                                paddingRight: 0,
                                              },
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <Select
                                                    id="select-option"
                                                    variant="outlined"
                                                    size="small"
                                                    name="distanceType"
                                                    value={item?.distanceType}
                                                    label="Weight Type"
                                                    onChange={(e) => {
                                                      handleChangeSuperSet(
                                                        e,
                                                        mainindex,
                                                        exerIndex,
                                                        setIndex
                                                      );
                                                    }}
                                                    sx={{
                                                      "& legend": {
                                                        display: "none",
                                                      },
                                                      "& fieldset": { top: 0 },
                                                      fontSize: "13px",
                                                      border: "none",
                                                      "& fieldset": {
                                                        border: "none",
                                                      },
                                                    }}
                                                  >
                                                    <MenuItem value="m">
                                                      Meter
                                                    </MenuItem>
                                                    <MenuItem value="km">
                                                      Kilometer
                                                    </MenuItem>
                                                    <MenuItem value="mi">
                                                      Mile
                                                    </MenuItem>
                                                  </Select>
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                          <TextField
                                            id="outlined-basic"
                                            size="small"
                                            variant="outlined"
                                            placeholder="0"
                                            type="number"
                                            value={item?.rest}
                                            name="rest"
                                            onChange={(e) => {
                                              handleChangeSuperSet(
                                                e,
                                                mainindex,
                                                exerIndex,
                                                setIndex
                                              );
                                            }}
                                            InputLabelProps={{
                                              shrink: true,
                                              style: { fontSize: "14px" },
                                            }}
                                            label="Rest"
                                            InputProps={{
                                              style: {
                                                fontSize: "13px",
                                                paddingRight: 0,
                                              },
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <Select
                                                    id="select-option"
                                                    variant="outlined"
                                                    size="small"
                                                    name="restType"
                                                    value={item?.restType}
                                                    label="Weight Type"
                                                    inputProps={{
                                                      style: {
                                                        padding: 0,
                                                      },
                                                    }}
                                                    onChange={(e) => {
                                                      handleChangeSuperSet(
                                                        e,
                                                        mainindex,
                                                        exerIndex,
                                                        setIndex
                                                      );
                                                    }}
                                                    sx={{
                                                      "& legend": {
                                                        display: "none",
                                                      },
                                                      "& fieldset": {
                                                        top: 0,
                                                      },
                                                      fontSize: "13px",
                                                      border: "none",
                                                      "& fieldset": {
                                                        border: "none",
                                                      },
                                                      "& .MuiOutlinedInput-input":
                                                        {
                                                          padding: 0,
                                                        },
                                                    }}
                                                  >
                                                    <MenuItem value="m">
                                                      Min
                                                    </MenuItem>
                                                    <MenuItem value="km">
                                                      sec
                                                    </MenuItem>
                                                  </Select>
                                                </InputAdornment>
                                              ),
                                              // endAdornment: (
                                              //   <InputAdornment position="end">
                                              //     <div
                                              //       style={{
                                              //         fontSize: "11px",
                                              //       }}
                                              //     >
                                              //       sec
                                              //     </div>
                                              //   </InputAdornment>
                                              // ),
                                            }}
                                            sx={{
                                              borderRadius: "5px",
                                              fontSize: "13px",
                                              width: "20%",
                                            }}
                                          />
                                        </Stack>
                                      </div>
                                    ))}
                                  {exerData?.open && (
                                    <TextField
                                      id="outlined-basic"
                                      // label="Exercise"
                                      size="small"
                                      variant="outlined"
                                      placeholder="Instructions"
                                      label="Instructions"
                                      type="number"
                                      value={exerData.description}
                                      name="description"
                                      multiline
                                      rows={2}
                                      onChange={(e) =>
                                        handleChangeSuperSetDes(
                                          e,
                                          mainindex,
                                          exerIndex
                                        )
                                      }
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { fontSize: "14px" },
                                      }}
                                      InputProps={{
                                        style: { fontSize: "14px" },
                                      }}
                                      sx={{
                                        borderRadius: "5px",
                                        // "& legend": { display: "none" },
                                        // "& fieldset": { top: 0 },

                                        mt: 2,
                                        width: "100%",
                                      }}
                                    />
                                  )}
                                  {/* <span
                                    className="cross-icon"
                                    style={{
                                      position: "absolute",
                                      bottom: "-30px",

                                      width: "100%",
                                      justifyContent: "center",
                                      zIndex: 1,
                       
                                    }}
                                  >
                                    {data?.superSet.length > 1 &&
                                    exerIndex < data?.superSet.length - 1 ? (
                                      <div className="">
                                        <SyncDisabledIcon
                                          onClick={() =>
                                            removeSuperSet(mainindex, exerIndex)
                                          }
                                          className="sync-icon-remove"
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </span> */}
                                </Box>
                              </div>
                            ))}
                          </div>
                        </Box>
                        {/* <span
                          className="cross-icon"
                          style={{
                            position: "absolute",
                            bottom: "-17px",
                            width: "100%",
                            justifyContent: "center",
                            zIndex: 1,
                       
                          }}
                        >
                          {addArray.length > 1 &&
                          mainindex < addArray.length - 1 ? (
                            <div className="">
                              <SyncIcon
                                onClick={() => handleAgreeSuperSet(mainindex)}
                                className="sync-icon-add"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </span> */}
                      </div>
                    </>
                  ) : (
                    <div
                      // direction="row"

                      className="d-flex my-2 workout-set-card w-100"
                      onMouseEnter={() => handleMouseLeave()}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          width: "100%",
                        }}
                        onMouseEnter={() => handleMouseLeave()}
                      >
                        <div
                          className="d-flex align-items-start justify-content-between w-100 "
                          onMouseEnter={() => handleMouseLeave()}
                        >
                          <div className="w-100 d-flex align-items-center">
                            <Tooltip placement="top" title={message}>
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                placeholder="Exercise"
                                value={
                                  data?.exercise?.title
                                    ? data?.exercise?.title
                                    : ""
                                }
                                name="Exercise"
                                // onChange={(e) => {}}
                                InputLabelProps={{
                                  shrink: true,
                                  style: { fontSize: "14px" },
                                }}
                                label="Exercise"
                                InputProps={{
                                  style: {
                                    fontSize: "13px",
                                    paddingLeft: "6px",
                                  },
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {data?.exercise?.image?.thumbnail_3 && (
                                        <Avatar
                                          sx={{ borderRadius: "5px" }}
                                          alt="User Image"
                                          src={
                                            s3baseUrl +
                                            data?.exercise?.image?.thumbnail_3
                                          }
                                        />
                                      )}
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <div style={{ fontSize: "16px" }}>
                                        {data?.exercise ? (
                                          <EditIcon
                                            fontSize="12px"
                                            sx={{ opacity: 0.5 }}
                                          />
                                        ) : (
                                          <AddIcon sx={{ opacity: 0.5 }} />
                                        )}
                                      </div>
                                    </InputAdornment>
                                  ),
                                  inputProps: {
                                    readOnly: true, // Disable editing
                                  },
                                }}
                                sx={{
                                  borderRadius: "5px",

                                  fontSize: "13px",
                                  width: "50%",
                                }}
                              />
                            </Tooltip>
                            <div className="d-flex align-items-center mx-1">
                              <div className="text-center">
                                {!data?.open ? (
                                  <KeyboardArrowDownIcon
                                    sx={{
                                      cursor: "pointer",
                                      opacity: 0.5,
                                      fontSize: "20px",
                                    }}
                                    onClick={() => {
                                      handleArrow(true, mainindex);
                                    }}
                                  />
                                ) : (
                                  <KeyboardArrowUpIcon
                                    sx={{
                                      cursor: "pointer",
                                      opacity: 0.5,
                                      fontSize: "20px",
                                    }}
                                    onClick={() => {
                                      handleArrow(false, mainindex);
                                    }}
                                  />
                                )}
                              </div>
                              <Typography variant="body2" sx={{ opacity: 0.6 }}>
                                {` Sets ${data?.set?.length}`}
                              </Typography>
                            </div>
                          </div>
                          {/* <div className=" cross-icon ">
                            {addArray.length > 1 ? (
                              <>
                                <Tooltip title="Remove">
                                  <RemoveCircleOutlineIcon
                                    onClick={() => handleDelete(mainindex)}
                                    className="diary-icon-remove"
                                  />
                                </Tooltip>
                              </>
                            ) : (
                              ""
                            )}
                            <Tooltip title="Add">
                              <AddCircleOutlineIcon
                                onClick={() => handleAdd(mainindex)}
                                className="diary-icon-add"
                              />
                            </Tooltip>

                            <CustomPopover
                              menu={memueOptions(mainindex)}
                              data={mainindex}
                            />
                          </div> */}
                        </div>

                        {data?.open &&
                          data?.set?.map((item, index1) => (
                            <Tooltip placement="top" title={message}>
                              <div
                                className="d-flex align-items-center my-3 workout-card-inset"
                                onMouseEnter={() =>
                                  handleMouseEnter(index1, mainindex)
                                }

                                // onMouseLeave={() => handleMouseLeave()}
                              >
                                {/* <div>
                                    {isHovered === index1 &&
                                      isHoveredExercise == mainindex && (
                                        <div
                                          className="icon-calendar-workout"
                                          onMouseEnter={() =>
                                            handleMouseEnter(index1, mainindex)
                                          }
                                        >
                                          <Tooltip title="Add">
                                            <AddIcon
                                              className="add-icon-calendar-workout me-1"
                                              onMouseEnter={() =>
                                                handleMouseEnter(
                                                  index1,
                                                  mainindex
                                                )
                                              }
                                              onClick={() =>
                                                handleAddSet(mainindex, index1)
                                              }
                                            />
                                          </Tooltip>
                                          <Tooltip
                                            title={`${
                                              data?.set.length > 1
                                                ? "Delete"
                                                : "Can't be Deleted"
                                            }`}
                                          >
                                            <DeleteIcon
                                              onMouseEnter={() =>
                                                handleMouseEnter(
                                                  index1,
                                                  mainindex
                                                )
                                              }
                                              className={`${
                                                data?.set.length > 1
                                                  ? "del-icon-calendar-workout-working"
                                                  : "del-icon-calendar-workout-notwork"
                                              }`}
                                              onClick={
                                                data?.set.length > 1
                                                  ? () =>
                                                      handleDeleteSet(
                                                        mainindex,
                                                        index1
                                                      )
                                                  : () => {}
                                              }
                                            />
                                          </Tooltip>
                                        </div>
                                      )}
                                  </div> */}
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                  justifyContent="start"
                                >
                                  <Label
                                    variant={"ghost"}
                                    sx={{ padding: "25px 4px" }}
                                  >
                                    {index1 + 1}
                                  </Label>
                                  {/* <TextField
                                  id="outlined-basic"
                                  onMouseLeave={() =>
                                    handleMouseEnter(index1, mainindex)
                                  }
                                  onClick={() => handleMouseLeave()}
                                  size="small"
                                  variant="outlined"
                                  placeholder="Set"
                                  type="number"
                                  // value={item.set}
                                  name="set"
                                  disabled
                                  InputLabelProps={{
                                    shrink: true,
                                    style: { fontSize: "14px" },
                                  }}
                                  label="Set"
                                  InputProps={{
                                    style: { fontSize: "13px" },
                                  }}
                                  sx={{
                                    borderRadius: "5px",
                                    // "& legend": { display: "none" },
                                    // "& fieldset": { top: 0 },
                                    fontSize: "13px",
                                    width: "13%",
                                  }}
                                /> */}
                                  <CloseIcon sx={{ opacity: 0.3 }} />

                                  {data.parameters.includes(
                                    "Duration/Pace"
                                  ) && (
                                    <TextField
                                      id="outlined-basic"
                                      size="small"
                                      variant="outlined"
                                      onMouseLeave={() =>
                                        handleMouseEnter(index1, mainindex)
                                      }
                                      onClick={() => handleMouseLeave()}
                                      placeholder="0"
                                      type="number"
                                      value={item?.time}
                                      // inputRef={(ref) => {
                                      //   // Assign the ref to the focusedInputRef
                                      //   focusedInputRef.current = ref;
                                      // }}
                                      name="time"
                                      onChange={(e) => {
                                        handleChange(e, mainindex, index1);
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { fontSize: "14px" },
                                      }}
                                      label="Duration/Pace"
                                      InputProps={{
                                        style: {
                                          fontSize: "13px",
                                          paddingRight: 0,
                                        },
                                        readOnly: true,
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <Select
                                              id="select-option"
                                              variant="outlined"
                                              size="small"
                                              name="timeType"
                                              value={item?.timeType}
                                              label="Weight Type"
                                              readOnly
                                              inputProps={{
                                                style: {
                                                  padding: 0,
                                                },
                                              }}
                                              onChange={(e) => {
                                                handleChange(
                                                  e,
                                                  mainindex,
                                                  index1
                                                );
                                              }}
                                              sx={{
                                                "& legend": {
                                                  display: "none",
                                                },
                                                "& fieldset": {
                                                  top: 0,
                                                },
                                                fontSize: "13px",
                                                border: "none",
                                                "& fieldset": {
                                                  border: "none",
                                                },
                                                "& .MuiOutlinedInput-input": {
                                                  padding: 0,
                                                },
                                              }}
                                            >
                                              <MenuItem value="sec">
                                                sec
                                              </MenuItem>
                                              <MenuItem value="min">
                                                min
                                              </MenuItem>
                                            </Select>
                                          </InputAdornment>
                                        ),
                                        // endAdornment: (
                                        //   <InputAdornment position="end">
                                        //     <div style={{ fontSize: "11px" }}>
                                        //       sec
                                        //     </div>
                                        //   </InputAdornment>
                                        // ),
                                      }}
                                      sx={{
                                        borderRadius: "5px",

                                        fontSize: "13px",
                                        // width: "20%",
                                        width: "100px",
                                      }}
                                    />
                                  )}
                                  {data.parameters.includes("Reps") && (
                                    <TextField
                                      id="outlined-basic"
                                      size="small"
                                      variant="outlined"
                                      placeholder="Reps"
                                      type="number"
                                      onMouseLeave={() =>
                                        handleMouseEnter(index1, mainindex)
                                      }
                                      onClick={() => handleMouseLeave()}
                                      label="Reps"
                                      value={item?.reps}
                                      name="reps"
                                      onChange={(e) => {
                                        handleChange(e, mainindex, index1);
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { fontSize: "14px" },
                                      }}
                                      InputProps={{
                                        style: { fontSize: "13px" },
                                        readOnly: true,
                                      }}
                                      sx={{
                                        borderRadius: "5px",
                                        // "& legend": { display: "none" },
                                        // "& fieldset": { top: 0 },
                                        fontSize: "13px",
                                        // width: "13%",
                                        width: "60px",
                                      }}
                                    />
                                  )}
                                  {data.parameters.includes("Weight") && (
                                    <TextField
                                      sx={{
                                        borderRadius: "5px",
                                        // "& legend": { display: "none" },
                                        // "& fieldset": { top: 0 },
                                        paddingRight: 0,
                                        // width: "45%",
                                        width: "175px",
                                      }}
                                      // className={classes.customInput}
                                      size="small"
                                      id="custom-input"
                                      variant="outlined"
                                      onMouseLeave={() =>
                                        handleMouseEnter(index1, mainindex)
                                      }
                                      onClick={() => handleMouseLeave()}
                                      placeholder="Weight"
                                      name="weight"
                                      label="Weight"
                                      value={item.weight}
                                      onChange={(e) => {
                                        handleChange(e, mainindex, index1);
                                      }}
                                      type="number"
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { fontSize: "14px" },
                                      }}
                                      InputProps={{
                                        style: {
                                          fontSize: "13px",
                                          paddingRight: 0,
                                        },
                                        readOnly: true,
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <Select
                                              id="select-option"
                                              variant="outlined"
                                              size="small"
                                              name="weightType"
                                              value={item?.weightType}
                                              label="Weight Type"
                                              readOnly
                                              onChange={(e) => {
                                                handleChange(
                                                  e,
                                                  mainindex,
                                                  index1
                                                );
                                              }}
                                              onMouseLeave={() =>
                                                handleMouseEnter(
                                                  index1,
                                                  mainindex
                                                )
                                              }
                                              onClick={() => handleMouseLeave()}
                                              sx={{
                                                "& legend": {
                                                  display: "none",
                                                },
                                                "& fieldset": { top: 0 },
                                                fontSize: "13px",
                                                border: "none",
                                                "& fieldset": {
                                                  border: "none",
                                                },
                                              }}
                                            >
                                              <MenuItem value="1rm">
                                                % 1RM
                                              </MenuItem>
                                              <MenuItem value="body_weight">
                                                % Bodyweight
                                              </MenuItem>
                                              <MenuItem value="kg">
                                                kilogram
                                              </MenuItem>
                                              <MenuItem value="lb">
                                                Pound
                                              </MenuItem>
                                              <MenuItem value="poods">
                                                Poods
                                              </MenuItem>
                                            </Select>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  )}
                                  {data.parameters.includes(
                                    "Distance/Calories"
                                  ) && (
                                    <TextField
                                      sx={{
                                        borderRadius: "5px",
                                        // "& legend": { display: "none" },
                                        // "& fieldset": { top: 0 },
                                        paddingRight: 0,
                                        // width: "45%",
                                        width: "175px",
                                      }}
                                      // className={classes.customInput}
                                      size="small"
                                      id="custom-input"
                                      variant="outlined"
                                      onMouseLeave={() =>
                                        handleMouseEnter(index1, mainindex)
                                      }
                                      onClick={() => handleMouseLeave()}
                                      placeholder={
                                        item?.distanceType == "calories"
                                          ? "Calories"
                                          : "Distance"
                                      }
                                      name="distance"
                                      label="Distance/Calories"
                                      value={item?.distance}
                                      onChange={(e) => {
                                        handleChange(e, mainindex, index1);
                                      }}
                                      type="number"
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { fontSize: "14px" },
                                      }}
                                      InputProps={{
                                        style: {
                                          fontSize: "13px",
                                          paddingRight: 0,
                                        },
                                        readOnly: true,
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <Select
                                              id="select-option"
                                              variant="outlined"
                                              size="small"
                                              name="distanceType"
                                              value={item?.distanceType}
                                              label="Weight Type"
                                              readOnly
                                              onChange={(e) => {
                                                handleChange(
                                                  e,
                                                  mainindex,
                                                  index1
                                                );
                                              }}
                                              onMouseLeave={() =>
                                                handleMouseEnter(
                                                  index1,
                                                  mainindex
                                                )
                                              }
                                              onClick={() => handleMouseLeave()}
                                              sx={{
                                                "& legend": {
                                                  display: "none",
                                                },
                                                "& fieldset": { top: 0 },
                                                fontSize: "13px",
                                                border: "none",
                                                "& fieldset": {
                                                  border: "none",
                                                },
                                              }}
                                            >
                                              <MenuItem value="m">
                                                Meter
                                              </MenuItem>
                                              <MenuItem value="km">
                                                Kilometer
                                              </MenuItem>
                                              <MenuItem value="mi">
                                                Mile
                                              </MenuItem>
                                              <MenuItem value="calories">
                                                Calories
                                              </MenuItem>
                                            </Select>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  )}

                                  {data.parameters.includes("Tempo") && (
                                    <TextField
                                      id="outlined-basic"
                                      size="small"
                                      variant="outlined"
                                      placeholder="Tempo"
                                      type="number"
                                      label="Tempo"
                                      value={item?.tempo}
                                      name="tempo"
                                      onChange={(e) => {
                                        handleChangeTempo(e, mainindex, index1);
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { fontSize: "14px" },
                                      }}
                                      InputProps={{
                                        style: {
                                          fontSize: "13px",
                                        },
                                        readOnly: true,
                                      }}
                                      sx={{
                                        borderRadius: "5px",

                                        fontSize: "13px",
                                        width: "60px",
                                        // width: "13%",
                                        // "& input": {
                                        //   textAlign: "center",
                                        // },
                                        // "& .MuiOutlinedInput-input": {
                                        //   padding: "8px 0px",
                                        // },
                                      }}
                                    />
                                  )}
                                  {data.parameters.includes("Rest") && (
                                    <TextField
                                      id="outlined-basic"
                                      size="small"
                                      variant="outlined"
                                      placeholder="0"
                                      type="number"
                                      value={item?.rest}
                                      name="rest"
                                      onChange={(e) => {
                                        handleChange(e, mainindex, index1);
                                      }}
                                      onMouseLeave={() =>
                                        handleMouseEnter(index1, mainindex)
                                      }
                                      onClick={() => handleMouseLeave()}
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { fontSize: "14px" },
                                      }}
                                      label="Rest"
                                      InputProps={{
                                        style: {
                                          fontSize: "13px",
                                          paddingRight: 0,
                                        },
                                        readOnly: true,
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <Select
                                              id="select-option"
                                              variant="outlined"
                                              size="small"
                                              name="restType"
                                              value={item?.restType}
                                              label="Weight Type"
                                              readOnly
                                              inputProps={{
                                                style: {
                                                  padding: 0,
                                                },
                                              }}
                                              onChange={(e) => {
                                                handleChange(
                                                  e,
                                                  mainindex,
                                                  index1
                                                );
                                              }}
                                              sx={{
                                                "& legend": {
                                                  display: "none",
                                                },
                                                "& fieldset": {
                                                  top: 0,
                                                },
                                                fontSize: "13px",
                                                border: "none",
                                                "& fieldset": {
                                                  border: "none",
                                                },
                                                "& .MuiOutlinedInput-input": {
                                                  padding: 0,
                                                },
                                              }}
                                            >
                                              <MenuItem value="sec">
                                                sec
                                              </MenuItem>
                                              <MenuItem value="min">
                                                min
                                              </MenuItem>
                                            </Select>
                                          </InputAdornment>
                                        ),
                                        // endAdornment: (
                                        //   <InputAdornment position="end">
                                        //     <div style={{ fontSize: "11px" }}>
                                        //       sec
                                        //     </div>
                                        //   </InputAdornment>
                                        // ),
                                      }}
                                      sx={{
                                        borderRadius: "5px",

                                        fontSize: "13px",
                                        // width: "20%",
                                        width: "100px",
                                      }}
                                    />
                                  )}
                                </Stack>
                              </div>
                            </Tooltip>
                          ))}
                        {data?.open && (
                          <TextField
                            id="outlined-basic"
                            // label="Exercise"
                            size="small"
                            variant="outlined"
                            placeholder="Instructions"
                            label="Instructions"
                            type="number"
                            value={data.description}
                            name="description"
                            multiline
                            rows={2}
                            onChange={(e) => handleChangeDes(e, mainindex)}
                            InputLabelProps={{
                              shrink: true,
                              style: { fontSize: "14px" },
                            }}
                            InputProps={{
                              readOnly: true,
                              style: { fontSize: "14px" },
                            }}
                            sx={{
                              borderRadius: "5px",
                              // "& legend": { display: "none" },
                              // "& fieldset": { top: 0 },

                              mt: 2,
                              width: "100%",
                            }}
                          />
                        )}

                        {/* <span
                          className="cross-icon"
                          style={{
                            position: "absolute",
                            bottom: "-37px",

                            width: "100%",
                            justifyContent: "center",
                            zIndex: 1,
                        
                          }}
                        >
                          {addArray.length > 1 &&
                          mainindex < addArray.length - 1 ? (
                            <div className="">
                              <SyncIcon
                                onClick={() => handleAgreeExercise(mainindex)}
                                className="sync-icon-add"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </span> */}
                      </Box>
                    </div>
                  )}
                </>
              );
            })}
          </form>
        </div>
      </div>
    </div>
  );
};
export default WorkoutExercisesUIClient;
