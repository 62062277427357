import { invokeApi } from "../../bl_libs/invokeApi";

export const ExerciseTypeListingApi = async (page, limt, search) => {
  const requestObj = {
    path: `/api/exercise_type?page=${page}&limit=${limt}&search=${search}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AllActiveExerciseTypeListingApi = async () => {
  const requestObj = {
    path: `/api/exercise_type/all_active/exercise_types`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const exercise_detail_api = async (slug) => {
  const requestObj = {
    path: `/api/exercise/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const AddExerciseTypeApi = async (data) => {
  const requestObj = {
    path: `/api/exercise_type`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const EditExerciseTypeApi = async (data, slug) => {
  const requestObj = {
    path: `/api/exercise_type/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteExerciseTypeApi = async (slug) => {
  const requestObj = {
    path: `/api/exercise_type/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
