import {
  Autocomplete,
  Avatar,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Tooltip,
  Box,
  Button,
  Typography,
  Chip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SyncIcon from "@mui/icons-material/Sync";

import EditIcon from "@mui/icons-material/Edit";
import { makeStyles } from "@mui/styles";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import CloseIcon from "@mui/icons-material/Close";
import { ExerciseListingApi } from "src/DAL/exercise/exercise";

import { s3baseUrl } from "src/config/config";

import faker from "faker";

// import CustomPopovers from "src/components/GeneralComponents/CustomPopovers";
// import ToshowExercise from "./components/modalToshowExercise";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";

import Label from "src/components/Label";
import ToshowExercise from "./component/modalToshowExercise";

import ConfirmationWithDescription from "src/components/ModalPopover/ConfirmationWithDescription";
import { Icon } from "@iconify/react";
import AMRAPExercisesUI from "./AMRAPExercisesUI";
import EMOMExercisesUI from "./EMOMExercisesUI";
import SomeThingElseExercisesUI from "./SomeThingElseExercisesUI";
import { paceFromVdot } from "src/utils/constant";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 20,
    },
  },
};
const Input = styled("input")({
  display: "none",
});
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  customInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed

      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },

  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      backgroundColor: "#ebebeb",
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
}));
const finalSpaceCharacters = [
  {
    id: "gary",
    name: "Gary Goodspeed",
    thumb: "/images/gary.png",
  },
  {
    id: "cato",
    name: "Little Cato",
    thumb: "/images/cato.png",
  },
  {
    id: "kvn",
    name: "KVN",
    thumb: "/images/kvn.png",
  },
  {
    id: "mooncake",
    name: "Mooncake",
    thumb: "/images/mooncake.png",
  },
  {
    id: "quinn",
    name: "Quinn Ergon",
    thumb: "/images/quinn.png",
  },
];
const findPaceObjectByVdot = (vdotValue) => {
  return paceFromVdot.find((paceObject) => paceObject.vdot == vdotValue);
};
const WorkoutExercisesUILogedClient = ({
  addArray,
  setaddArray,
  setModalChangeExe,
  message,
  isPersonal,
}) => {
  const [characters, updateCharacters] = useState(finalSpaceCharacters);
  const { state } = useLocation();
  const myInputRef = useRef(null);
  const [serchText, setSerchText] = useState("");
  const [superAlterIndex, setSuperAlterIndex] = useState("");
  const [alterChanges, setAlterChanges] = useState("");
  const [toAlterChanges, setToAlterChanges] = useState("");
  const [openExerciseAlter, setOpenExerciseAlter] = useState(false);
  const [openSuperSetAlter, setOpenSuperSetAlter] = useState(false);
  const [alterIndex, setalterIndex] = useState();

  const [navItems, setNavitems] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isReaload, setIsReaload] = useState(false);
  const [exercise, setExercise] = useState([]);
  const [workoutTitle, setWorkoutTitle] = useState("Workout Name !");
  const [file, setImage] = React.useState({});
  const [previews, setPreviews] = useState("");
  const [imageStatus, setImageStatus] = useState(false);
  const [oldProfile, setOldProfile] = useState();
  const [detailsData, setDetailsData] = useState("");
  const [exerIndex, setExerIndex] = useState("");

  const [openDetails, setOpenDetails] = useState(false);
  const [workoutDescription, setWorkoutDescription] = useState(
    "Workout description"
  );
  const [isHovered, setIsHovered] = useState("");
  const [isHoveredExercise, setIsHoveredExercise] = useState("");
  const [isExerciseForSuperSet, setIsExerciseForSuperSet] = useState(false);
  const handleUpload = (event) => {
    setImageStatus(true);
    // setImage(event.target.files[0]);
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();

  const [isFocused, setIsFocused] = useState(false);
  const [isFocused1, setIsFocused1] = useState(false);
  const handleMouseEnter = (setIndex, exerciseIndex) => {
    setIsHovered(setIndex);
    setIsHoveredExercise(exerciseIndex);
  };
  const timeoutRef = useRef(null);

  useEffect(() => {
    // Cleanup function to clear the timeout on component unmount
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);
  const handleMouseLeave = () => {
    // Introduce a delay of 100 milliseconds before updating state on hover out

    const delay = 0;
    timeoutRef.current = setTimeout(() => {
      setIsHovered("");
    }, delay);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleFocus1 = () => {
    setIsFocused1(true);
  };

  const handleBlur1 = () => {
    setIsFocused1(false);
  };
  function durationToSeconds(durationString) {
    // Regular expression to match a number and a time unit (sec, min, hour, etc.)
    const regex = /(\d+)\s+(sec|min|hour|hr|day|week|month|year)s?/i;

    // Use the regex to extract the number and unit from the input string

    const match = durationString && durationString.match(regex);

    if (match) {
      const value = parseInt(match[1], 10);
      const unit = match[2].toLowerCase();

      // Define conversion factors for different time units
      const unitToSeconds = {
        sec: 1,
        min: 60,
        hour: 3600,
        hr: 3600,
        day: 86400,
        week: 604800,
        month: 2592000,
        year: 31536000,
      };

      if (unitToSeconds.hasOwnProperty(unit)) {
        return value * unitToSeconds[unit];
      }
    }

    return 0; // Invalid duration format
  }
  const handleAdd = (i) => {
    let list = addArray;
    list.splice(i + 1, 0, {
      exercise: "",
      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          distance: "",
          distanceType: "m",
          reps: "",
          rest: "",
        },
      ],
      description: "",
      superSet: [],
      superset_sets: "",
    });

    setModalChangeExe(true);
    setaddArray([...list]);
  };
  const handleAddSet = (mainindex, index) => {
    let list = addArray;

    list[mainindex]["set"].splice(index + 1, 0, {
      time: "",
      weight: "",
      weightType: "kg",
      distance: "",
      distanceType: "m",
      reps: "",
      rest: "",
    });

    setModalChangeExe(true);
    setaddArray([...list]);
  };
  const handleDeleteSet = (mainindex, index) => {
    let list = addArray;

    list[mainindex]["set"].splice(index, 1);

    setModalChangeExe(true);
    setaddArray([...list]);
  };

  const handleAddSupersetSets = (mainindex, index) => {
    let list = addArray;

    list[mainindex]["set"].splice(index + 1, 0, {
      time: "",
      weight: "",
      weightType: "kg",
      distance: "",
      distanceType: "m",
      reps: "",
      rest: "",
    });

    setModalChangeExe(true);
    setaddArray([...list]);
  };

  const addSuperSet2 = (index) => {
    //  const { name, value } = e.target;

    let current = { ...addArray[index] };
    current.superSet = [];
    let array = [current];

    if (addArray[index + 1].superSet.length > 0) {
      addArray[index + 1].superSet.map((item) => {
        array.push(item);
      });
      const list = [...addArray];
      list[index]["superSet"] = array;

      setModalChangeExe(true);
      setaddArray(list);
      handleDelete(index + 1);
    } else {
      if (addArray[index + 1].set.length > addArray[index].set.length) {
        const setDifference =
          addArray[index + 1].set.length - addArray[index].set.length;
        addArray[index + 1].set.splice(0, setDifference);
      } else {
        const setDifference =
          addArray[index].set.length - addArray[index + 1].set.length;
        if (setDifference > 0) {
          addArray[index + 1].set.push(
            ...Array.from({ length: setDifference }, () => ({
              time: "",
              weight: "",
              weightType: "kg",
              distance: "",
              distanceType: "m",
              reps: "",
              rest: "",
            }))
          );
        }
      }
      console.log(
        addArray[index + 1].set.length,
        "fdhskdjhfkja",
        addArray[index].set.length
      );
      array.push(addArray[index + 1]);
      const list = [...addArray];
      list[index]["superSet"] = array;

      setModalChangeExe(true);
      setaddArray(list);
      handleDelete(index + 1);
    }
  };
  const handleDeleteExer = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);

    setModalChangeExe(true);
    setaddArray(delValue);
  };
  const removeSuperSet = (mainIndex, index) => {
    //  const { name, value } = e.target;
    const list = [...addArray];
    let firstPart = list[mainIndex]["superSet"].slice(0, index + 1);
    let secondPart = list[mainIndex]["superSet"].slice(index + 1);
    console.log(firstPart, secondPart, "dlfkjksdhfjas");
    let firstObject = { ...firstPart[0] };
    if (index > 0) {
      firstObject.superSet = firstPart;
      firstObject.superset_sets = list[mainIndex].superset_sets;
      console.log(firstObject, "fdjhsjkdfjkajds1", list[mainIndex]);
      // superset_sets;
    }

    let secondObject = { ...secondPart[0] };
    if (secondPart.length > 1) {
      secondObject.superSet = secondPart;
      secondObject.superset_sets = list[mainIndex].superset_sets;
      console.log(firstObject, "fdjhsjkdfjkajds2", list[mainIndex]);
    }

    let list1 = addArray;
    list1.splice(mainIndex + 1, 0, secondObject);
    list1.splice(mainIndex + 1, 0, firstObject);

    setModalChangeExe(true);
    setaddArray([...list1]);
    handleDeleteExer(mainIndex);
  };

  const handleAgreeSuperSet = (mainindex) => {
    setSuperAlterIndex(mainindex);

    setAlterChanges(
      addArray[mainindex + 1].superSet.length
        ? addArray[mainindex + 1].superset_sets
        : addArray[mainindex + 1].set.length
    );

    setToAlterChanges(
      addArray[mainindex].superSet.length
        ? addArray[mainindex].superset_sets
        : addArray[mainindex].set.length
    );

    const first = addArray[mainindex].superSet.length
      ? addArray[mainindex].superset_sets
      : addArray[mainindex].set.length;
    const second = addArray[mainindex + 1].superSet.length
      ? addArray[mainindex + 1].superset_sets
      : addArray[mainindex + 1].set.length;

    if (first == second) {
      addInSuperSet(mainindex);
      console.log(alterChanges, toAlterChanges, "dfjkhakjsdhfa");
    } else setOpenSuperSetAlter(true);
  };
  const handleAgreeExercise = (mainindex) => {
    setalterIndex(mainindex);

    setAlterChanges(
      addArray[mainindex + 1].superSet.length
        ? addArray[mainindex + 1].superset_sets
        : addArray[mainindex + 1].set.length
    );

    setToAlterChanges(
      addArray[mainindex].superSet.length
        ? addArray[mainindex].superSet.length
        : addArray[mainindex].set.length
    );

    const first = addArray[mainindex].superSet.length
      ? addArray[mainindex].superSet.length
      : addArray[mainindex].set.length;
    const second = addArray[mainindex + 1].superSet.length
      ? addArray[mainindex + 1].superset_sets
      : addArray[mainindex + 1].set.length;

    if (first == second) {
      addSuperSet(mainindex);
    } else {
      setOpenExerciseAlter(true);
    }
  };
  const addAgreeSuperSet = (index) => {
    //  const { name, value } = e.target;

    let current = { ...addArray[alterIndex] };
    current.superSet = [];
    let array = [current];

    if (
      addArray[alterIndex + 1]?.superSet &&
      addArray[alterIndex + 1].superSet.length > 0
    ) {
      addArray[alterIndex + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[alterIndex + 1]);
    }

    const list = [...addArray];
    list[alterIndex]["superSet"] = array;

    setModalChangeExe(true);
    setaddArray(list);

    let e = {
      target: {
        name: "superset_sets",
        value: addArray[alterIndex].set.length,
      },
    };
    handleChangeSuperSetCount(e, alterIndex);
    handleDelete(alterIndex + 1);
    setOpenExerciseAlter(false);
  };
  const addSuperSet = (index) => {
    //  const { name, value } = e.target;

    console.log(index, "dsjkhdkfgs", alterIndex);
    let current = { ...addArray[index] };
    current.superSet = [];
    let array = [current];

    if (
      addArray[index + 1]?.superSet &&
      addArray[index + 1].superSet.length > 0
    ) {
      addArray[index + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[index + 1]);
    }

    const list = [...addArray];
    list[index]["superSet"] = array;

    setModalChangeExe(true);
    setaddArray(list);

    let e = {
      target: {
        name: "superset_sets",
        value: addArray[index].set.length,
      },
    };
    handleChangeSuperSetCount(e, index);
    handleDelete(index + 1);
    setOpenExerciseAlter(false);
  };
  const addInSuperSet = (index) => {
    let array = addArray[index].superSet;
    if (addArray[index + 1].superSet.length > 0) {
      addArray[index + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[index + 1]);
    }
    const list = [...addArray];
    list[index]["superSet"] = array;

    setModalChangeExe(true);
    setaddArray(list);
    let e = {
      target: {
        name: "superset_sets",
        value: list[index].superset_sets,
      },
    };
    handleChangeSuperSetCount(e, index);
    handleDelete(index + 1);
    setOpenSuperSetAlter(false);
  };
  const addAgreeInSuperSet = () => {
    let array = addArray[superAlterIndex].superSet;
    if (addArray[superAlterIndex + 1].superSet.length > 0) {
      addArray[superAlterIndex + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[superAlterIndex + 1]);
    }
    const list = [...addArray];
    list[superAlterIndex]["superSet"] = array;

    setModalChangeExe(true);
    setaddArray(list);
    let e = {
      target: {
        name: "superset_sets",
        value: list[superAlterIndex].superset_sets,
      },
    };
    handleChangeSuperSetCount(e, superAlterIndex);
    handleDelete(superAlterIndex + 1);
    setOpenSuperSetAlter(false);
  };
  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);

    setModalChangeExe(true);
    setaddArray(delValue);
  };
  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };

  const get_accessment_setting = async () => {
    setIsReaload(true);
    const result = await _get_attitude_accessment_setting();
    if (result.code == 200) {
      if (result.assessment_setting[level]?.length > 0) {
        const config_array = result.assessment_setting[level]?.map(
          (config, i) => {
            return {
              ...config,
              id: "coin" + i,
            };
          }
        );

        setModalChangeExe(true);
        setaddArray(config_array);
      }
      setIsReaload(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsReaload(false);
    }
  };

  const handleArrow = (value, index) => {
    //  const { name, value } = e.target;

    const list = [...addArray];
    list[index]["open"] = value;

    // setModalChangeExe(true);
    setaddArray(list);
  };
  const handleArrowSuperSet = (value, mainindex, exerIndex) => {
    //  const { name, value } = e.target;

    const list = [...addArray];
    list[mainindex]["superSet"][exerIndex]["open"] = value;

    // setModalChangeExe(true);
    setaddArray(list);
  };
  const handleChangeOther = (e, index) => {
    const list = [...addArray];

    list[index]["exercise"] = e;
    list[index]["description"] = e?.instructions;

    setModalChangeExe(true);
    setaddArray(list);
  };
  function handleOnDragEnd(result) {
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setModalChangeExe(true);
    setaddArray(items);
  }
  const handleChangeNavItem = (event, index) => {
    let list = [...addArray];
    const {
      target: { value },
    } = event;
    list[index]["nav_items"] =
      typeof value === "string" ? value.split(",") : value;

    setModalChangeExe(true);
    setaddArray(list);
    setNavitems(typeof value === "string" ? value.split(",") : value);
  };
  const handleChange = (e, index, index1) => {
    const { name, value } = e.target;

    const list = [...addArray];
    console.log(list, "sdflkjsdak", list[index]["set"][index1][name]);
    list[index]["set"][index1][name] = value;

    setModalChangeExe(true);
    setaddArray(list);
  };
  const getVdotPace = (index, index1) => {
    const list = [...addArray];
    const vodt = list[index]["set"][index1]["vdot"];
    const distanceType = list[index]["set"][index1]["race_type"];
    const paceType = list[index]["set"][index1]["pace_type"];

    // Example: Find object with vdot value of 33
    const vdot33Object = findPaceObjectByVdot(vodt);
    if (distanceType != "el") {
      list[index]["set"][index1]["pace"] = vdot33Object[paceType];
    } else {
      list[index]["set"][index1]["pace"] = vdot33Object[paceType + "_low"];
      list[index]["set"][index1]["paceUp"] = vdot33Object[paceType + "_up"];
    }

    setaddArray(list);
    // setModalChangeExe(true);
  };
  const handleChangeVdotPaceType = (e, index, index1) => {
    const { name, value } = e.target;

    const list = [...addArray];

    list[index]["set"][index1][name] = value;
    setaddArray(list);
    setModalChangeExe(true);
    getVdotPace(index, index1);
  };
  const handleChangeVdot = (e, index, index1) => {
    const { name, value } = e.target;

    const list = [...addArray];

    list[index]["set"][index1][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
    getVdotPace(index, index1);
  };
  const handleChangeVdotDistance = (e, index, index1) => {
    const { name, value } = e.target;
    const list = [...addArray];
    list[index].set[index1][name] = value;

    let optionsTypeSArray = [];

    switch (value) {
      case "r":
        optionsTypeSArray = [
          { title: "200 Meters", key: "r_200_m" },
          { title: "300 Meters", key: "r_300_m" },
          { title: "400 Meters", key: "r_400_m" },
          { title: "600 Meters", key: "r_600_m" },
          { title: "800 Meters", key: "r_800_m" },
        ];
        break;

      case "m":
        optionsTypeSArray = [
          { title: "KM", key: "m_km" },
          { title: "MILE", key: "m_mi" },
        ];
        break;

      case "t":
        optionsTypeSArray = [
          { title: "400 Meters", key: "i_400_m" },
          { title: "KM", key: "t_km" },
          { title: "MILE", key: "t_mi" },
        ];
        break;

      case "i":
        optionsTypeSArray = [
          { title: "400 Meters", key: "i_400_m" },
          { title: "KM", key: "i_km" },
          { title: "1200 Meters", key: "i_1200_m" },
          { title: "MILE", key: "i_mi" },
        ];
        break;

      default:
        optionsTypeSArray = [
          { title: "KM", key: "e_km" },
          { title: "MILE", key: "e_mi" },
        ];
    }
    list[index].set[index1]["optionsType"] = optionsTypeSArray;
    list[index].set[index1]["pace_type"] = optionsTypeSArray[0].key;

    setaddArray(list);
    setModalChangeExe(true);
    getVdotPace(index, index1);
  };
  const handleChangeSuperSetDes = (e, index, index1) => {
    const { name, value } = e.target;
    const list = [...addArray];
    list[index]["superSet"][index1][name] = value;

    setModalChangeExe(true);
    setaddArray(list);
  };
  const handleChangeDes = (e, index) => {
    const { name, value } = e.target;

    const list = [...addArray];
    list[index][name] = value;

    setModalChangeExe(true);
    setaddArray(list);
  };
  const handleChangeSuperSet = (e, mainindex, exerIndex, setIndex) => {
    const { name, value } = e.target;

    const list = [...addArray];

    list[mainindex]["superSet"][exerIndex]["set"][setIndex][name] = value;

    setModalChangeExe(true);
    setaddArray(list);
  };
  const handleAddSetCount = (index) => {
    const list = [...addArray];
    const name = "superset_sets";
    const value = parseInt(list[index].superset_sets) + 1;
    // console.log(list[index].superset_sets, "ksdfjhkjsdnfkjs");
    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    list[index]?.superSet.forEach((superSetItem) => {
      const currentSetLength = superSetItem.set.length;

      if (currentSetLength < targetValue) {
        const objectsToAdd = targetValue - currentSetLength;

        for (let i = 0; i < objectsToAdd; i++) {
          superSetItem.set.push({
            time: "",
            weight: "",
            weightType: "kg",
            distance: "",
            distanceType: "m",
            reps: "",
            rest: "",
          });
        }
      } else if (currentSetLength > targetValue) {
        // If the new count is less than the current count, remove excess objects
        superSetItem.set.splice(targetValue);
      }
    });

    setModalChangeExe(true);
    setaddArray(list);
  };
  const handleMinusSetCount = (index) => {
    const list = [...addArray];
    const name = "superset_sets";
    const value = parseInt(list[index].superset_sets) - 1;
    // console.log(list[index].superset_sets, "ksdfjhkjsdnfkjs");
    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    list[index]?.superSet.forEach((superSetItem) => {
      const currentSetLength = superSetItem.set.length;

      if (currentSetLength < targetValue) {
        const objectsToAdd = targetValue - currentSetLength;

        for (let i = 0; i < objectsToAdd; i++) {
          superSetItem.set.push({
            time: "",
            weight: "",
            weightType: "kg",
            distance: "",
            distanceType: "m",
            reps: "",
            rest: "",
          });
        }
      } else if (currentSetLength > targetValue) {
        // If the new count is less than the current count, remove excess objects
        superSetItem.set.splice(targetValue);
      }
    });

    setModalChangeExe(true);
    setaddArray(list);
  };
  const handleChangeSuperSetCount = (e, index) => {
    const { name, value } = e.target;
    const list = [...addArray];

    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    list[index]?.superSet.forEach((superSetItem) => {
      const currentSetLength = superSetItem.set.length;

      if (currentSetLength < targetValue) {
        const objectsToAdd = targetValue - currentSetLength;

        for (let i = 0; i < objectsToAdd; i++) {
          superSetItem.set.push({
            time: "",
            weight: "",
            weightType: "kg",
            distance: "",
            distanceType: "m",
            reps: "",
            rest: "",
          });
        }
      } else if (currentSetLength > targetValue) {
        // If the new count is less than the current count, remove excess objects
        superSetItem.set.splice(targetValue);
      }
    });

    setModalChangeExe(true);
    setaddArray(list);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let exerciesArray = addArray.map((item) => {
      let data = {
        exercise_id: item?.superSet[0]?.exercise?._id
          ? item?.superSet[0]?.exercise?._id
          : item.exercise?._id,
        title: item?.superSet[0]?.exercise?.title
          ? item?.superSet[0]?.exercise?.title
          : item.exercise?.title,
        description: item.description,
        unit: item.weightType,
      };
      if (item.superset_sets) {
        data["superset_sets"] = item.superset_sets;
      }
      if (item.set) {
        data["sets"] = item.set;
      }
      if (item.reps) {
        data["reps"] = item.reps;
      }
      if (item.rest) {
        data["rest"] = item.rest;
      }
      if (item.weight) {
        data["weight"] = item.weight;
      }
      if (item.time) {
        data["duration"] = item.time;
      }
      if (item.superSet) {
        data["sub_exercises"] = [];
        if (item.superSet.length > 0) {
          data["sub_exercises"] = item.superSet.map((item) => {
            let data = {
              exercise_id: item.exercise?._id,
              title: item.exercise?.title,
              description: item.description,
              unit: item.weightType,
            };

            if (item.set) {
              data["sets"] = item.set;
            }
            if (item.reps) {
              data["reps"] = item.reps;
            }
            if (item.rest) {
              data["rest"] = item.rest;
            }
            if (item.weight) {
              data["weight"] = item.weight;
            }
            if (item.time) {
              data["duration"] = item.time;
            }

            return data;
          });
        }
      }
      return data;
      return {
        // sets: item.set,
        // reps: item.reps,
        // rest: item.rest,
        // weight: item.weight,
        // duration: item.time,
      };
    });
    const formData = new FormData();
    formData.append("title", workoutTitle);

    // formData.append("image", state.image);
    if (imageStatus) {
      formData.append("image", file);
    }
    formData.append("type", state.type);
    formData.append("description", workoutDescription);
    formData.append("status", state.status);
    formData.append("exercises", JSON.stringify(exerciesArray));
  };
  const MENU_OPTIONS = [
    {
      label: "Add Exercise",
      icon: "mingcute:add-fill",
      // handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      // handleClick: handleAgreeDelete,
    },
  ];
  const handleChangeUp = (value) => {
    if (value == 0) {
      enqueueSnackbar("You are already on Top !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value - 1, 0, reorderedItem);

    setModalChangeExe(true);
    setaddArray(items);
  };
  const handleExerciseList = (value) => {
    setIsExerciseForSuperSet(false);
    setDetailsData(value);
    setOpenDetails(true);
  };
  const handleSupersetExerciseList = (mainIndex, exerInde) => {
    setDetailsData(mainIndex);
    setExerIndex(exerInde);
    setIsExerciseForSuperSet(true);
  };
  const handleSelectExercise = (value) => {
    const list = [...addArray];
    list[detailsData]["exercise"] = value;
    list[detailsData]["description"] = value?.instructions;

    setModalChangeExe(true);
    setaddArray(list);
  };
  const handleSelectSupersetExercise = (value) => {
    const list = [...addArray];

    list[detailsData]["superSet"][exerIndex]["exercise"] = value;
    list[detailsData]["superSet"][exerIndex]["description"] =
      value?.instructions;

    setModalChangeExe(true);
    setaddArray(list);
  };
  const handleChangeDown = (value) => {
    if (value >= addArray.length - 1) {
      enqueueSnackbar("You are already on Bottom !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value + 1, 0, reorderedItem);

    setModalChangeExe(true);
    setaddArray(items);
  };
  const memueOptions = (value) => {
    const MENU_OPTIONS = [];

    MENU_OPTIONS.unshift(
      {
        label: "Move Up",
        icon: "mdi:arrow-up",
        handleClick: handleChangeUp,
        disabled: value == 0 ? true : false,
      },
      {
        label: "Move Down",
        icon: "ic:outline-arrow-downward",
        handleClick: handleChangeDown,
        disabled: value >= addArray.length - 1 ? true : false,
      }
    );

    return MENU_OPTIONS;
  };

  const getKmhr = (value, type) => {
    let result = 0;
    if (type?.includes("_200_")) {
      result = (200 * 3600) / (parseInt(value) * 1000);
    } else if (type?.includes("_300_")) {
      result = (300 * 3600) / (parseInt(value) * 1000);
    } else if (type?.includes("_400_")) {
      result = (400 * 3600) / (parseInt(value) * 1000);
    } else if (type?.includes("_600_")) {
      result = (600 * 3600) / (parseInt(value) * 1000);
    } else if (type?.includes("_800_")) {
      result = (800 * 3600) / (parseInt(value) * 1000);
    } else if (type?.includes("_1200_")) {
      result = (1200 * 3600) / (parseInt(value) * 1000);
    } else if (type?.includes("mi")) {
      result = (3600 / parseInt(value)) * 1.609;
    } else if (type?.includes("km")) {
      result = 3600 / parseInt(value);
    }
    return result.toFixed(2);
  };
  const getMihr = (value, type) => {
    let result = 0;
    if (type?.includes("_200_")) {
      result = (200 * 3600) / (parseInt(value) * 1000) / 1.609;
    } else if (type?.includes("_300_")) {
      result = (300 * 3600) / (parseInt(value) * 1000) / 1.609;
    } else if (type?.includes("_400_")) {
      result = (400 * 3600) / (parseInt(value) * 1000) / 1.609;
    } else if (type?.includes("_600_")) {
      result = (600 * 3600) / (parseInt(value) * 1000) / 1.609;
    } else if (type?.includes("_800_")) {
      result = (800 * 3600) / (parseInt(value) * 1000) / 1.609;
    } else if (type?.includes("_1200_")) {
      result = (1200 * 3600) / (parseInt(value) * 1000) / 1.609;
    } else if (type?.includes("mi")) {
      result = 3600 / parseInt(value);
    } else if (type?.includes("km")) {
      result = 3600 / parseInt(value) / 1.609;
    }
    return result.toFixed(2);
  };
  const handleMinutesChange = (e, index, index1) => {
    const { name, value } = e.target;
    const list = [...addArray];

    const minutes = parseInt(e.target.value) || 0;
    const seconds = list[index]["set"][index1][name] % 60;
    updateIntervalTime(minutes, seconds, index, index1, name);
  };

  const handleSecondsChange = (e, index, index1) => {
    const { name, value } = e.target;
    const list = [...addArray];

    let seconds = parseInt(e.target.value) || 0;
    seconds = Math.min(Math.max(seconds, 0), 59); // Ensure the value is between 0 and 60
    const minutes = Math.floor(list[index]["set"][index1][name] / 60);
    updateIntervalTime(minutes, seconds, index, index1, name);
  };

  // Function to update intervalTime state
  const updateIntervalTime = (minutes, seconds, index, index1, name) => {
    const totalSeconds = minutes * 60 + seconds;
    const list = [...addArray];
    list[index]["set"][index1][name] = totalSeconds;
    setaddArray(list);
    setModalChangeExe(true);
  };
  const formatWithLeadingZeros = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
  };
  if (isReaload === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-md">
      <ConfirmationWithDescription
        open={openSuperSetAlter}
        setOpen={setOpenSuperSetAlter}
        title={"Are you sure you want to take this action?"}
        descriotion={`You are trying to alternate an exercise with  ${toAlterChanges} sets with an exercise with ${alterChanges} sets. If you proceed, we’ll modify the exercise to have  ${toAlterChanges} sets.`}
        handleAgree={addAgreeInSuperSet}
      />
      <ConfirmationWithDescription
        open={openExerciseAlter}
        setOpen={setOpenExerciseAlter}
        title={"Are you sure you want to take this action?"}
        descriotion={`You are trying to alternate an exercise with  ${toAlterChanges} sets with an exercise with ${alterChanges} sets. If you proceed, we’ll modify the exercise to have  ${toAlterChanges} sets.`}
        handleAgree={addAgreeSuperSet}
      />
      <ToshowExercise
        open={openDetails}
        setOpen={setOpenDetails}
        handleChange={handleSelectExercise}
      />
      <ToshowExercise
        open={isExerciseForSuperSet}
        setOpen={setIsExerciseForSuperSet}
        handleChange={handleSelectSupersetExercise}
      />
      <div className="mt-2 ">
        <div className="characters">
          {/* <form onSubmit={handleSubmit}> */}

          <form onSubmit={() => {}}>
            {addArray?.map((data, mainindex) => {
              return (
                <>
                  {data?.type == "something_else" ? (
                    <SomeThingElseExercisesUI
                      addArray={addArray}
                      setaddArray={setaddArray}
                      setModalChangeExe={setModalChangeExe}
                      mainindex={mainindex}
                      isHistory={false}
                      memberId={""}
                      isPersonal={isPersonal}
                      message={message}
                    />
                  ) : data?.type == "amrap" ? (
                    <AMRAPExercisesUI
                      addArray={addArray}
                      setaddArray={setaddArray}
                      setModalChangeExe={setModalChangeExe}
                      mainindex={mainindex}
                      isHistory={false}
                      memberId={""}
                      isPersonal={isPersonal}
                      message={message}
                    />
                  ) : data?.type == "emom" ? (
                    <EMOMExercisesUI
                      addArray={addArray}
                      setaddArray={setaddArray}
                      setModalChangeExe={setModalChangeExe}
                      mainindex={mainindex}
                      isHistory={false}
                      memberId={""}
                      isPersonal={isPersonal}
                      message={message}
                    />
                  ) : data?.superSet && data?.superSet.length > 0 ? (
                    <>
                      <div
                        style={{ position: "relative" }}
                        className="workout-Superset-card my-1"
                      >
                        {/* <SuperSets length={data?.superset_sets} data={data} /> */}
                        <Box>
                          <div className="d-flex align-items-start justify-content-between ">
                            <div className="d-flex align-items-center w-100  mb-2">
                              <div>Superset of</div>
                              <div className="ms-2 cross-icon ">
                                <RemoveCircleIcon
                                  // onClick={() => handleMinusSetCount(mainindex)}
                                  className="diary-icon-plus-minus"
                                />
                              </div>

                              <TextField
                                id="outlined-basic"
                                size="small"
                                variant="outlined"
                                placeholder="Sets"
                                type="number"
                                value={data.superset_sets}
                                name="superset_sets"
                                disabled
                                onChange={(e) =>
                                  handleChangeSuperSetCount(e, mainindex)
                                }
                                InputLabelProps={{
                                  shrink: true,
                                  style: { fontSize: "14px" },
                                }}
                                //   label="Set"
                                InputProps={{
                                  style: { fontSize: "13px", padding: "0" },
                                }}
                                sx={{
                                  mx: 1,
                                  borderRadius: "5px",
                                  "& legend": { display: "none" },
                                  "& fieldset": { top: 0 },
                                  fontSize: "13px",
                                  "& input": {
                                    padding: "3px 5px",
                                    textAlign: "center",
                                  },
                                  width: "4%",
                                }}
                              />

                              <div className=" cross-icon me-2">
                                <AddCircleIcon
                                  // onClick={() => handleAddSetCount(mainindex)}
                                  className="diary-icon-plus-minus"
                                />
                              </div>
                              <div>sets</div>
                            </div>

                            <div className=" cross-icon ">
                              {addArray.length > 1 ? (
                                <>
                                  <Tooltip placement="top" title={message}>
                                    <RemoveCircleOutlineIcon
                                      // onClick={() => handleDelete(mainindex)}
                                      className="diary-icon-remove"
                                    />
                                  </Tooltip>
                                </>
                              ) : (
                                ""
                              )}
                              <Tooltip placement="top" title={message}>
                                <AddCircleOutlineIcon
                                  // onClick={() => handleAdd(mainindex)}
                                  className="diary-icon-add"
                                />
                              </Tooltip>
                              <Tooltip placement="top" title={message}>
                                <span>
                                  <CustomPopoverSection
                                    menu={[]}
                                    data={mainindex}
                                  />
                                </span>
                              </Tooltip>
                            </div>
                          </div>

                          <div className=" mb-2 ">
                            {data?.superSet?.map((exerData, exerIndex) => (
                              <div
                                style={{
                                  width: "100%",
                                  padding: "1px",
                                  position: "relative",
                                }}
                              >
                                <div
                                  className="d-flex workout-set-card"
                                  style={{
                                    width: "100%",
                                    position: "relative",
                                    overflow: "hidden",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      position: "relative",
                                      width: "100%",
                                    }}
                                  >
                                    {exerData?.is_modified && (
                                      <span
                                        style={{
                                          position: "absolute",
                                          top: "-11px",
                                          justifyContent: "center",
                                          zIndex: "1",
                                          left: "-38px",
                                          transform: "rotate(-53deg)",
                                        }}
                                      >
                                        <Chip
                                          label={"Modified"}
                                          variant="contained"
                                          color={"error"}
                                          sx={{
                                            fontSize: "10px",
                                            height: "auto",
                                            width: "auto",
                                          }}
                                        />
                                      </span>
                                    )}
                                    <div className="d-flex align-items-center ">
                                      <Tooltip placement="top" title={message}>
                                        <TextField
                                          id="outlined-basic"
                                          variant="outlined"
                                          placeholder="Exercise"
                                          value={exerData?.exercise?.title}
                                          name="Exercise"
                                          // onChange={(e) => {}}
                                          InputLabelProps={{
                                            shrink: true,
                                            style: { fontSize: "14px" },
                                          }}
                                          label="Exercise"
                                          InputProps={{
                                            style: {
                                              fontSize: "13px",
                                              paddingLeft: "6px",
                                            },
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                {exerData?.exercise?.image
                                                  ?.thumbnail_3 && (
                                                  <Avatar
                                                    sx={{ borderRadius: "5px" }}
                                                    alt="User Image"
                                                    src={
                                                      s3baseUrl +
                                                      exerData?.exercise?.image
                                                        ?.thumbnail_3
                                                    }
                                                  />
                                                )}
                                              </InputAdornment>
                                            ),
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <div
                                                  style={{ fontSize: "16px" }}
                                                >
                                                  {exerData?.exercise ? (
                                                    <EditIcon
                                                      fontSize="12px"
                                                      sx={{ opacity: 0.5 }}
                                                    />
                                                  ) : (
                                                    <AddIcon
                                                      sx={{ opacity: 0.5 }}
                                                    />
                                                  )}
                                                </div>
                                              </InputAdornment>
                                            ),
                                            inputProps: {
                                              readOnly: true, // Disable editing
                                            },
                                          }}
                                          sx={{
                                            borderRadius: "5px",

                                            fontSize: "13px",
                                            width: "50%",
                                          }}
                                        />
                                      </Tooltip>

                                      <div className="d-flex align-items-center mx-1">
                                        <div className="text-center">
                                          {!exerData?.open ? (
                                            <KeyboardArrowDownIcon
                                              sx={{
                                                cursor: "pointer",
                                                opacity: 0.5,
                                                fontSize: "20px",
                                              }}
                                              onClick={() => {
                                                handleArrowSuperSet(
                                                  true,
                                                  mainindex,
                                                  exerIndex
                                                );
                                              }}
                                            />
                                          ) : (
                                            <KeyboardArrowUpIcon
                                              sx={{
                                                cursor: "pointer",
                                                opacity: 0.5,
                                                fontSize: "20px",
                                              }}
                                              onClick={() => {
                                                handleArrowSuperSet(
                                                  false,
                                                  mainindex,
                                                  exerIndex
                                                );
                                              }}
                                            />
                                          )}
                                        </div>
                                        <Typography
                                          variant="body2"
                                          sx={{ opacity: 0.6 }}
                                        >
                                          {` Sets ${exerData?.set?.length}`}
                                        </Typography>
                                        <Tooltip
                                          placement="top"
                                          title={message}
                                        >
                                          <div className="d-flex align-items-center ms-3 set-parameters ">
                                            <Icon
                                              icon="pajamas:labels"
                                              fontSize="10px"
                                            />
                                            <Typography
                                              variant="body2"
                                              sx={{ ml: 0.5 }}
                                            >
                                              {`Parameters`}
                                            </Typography>
                                          </div>
                                        </Tooltip>
                                      </div>
                                    </div>

                                    {exerData?.open &&
                                      exerData?.set?.map((item, setIndex) => (
                                        <Tooltip
                                          placement="top"
                                          title={message}
                                        >
                                          <div className="d-flex align-items-center my-3 workout-card-inset">
                                            <Stack
                                              direction="row"
                                              spacing={1}
                                              alignItems="center"
                                              justifyContent="start"
                                            >
                                              <Label
                                                variant={"ghost"}
                                                sx={{ padding: "25px 4px" }}
                                              >
                                                {setIndex + 1}
                                              </Label>
                                              {/* <TextField
                                            id="outlined-basic"
                                            size="small"
                                            variant="outlined"
                                            placeholder="Set"
                                            type="number"
                                            // value={item.set}
                                            name="set"
                                            disabled
                                            InputLabelProps={{
                                              shrink: true,
                                              style: { fontSize: "14px" },
                                            }}
                                            label="Set"
                                            InputProps={{
                                              style: { fontSize: "13px" },
                                            }}
                                            sx={{
                                              borderRadius: "5px",
                                              fontSize: "13px",
                                              width: "13%",
                                            }}
                                          /> */}
                                              <CloseIcon
                                                sx={{ opacity: 0.3 }}
                                              />

                                              {exerData.parameters.includes(
                                                "Duration/Pace"
                                              ) && (
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="0"
                                                  type="number"
                                                  value={item?.time}
                                                  name="time"
                                                  onChange={(e) => {
                                                    handleChangeSuperSet(
                                                      e,
                                                      mainindex,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  label="Duration/Pace"
                                                  InputProps={{
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    readOnly: true,
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="timeType"
                                                          value={item?.timeType}
                                                          label="Weight Type"
                                                          readOnly
                                                          inputProps={{
                                                            style: {
                                                              padding: 0,
                                                            },
                                                          }}
                                                          onChange={(e) => {
                                                            handleChangeSuperSet(
                                                              e,
                                                              mainindex,
                                                              exerIndex,
                                                              setIndex
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                            "& .MuiOutlinedInput-input":
                                                              {
                                                                padding: 0,
                                                              },
                                                          }}
                                                        >
                                                          <MenuItem value="sec">
                                                            sec
                                                          </MenuItem>
                                                          <MenuItem value="min">
                                                            min
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                    // endAdornment: (
                                                    //   <InputAdornment position="end">
                                                    //     <div
                                                    //       style={{
                                                    //         fontSize: "11px",
                                                    //       }}
                                                    //     >
                                                    //       sec
                                                    //     </div>
                                                    //   </InputAdornment>
                                                    // ),
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    fontSize: "13px",
                                                    // width: "20%",
                                                    width: "100px",
                                                  }}
                                                />
                                              )}
                                              {exerData.parameters.includes(
                                                "Reps"
                                              ) && (
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="Reps"
                                                  type="number"
                                                  label="Reps"
                                                  value={item?.reps}
                                                  name="reps"
                                                  onChange={(e) => {
                                                    handleChangeSuperSet(
                                                      e,
                                                      mainindex,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                    style: { fontSize: "14px" },
                                                  }}
                                                  InputProps={{
                                                    style: { fontSize: "13px" },
                                                    readOnly: true,
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    // "& legend": { display: "none" },
                                                    // "& fieldset": { top: 0 },
                                                    fontSize: "13px",
                                                    width: "13%",
                                                    width: "60px",
                                                  }}
                                                />
                                              )}
                                              {exerData.parameters.includes(
                                                "Weight"
                                              ) && (
                                                <TextField
                                                  sx={{
                                                    borderRadius: "5px",
                                                    // "& legend": { display: "none" },
                                                    // "& fieldset": { top: 0 },
                                                    paddingRight: 0,
                                                    // width: "45%",
                                                    width: "175px",
                                                  }}
                                                  // className={classes.customInput}
                                                  size="small"
                                                  id="custom-input"
                                                  variant="outlined"
                                                  placeholder="Weight"
                                                  name="weight"
                                                  label="Weight"
                                                  value={item.weight}
                                                  onChange={(e) => {
                                                    handleChangeSuperSet(
                                                      e,
                                                      mainindex,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  type="number"
                                                  InputLabelProps={{
                                                    shrink: true,
                                                    style: { fontSize: "14px" },
                                                  }}
                                                  InputProps={{
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    readOnly: true,
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="weightType"
                                                          value={
                                                            item?.weightType
                                                          }
                                                          label="Weight Type"
                                                          readOnly
                                                          onChange={(e) => {
                                                            handleChangeSuperSet(
                                                              e,
                                                              mainindex,
                                                              exerIndex,
                                                              setIndex
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                          }}
                                                        >
                                                          <MenuItem value="1rm">
                                                            % 1RM
                                                          </MenuItem>
                                                          <MenuItem value="body_weight">
                                                            % Bodyweight
                                                          </MenuItem>
                                                          <MenuItem value="kg">
                                                            kilogram
                                                          </MenuItem>
                                                          <MenuItem value="lb">
                                                            Pound
                                                          </MenuItem>
                                                          <MenuItem value="poods">
                                                            Poods
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                              )}
                                              {exerData.parameters.includes(
                                                "Distance/Calories"
                                              ) && (
                                                <TextField
                                                  sx={{
                                                    borderRadius: "5px",
                                                    // "& legend": { display: "none" },
                                                    // "& fieldset": { top: 0 },
                                                    paddingRight: 0,
                                                    // width: "45%",
                                                    width: "175px",
                                                  }}
                                                  // className={classes.customInput}
                                                  size="small"
                                                  id="custom-input"
                                                  variant="outlined"
                                                  placeholder={
                                                    item?.distanceType ==
                                                    "calories"
                                                      ? "Calories"
                                                      : "Distance"
                                                  }
                                                  name="distance"
                                                  label="Distance/Calories"
                                                  value={item?.distance}
                                                  onChange={(e) => {
                                                    handleChangeSuperSet(
                                                      e,
                                                      mainindex,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  type="number"
                                                  InputLabelProps={{
                                                    shrink: true,
                                                    style: { fontSize: "14px" },
                                                  }}
                                                  InputProps={{
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    readOnly: true,
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="distanceType"
                                                          value={
                                                            item?.distanceType
                                                          }
                                                          label="Weight Type"
                                                          readOnly
                                                          onChange={(e) => {
                                                            handleChangeSuperSet(
                                                              e,
                                                              mainindex,
                                                              exerIndex,
                                                              setIndex
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                          }}
                                                        >
                                                          <MenuItem value="m">
                                                            Meter
                                                          </MenuItem>
                                                          <MenuItem value="km">
                                                            Kilometer
                                                          </MenuItem>
                                                          <MenuItem value="mi">
                                                            Mile
                                                          </MenuItem>
                                                          <MenuItem value="calories">
                                                            Calories
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                              )}

                                              {exerData.parameters.includes(
                                                "Tempo"
                                              ) && (
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="Tempo"
                                                  type="number"
                                                  label="Tempo"
                                                  value={item?.tempo}
                                                  name="tempo"
                                                  onChange={(e) => {
                                                    handleChangeSuperSetTempo(
                                                      e,
                                                      mainindex,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                    style: { fontSize: "14px" },
                                                  }}
                                                  InputProps={{
                                                    style: {
                                                      fontSize: "13px",
                                                    },
                                                    readOnly: true,
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",

                                                    fontSize: "13px",
                                                    width: "60px",
                                                    // width: "13%",
                                                    // "& input": {
                                                    //   textAlign: "center",
                                                    // },
                                                    // "& .MuiOutlinedInput-input":
                                                    //   {
                                                    //     padding: "8px 0px",
                                                    //   },
                                                  }}
                                                />
                                              )}
                                              {exerData.parameters.includes(
                                                "Rest"
                                              ) && (
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="0"
                                                  type="number"
                                                  value={item?.rest}
                                                  name="rest"
                                                  onChange={(e) => {
                                                    handleChangeSuperSet(
                                                      e,
                                                      mainindex,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                    style: { fontSize: "14px" },
                                                  }}
                                                  label="Rest"
                                                  InputProps={{
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    readOnly: true,
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="restType"
                                                          value={item?.restType}
                                                          label="Weight Type"
                                                          readOnly
                                                          inputProps={{
                                                            style: {
                                                              padding: 0,
                                                            },
                                                          }}
                                                          onChange={(e) => {
                                                            handleChangeSuperSet(
                                                              e,
                                                              mainindex,
                                                              exerIndex,
                                                              setIndex
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                            "& .MuiOutlinedInput-input":
                                                              {
                                                                padding: 0,
                                                              },
                                                          }}
                                                        >
                                                          <MenuItem value="sec">
                                                            sec
                                                          </MenuItem>
                                                          <MenuItem value="min">
                                                            min
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                    // endAdornment: (
                                                    //   <InputAdornment position="end">
                                                    //     <div
                                                    //       style={{
                                                    //         fontSize: "11px",
                                                    //       }}
                                                    //     >
                                                    //       sec
                                                    //     </div>
                                                    //   </InputAdornment>
                                                    // ),
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    fontSize: "13px",
                                                    // width: "20%",
                                                    width: "100px",
                                                  }}
                                                />
                                              )}
                                            </Stack>
                                          </div>
                                        </Tooltip>
                                      ))}
                                    {exerData?.open && (
                                      <Tooltip placement="top" title={message}>
                                        <TextField
                                          id="outlined-basic"
                                          size="small"
                                          variant="outlined"
                                          placeholder="Instructions"
                                          label="Instructions"
                                          type="number"
                                          value={exerData.description}
                                          name="description"
                                          multiline
                                          rows={2}
                                          onChange={(e) =>
                                            handleChangeSuperSetDes(
                                              e,
                                              mainindex,
                                              exerIndex
                                            )
                                          }
                                          InputLabelProps={{
                                            shrink: true,
                                            style: { fontSize: "14px" },
                                          }}
                                          InputProps={{
                                            readOnly: true,

                                            style: { fontSize: "14px" },
                                          }}
                                          sx={{
                                            borderRadius: "5px",
                                            // "& legend": { display: "none" },
                                            // "& fieldset": { top: 0 },

                                            mt: 2,
                                            width: "100%",
                                          }}
                                        />
                                      </Tooltip>
                                    )}
                                  </Box>
                                </div>
                                <span
                                  className="cross-icon"
                                  style={{
                                    position: "absolute",
                                    bottom: "-10px",

                                    width: "100%",
                                    justifyContent: "center",
                                    zIndex: 1,
                                  }}
                                >
                                  {data?.superSet.length > 1 &&
                                  exerIndex < data?.superSet.length - 1 ? (
                                    <Tooltip placement="top" title={message}>
                                      <div className="">
                                        <SyncDisabledIcon
                                          // onClick={() =>
                                          //   removeSuperSet(mainindex, exerIndex)
                                          // }
                                          className="sync-icon-remove"
                                        />
                                      </div>
                                    </Tooltip>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                            ))}
                          </div>
                        </Box>
                        <span
                          className="cross-icon"
                          style={{
                            position: "absolute",
                            bottom: "-17px",
                            width: "100%",
                            justifyContent: "center",
                            zIndex: 1,
                            // backgroundColor: "gray",
                            // borderRadius: "50%",
                            // padding: "4px 5px",
                          }}
                        >
                          {addArray.length > 1 &&
                          addArray[mainindex + 1]?.type == "general" &&
                          mainindex < addArray.length - 1 ? (
                            <Tooltip placement="top" title={message}>
                              <div className="">
                                <SyncIcon
                                  // onClick={() => handleAgreeSuperSet(mainindex)}
                                  className="sync-icon-add"
                                />
                              </div>
                            </Tooltip>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        padding: "1px",
                        position: "relative",
                      }}
                    >
                      <div
                        // direction="row"

                        className="d-flex my-1 workout-set-card w-100"
                        onMouseEnter={() => handleMouseLeave()}
                        style={{
                          width: "100%",
                          position: "relative",
                          overflow: "hidden",
                        }}
                      >
                        <Box
                          sx={{
                            position: "relative",
                            width: "100%",
                          }}
                          onMouseEnter={() => handleMouseLeave()}
                        >
                          <div
                            className="d-flex align-items-start justify-content-between w-100 "
                            onMouseEnter={() => handleMouseLeave()}
                          >
                            <div className="w-100 d-flex align-items-center">
                              {data?.is_modified && (
                                <span
                                  style={{
                                    position: "absolute",
                                    top: "-11px",
                                    justifyContent: "center",
                                    zIndex: "1",
                                    left: "-38px",
                                    transform: "rotate(-53deg)",
                                  }}
                                >
                                  <Chip
                                    label={"Modified"}
                                    variant="contained"
                                    color={"error"}
                                    sx={{
                                      fontSize: "10px",
                                      height: "auto",
                                      width: "auto",
                                    }}
                                  />
                                </span>
                              )}
                              <Tooltip placement="top" title={message}>
                                <TextField
                                  id="outlined-basic"
                                  variant="outlined"
                                  placeholder="Exercise"
                                  value={
                                    data?.exercise?.title
                                      ? data?.exercise?.title
                                      : ""
                                  }
                                  name="Exercise"
                                  // onChange={(e) => {}}
                                  InputLabelProps={{
                                    shrink: true,
                                    style: { fontSize: "14px" },
                                  }}
                                  label="Exercise"
                                  InputProps={{
                                    style: {
                                      fontSize: "13px",
                                      paddingLeft: "6px",
                                    },
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {data?.exercise?.image?.thumbnail_3 && (
                                          <Avatar
                                            sx={{ borderRadius: "5px" }}
                                            alt="User Image"
                                            src={
                                              s3baseUrl +
                                              data?.exercise?.image?.thumbnail_3
                                            }
                                          />
                                        )}
                                      </InputAdornment>
                                    ),
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <div style={{ fontSize: "16px" }}>
                                          {data?.exercise ? (
                                            <EditIcon
                                              fontSize="12px"
                                              sx={{ opacity: 0.5 }}
                                            />
                                          ) : (
                                            <AddIcon sx={{ opacity: 0.5 }} />
                                          )}
                                        </div>
                                      </InputAdornment>
                                    ),
                                    inputProps: {
                                      readOnly: true, // Disable editing
                                    },
                                  }}
                                  sx={{
                                    borderRadius: "5px",

                                    fontSize: "13px",
                                    width: "50%",
                                  }}
                                />
                              </Tooltip>

                              <div className="d-flex align-items-center mx-1">
                                <div className="text-center">
                                  {!data?.open ? (
                                    <KeyboardArrowDownIcon
                                      sx={{
                                        cursor: "pointer",
                                        opacity: 0.5,
                                        fontSize: "20px",
                                      }}
                                      onClick={() => {
                                        handleArrow(true, mainindex);
                                      }}
                                    />
                                  ) : (
                                    <KeyboardArrowUpIcon
                                      sx={{
                                        cursor: "pointer",
                                        opacity: 0.5,
                                        fontSize: "20px",
                                      }}
                                      onClick={() => {
                                        handleArrow(false, mainindex);
                                      }}
                                    />
                                  )}
                                </div>
                                <Typography
                                  variant="body2"
                                  sx={{ opacity: 0.6 }}
                                >
                                  {` Sets ${data?.set?.length}`}
                                </Typography>
                                <Tooltip placement="top" title={message}>
                                  <div className="d-flex align-items-center ms-3 set-parameters ">
                                    <Icon
                                      icon="pajamas:labels"
                                      fontSize="10px"
                                    />
                                    <Typography
                                      variant="body2"
                                      sx={{ ml: 0.5 }}
                                    >
                                      {`Parameters`}
                                    </Typography>
                                  </div>
                                </Tooltip>
                              </div>
                            </div>
                            <div className=" cross-icon ">
                              {addArray.length > 1 ? (
                                <>
                                  <Tooltip placement="top" title={message}>
                                    <RemoveCircleOutlineIcon
                                      // onClick={() => handleDelete(mainindex)}
                                      className="diary-icon-remove"
                                    />
                                  </Tooltip>
                                </>
                              ) : (
                                ""
                              )}
                              <Tooltip placement="top" title={message}>
                                <AddCircleOutlineIcon
                                  // onClick={() => handleAdd(mainindex)}
                                  className="diary-icon-add"
                                />
                              </Tooltip>
                              <Tooltip placement="top" title={message}>
                                <span>
                                  <CustomPopoverSection
                                    menu={[]}
                                    data={mainindex}
                                  />
                                </span>
                              </Tooltip>
                            </div>
                          </div>

                          {data?.open &&
                            data?.set?.map((item, index1) => (
                              <Tooltip placement="top" title={message}>
                                <div
                                  className="d-flex align-items-center my-3 workout-card-inset"
                                  onMouseEnter={() =>
                                    handleMouseEnter(index1, mainindex)
                                  }

                                  // onMouseLeave={() => handleMouseLeave()}
                                >
                                  {/* <div>
                                    {isHovered === index1 &&
                                      isHoveredExercise == mainindex && (
                                        <div
                                          className="icon-calendar-workout"
                                          onMouseEnter={() =>
                                            handleMouseEnter(index1, mainindex)
                                          }
                                        >
                                          <Tooltip title="Add">
                                            <AddIcon
                                              className="add-icon-calendar-workout me-1"
                                              onMouseEnter={() =>
                                                handleMouseEnter(
                                                  index1,
                                                  mainindex
                                                )
                                              }
                                              onClick={() =>
                                                handleAddSet(mainindex, index1)
                                              }
                                            />
                                          </Tooltip>
                                          <Tooltip
                                            title={`${
                                              data?.set.length > 1
                                                ? "Delete"
                                                : "Can't be Deleted"
                                            }`}
                                          >
                                            <DeleteIcon
                                              onMouseEnter={() =>
                                                handleMouseEnter(
                                                  index1,
                                                  mainindex
                                                )
                                              }
                                              className={`${
                                                data?.set.length > 1
                                                  ? "del-icon-calendar-workout-working"
                                                  : "del-icon-calendar-workout-notwork"
                                              }`}
                                              onClick={
                                                data?.set.length > 1
                                                  ? () =>
                                                      handleDeleteSet(
                                                        mainindex,
                                                        index1
                                                      )
                                                  : () => {}
                                              }
                                            />
                                          </Tooltip>
                                        </div>
                                      )}
                                  </div> */}
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    justifyContent="start"
                                  >
                                    <Label
                                      variant={"ghost"}
                                      sx={{ padding: "25px 4px" }}
                                    >
                                      {index1 + 1}
                                    </Label>
                                    {/* <TextField
                                  id="outlined-basic"
                                  onMouseLeave={() =>
                                    handleMouseEnter(index1, mainindex)
                                  }
                                  onClick={() => handleMouseLeave()}
                                  size="small"
                                  variant="outlined"
                                  placeholder="Set"
                                  type="number"
                                  // value={item.set}
                                  name="set"
                                  disabled
                                  InputLabelProps={{
                                    shrink: true,
                                    style: { fontSize: "14px" },
                                  }}
                                  label="Set"
                                  InputProps={{
                                    style: { fontSize: "13px" },
                                  }}
                                  sx={{
                                    borderRadius: "5px",
                                    // "& legend": { display: "none" },
                                    // "& fieldset": { top: 0 },
                                    fontSize: "13px",
                                    width: "13%",
                                  }}
                                /> */}
                                    <CloseIcon sx={{ opacity: 0.3 }} />
                                    {data.parameters.includes("Vdot") && (
                                      <>
                                        <TextField
                                          id="outlined-basic"
                                          size="small"
                                          variant="outlined"
                                          placeholder="0"
                                          type="number"
                                          onMouseLeave={() =>
                                            handleMouseEnter(index1, mainindex)
                                          }
                                          onClick={() => handleMouseLeave()}
                                          label="VDOT"
                                          value={item?.vdot}
                                          name="vdot"
                                          onChange={(e) => {
                                            handleChangeVdot(
                                              e,
                                              mainindex,
                                              index1
                                            );
                                          }}
                                          InputLabelProps={{
                                            shrink: true,
                                            style: { fontSize: "14px" },
                                          }}
                                          InputProps={{
                                            style: { fontSize: "13px" },
                                            readOnly: true,
                                          }}
                                          sx={{
                                            borderRadius: "5px",
                                            // "& legend": { display: "none" },
                                            // "& fieldset": { top: 0 },
                                            fontSize: "13px",
                                            // width: "13%",
                                            width: "60px",
                                          }}
                                        />
                                        <TextField
                                          id="outlined-basic"
                                          variant="outlined"
                                          label="Distance"
                                          InputLabelProps={{
                                            shrink: true,
                                            style: { fontSize: "14px" },
                                          }}
                                          InputProps={{
                                            style: {
                                              fontSize: "13px",
                                              paddingLeft: "0px",
                                              paddingRight: "0px",

                                              justifyContent: "center",
                                            },
                                            readOnly: true,
                                            endAdornment: (
                                              <InputAdornment
                                                position="end"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <Select
                                                  id="select-option"
                                                  variant="outlined"
                                                  size="small"
                                                  name="race_type"
                                                  value={item?.race_type}
                                                  label="Weight Type"
                                                  readOnly={true}
                                                  inputProps={{
                                                    style: {
                                                      padding: 0,
                                                    },
                                                    readOnly: true,
                                                  }}
                                                  onChange={(e) => {
                                                    handleChangeVdotDistance(
                                                      e,
                                                      mainindex,
                                                      index1
                                                    );
                                                  }}
                                                  sx={{
                                                    "& legend": {
                                                      display: "none",
                                                    },
                                                    "& fieldset": {
                                                      top: 0,
                                                    },
                                                    fontSize: "13px",
                                                    border: "none",
                                                    "& fieldset": {
                                                      border: "none",
                                                    },
                                                    "& .MuiOutlinedInput-input":
                                                      {
                                                        padding: 0,
                                                      },
                                                  }}
                                                >
                                                  <MenuItem value="el">
                                                    Easy/Long
                                                  </MenuItem>
                                                  <MenuItem value="m">
                                                    Marathon
                                                  </MenuItem>
                                                  <MenuItem value="t">
                                                    Threshold
                                                  </MenuItem>
                                                  <MenuItem value="i">
                                                    Interval
                                                  </MenuItem>
                                                  <MenuItem value="r">
                                                    Repitition
                                                  </MenuItem>
                                                </Select>

                                                <Select
                                                  id="select-option"
                                                  variant="outlined"
                                                  size="small"
                                                  name="pace_type"
                                                  value={item?.pace_type}
                                                  label="Weight Type"
                                                  readOnly={true}
                                                  inputProps={{
                                                    style: {
                                                      padding: 0,
                                                    },
                                                    readOnly: true,
                                                  }}
                                                  onChange={(e) => {
                                                    handleChangeVdotPaceType(
                                                      e,
                                                      mainindex,
                                                      index1
                                                    );
                                                  }}
                                                  sx={{
                                                    "& legend": {
                                                      display: "none",
                                                    },
                                                    "& fieldset": {
                                                      top: 0,
                                                    },
                                                    fontSize: "13px",
                                                    border: "none",
                                                    "& fieldset": {
                                                      border: "none",
                                                    },
                                                    "& .MuiOutlinedInput-input":
                                                      {
                                                        padding: 0,
                                                      },
                                                  }}
                                                >
                                                  {item?.optionsType?.map(
                                                    (option) => {
                                                      return (
                                                        <MenuItem
                                                          key={option?.key}
                                                          value={option?.key}
                                                        >
                                                          {option?.title}
                                                        </MenuItem>
                                                      );
                                                    }
                                                  )}
                                                </Select>
                                              </InputAdornment>
                                            ),
                                          }}
                                          sx={{
                                            borderRadius: "5px",
                                            fontSize: "13px",
                                            "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input":
                                              {
                                                width: "0px !important",
                                                padding: "9px 0px !important",
                                              },
                                            "& .css-1nuss9t": {
                                              width: "0px !important",
                                              padding: "9px 0px !important",
                                            },
                                            // "& input": {
                                            //   textAlign: "center",
                                            //   padding: "0px",
                                            // },
                                            width: "200px",
                                          }}
                                        />
                                        {item?.race_type == "el" ? (
                                          <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            label="Pace"
                                            InputLabelProps={{
                                              shrink: true,
                                              style: { fontSize: "14px" },
                                            }}
                                            InputProps={{
                                              style: {
                                                fontSize: "13px",
                                                paddingLeft: "0px",
                                                paddingRight: "0px",

                                                justifyContent: "center",
                                              },

                                              readOnly: true,
                                              endAdornment: (
                                                <InputAdornment
                                                  position="end"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <TextField
                                                    id="outlined-basic-minutes"
                                                    type="number"
                                                    size="small"
                                                    placeholder="Min"
                                                    name="pace"
                                                    value={formatWithLeadingZeros(
                                                      Math.floor(
                                                        item?.pace / 60
                                                      )
                                                    )}
                                                    readOnly={true}
                                                    onChange={(e) =>
                                                      handleMinutesChange(
                                                        e,
                                                        mainindex,
                                                        index1
                                                      )
                                                    }
                                                    InputProps={{
                                                      style: {
                                                        fontSize: "14px",
                                                        // width: "40px",
                                                      },
                                                      readOnly: true,
                                                    }}
                                                    sx={{
                                                      "& fieldset": {
                                                        border: "none",
                                                      },
                                                      // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                      //   {
                                                      //     padding: "0px !important",
                                                      //   },
                                                      "& input": {
                                                        textAlign: "center",
                                                        padding: "0px",
                                                      },
                                                      width: "100%",
                                                    }}
                                                  />
                                                  <div className="fs-6 fw-semibold ms-1 ">
                                                    :
                                                  </div>

                                                  <TextField
                                                    id="outlined-basic-seconds"
                                                    type="number"
                                                    size="small"
                                                    placeholder="Sec"
                                                    name="pace"
                                                    value={formatWithLeadingZeros(
                                                      item?.pace % 60
                                                    )}
                                                    onChange={(e) =>
                                                      handleSecondsChange(
                                                        e,
                                                        mainindex,
                                                        index1
                                                      )
                                                    }
                                                    InputProps={{
                                                      style: {
                                                        fontSize: "14px",
                                                        // width: "40px",
                                                      },
                                                      readOnly: true,
                                                    }}
                                                    sx={{
                                                      "& fieldset": {
                                                        border: "none",
                                                      },
                                                      // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                      //   {
                                                      //     padding: "0px !important",
                                                      //   },
                                                      "& input": {
                                                        textAlign: "center",
                                                        padding: "0px",
                                                      },
                                                      width: "100%",
                                                    }}
                                                  />
                                                  <div className="fs-6 fw-semibold ms-1 me-1 ">
                                                    -
                                                  </div>
                                                  <TextField
                                                    id="outlined-basic-minutes"
                                                    type="number"
                                                    size="small"
                                                    placeholder="Min"
                                                    name="paceUp"
                                                    value={formatWithLeadingZeros(
                                                      Math.floor(
                                                        item?.paceUp / 60
                                                      )
                                                    )}
                                                    onChange={(e) =>
                                                      handleMinutesChange(
                                                        e,
                                                        mainindex,
                                                        index1
                                                      )
                                                    }
                                                    InputProps={{
                                                      style: {
                                                        fontSize: "14px",
                                                        // width: "40px",
                                                      },
                                                      readOnly: true,
                                                    }}
                                                    sx={{
                                                      "& fieldset": {
                                                        border: "none",
                                                      },
                                                      // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                      //   {
                                                      //     padding: "0px !important",
                                                      //   },
                                                      "& input": {
                                                        textAlign: "center",
                                                        padding: "0px",
                                                      },
                                                      width: "100%",
                                                    }}
                                                  />
                                                  <div className="fs-6 fw-semibold ms-1">
                                                    :
                                                  </div>

                                                  <TextField
                                                    id="outlined-basic-seconds"
                                                    type="number"
                                                    size="small"
                                                    placeholder="Sec"
                                                    name="paceUp"
                                                    value={formatWithLeadingZeros(
                                                      item?.paceUp % 60
                                                    )}
                                                    onChange={(e) =>
                                                      handleSecondsChange(
                                                        e,
                                                        mainindex,
                                                        index1
                                                      )
                                                    }
                                                    InputProps={{
                                                      style: {
                                                        fontSize: "14px",
                                                        // width: "40px",
                                                      },
                                                      readOnly: true,
                                                    }}
                                                    sx={{
                                                      "& fieldset": {
                                                        border: "none",
                                                      },
                                                      // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                      //   {
                                                      //     padding: "0px !important",
                                                      //   },
                                                      "& input": {
                                                        textAlign: "center",
                                                        padding: "0px",
                                                      },
                                                      width: "100%",
                                                      marginRight: 1,
                                                    }}
                                                  />
                                                </InputAdornment>
                                              ),
                                            }}
                                            sx={{
                                              borderRadius: "5px",
                                              fontSize: "13px",
                                              "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input":
                                                {
                                                  width: "0px !important",
                                                  padding: "9px 0px !important",
                                                },
                                              "& .css-1nuss9t": {
                                                width: "0px !important",
                                                padding: "9px 0px !important",
                                              },
                                              // "& input": {
                                              //   textAlign: "center",
                                              //   padding: "0px",
                                              // },
                                              width: "140px",
                                            }}
                                          />
                                        ) : (
                                          <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            label="Pace"
                                            InputLabelProps={{
                                              shrink: true,
                                              style: { fontSize: "14px" },
                                            }}
                                            InputProps={{
                                              style: {
                                                fontSize: "13px",
                                                paddingLeft: "0px",
                                                paddingRight: "0px",

                                                justifyContent: "center",
                                              },
                                              readOnly: true,
                                              endAdornment: (
                                                <InputAdornment
                                                  position="end"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <TextField
                                                    id="outlined-basic-minutes"
                                                    type="number"
                                                    size="small"
                                                    placeholder="Min"
                                                    name="pace"
                                                    value={formatWithLeadingZeros(
                                                      Math.floor(
                                                        item?.pace / 60
                                                      )
                                                    )}
                                                    onChange={(e) =>
                                                      handleMinutesChange(
                                                        e,
                                                        mainindex,
                                                        index1
                                                      )
                                                    }
                                                    InputProps={{
                                                      style: {
                                                        fontSize: "14px",
                                                        // width: "40px",
                                                      },
                                                      readOnly: true,
                                                    }}
                                                    sx={{
                                                      "& fieldset": {
                                                        border: "none",
                                                      },
                                                      // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                      //   {
                                                      //     padding: "0px !important",
                                                      //   },
                                                      "& input": {
                                                        textAlign: "center",
                                                        padding: "0px",
                                                      },
                                                      width: "100%",
                                                    }}
                                                  />
                                                  <div className="fs-6 fw-semibold ms-1 ">
                                                    :
                                                  </div>

                                                  <TextField
                                                    id="outlined-basic-seconds"
                                                    type="number"
                                                    size="small"
                                                    placeholder="Sec"
                                                    name="pace"
                                                    value={formatWithLeadingZeros(
                                                      item?.pace % 60
                                                    )}
                                                    onChange={(e) =>
                                                      handleSecondsChange(
                                                        e,
                                                        mainindex,
                                                        index1
                                                      )
                                                    }
                                                    InputProps={{
                                                      style: {
                                                        fontSize: "14px",
                                                        // width: "40px",
                                                      },
                                                      readOnly: true,
                                                    }}
                                                    sx={{
                                                      "& fieldset": {
                                                        border: "none",
                                                      },
                                                      // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                      //   {
                                                      //     padding: "0px !important",
                                                      //   },
                                                      "& input": {
                                                        textAlign: "center",
                                                        padding: "0px",
                                                      },
                                                      width: "100%",
                                                    }}
                                                  />
                                                </InputAdornment>
                                              ),
                                            }}
                                            sx={{
                                              borderRadius: "5px",
                                              fontSize: "13px",
                                              "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input":
                                                {
                                                  width: "0px !important",
                                                  padding: "9px 0px !important",
                                                },
                                              "& .css-1nuss9t": {
                                                width: "0px !important",
                                                padding: "9px 0px !important",
                                              },
                                              // "& input": {
                                              //   textAlign: "center",
                                              //   padding: "0px",
                                              // },
                                              width: "100px",
                                            }}
                                          />
                                        )}
                                        <div
                                          style={{
                                            position: "absolute",
                                            bottom: "4px",
                                            left: "142px",
                                          }}
                                        >
                                          {item?.race_type == "el" ? (
                                            <Typography
                                              variant="subtitle2"
                                              sx={{
                                                opacity: 0.6,
                                                fontWeight: "600",
                                                fontSize: "12px",
                                              }}
                                            >{` @ Speed ${getKmhr(
                                              item?.pace,
                                              item?.pace_type
                                            )} km/hr or ${getMihr(
                                              item?.pace,
                                              item?.pace_type
                                            )} mi/hr -  ${getKmhr(
                                              item?.paceUp,
                                              item?.pace_type
                                            )} km/hr or ${getMihr(
                                              item?.paceUp,
                                              item?.pace_type
                                            )} mi/hr`}</Typography>
                                          ) : (
                                            <Typography
                                              variant="subtitle2"
                                              sx={{
                                                opacity: 0.6,
                                                fontWeight: "600",
                                                fontSize: "12px",
                                              }}
                                            >{` @ Speed ${getKmhr(
                                              item?.pace,
                                              item?.pace_type
                                            )} km/hr or ${getMihr(
                                              item?.pace,
                                              item?.pace_type
                                            )} mi/hr`}</Typography>
                                          )}
                                        </div>
                                      </>
                                    )}
                                    {data.parameters.includes(
                                      "Duration/Pace"
                                    ) && (
                                      <TextField
                                        id="outlined-basic"
                                        size="small"
                                        variant="outlined"
                                        onMouseLeave={() =>
                                          handleMouseEnter(index1, mainindex)
                                        }
                                        onClick={() => handleMouseLeave()}
                                        placeholder="0"
                                        type="number"
                                        value={item?.time}
                                        // inputRef={(ref) => {
                                        //   // Assign the ref to the focusedInputRef
                                        //   focusedInputRef.current = ref;
                                        // }}
                                        name="time"
                                        onChange={(e) => {
                                          handleChange(e, mainindex, index1);
                                        }}
                                        InputLabelProps={{
                                          shrink: true,
                                          style: { fontSize: "14px" },
                                        }}
                                        label="Duration/Pace"
                                        InputProps={{
                                          style: {
                                            fontSize: "13px",
                                            paddingRight: 0,
                                          },
                                          readOnly: true,
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <Select
                                                id="select-option"
                                                variant="outlined"
                                                size="small"
                                                name="timeType"
                                                value={item?.timeType}
                                                label="Weight Type"
                                                readOnly
                                                inputProps={{
                                                  style: {
                                                    padding: 0,
                                                  },
                                                }}
                                                onChange={(e) => {
                                                  handleChange(
                                                    e,
                                                    mainindex,
                                                    index1
                                                  );
                                                }}
                                                sx={{
                                                  "& legend": {
                                                    display: "none",
                                                  },
                                                  "& fieldset": {
                                                    top: 0,
                                                  },
                                                  fontSize: "13px",
                                                  border: "none",
                                                  "& fieldset": {
                                                    border: "none",
                                                  },
                                                  "& .MuiOutlinedInput-input": {
                                                    padding: 0,
                                                  },
                                                }}
                                              >
                                                <MenuItem value="sec">
                                                  sec
                                                </MenuItem>
                                                <MenuItem value="min">
                                                  min
                                                </MenuItem>
                                              </Select>
                                            </InputAdornment>
                                          ),
                                          // endAdornment: (
                                          //   <InputAdornment position="end">
                                          //     <div style={{ fontSize: "11px" }}>
                                          //       sec
                                          //     </div>
                                          //   </InputAdornment>
                                          // ),
                                        }}
                                        sx={{
                                          borderRadius: "5px",

                                          fontSize: "13px",
                                          // width: "20%",
                                          width: "100px",
                                        }}
                                      />
                                    )}
                                    {data.parameters.includes("Reps") && (
                                      <TextField
                                        id="outlined-basic"
                                        size="small"
                                        variant="outlined"
                                        placeholder="Reps"
                                        type="number"
                                        onMouseLeave={() =>
                                          handleMouseEnter(index1, mainindex)
                                        }
                                        onClick={() => handleMouseLeave()}
                                        label="Reps"
                                        value={item?.reps}
                                        name="reps"
                                        onChange={(e) => {
                                          handleChange(e, mainindex, index1);
                                        }}
                                        InputLabelProps={{
                                          shrink: true,
                                          style: { fontSize: "14px" },
                                        }}
                                        InputProps={{
                                          style: { fontSize: "13px" },
                                          readOnly: true,
                                        }}
                                        sx={{
                                          borderRadius: "5px",
                                          // "& legend": { display: "none" },
                                          // "& fieldset": { top: 0 },
                                          fontSize: "13px",
                                          // width: "13%",
                                          width: "60px",
                                        }}
                                      />
                                    )}
                                    {data.parameters.includes("Weight") && (
                                      <TextField
                                        sx={{
                                          borderRadius: "5px",
                                          // "& legend": { display: "none" },
                                          // "& fieldset": { top: 0 },
                                          paddingRight: 0,
                                          // width: "45%",
                                          width: "175px",
                                        }}
                                        // className={classes.customInput}
                                        size="small"
                                        id="custom-input"
                                        variant="outlined"
                                        onMouseLeave={() =>
                                          handleMouseEnter(index1, mainindex)
                                        }
                                        onClick={() => handleMouseLeave()}
                                        placeholder="Weight"
                                        name="weight"
                                        label="Weight"
                                        value={item.weight}
                                        onChange={(e) => {
                                          handleChange(e, mainindex, index1);
                                        }}
                                        type="number"
                                        InputLabelProps={{
                                          shrink: true,
                                          style: { fontSize: "14px" },
                                        }}
                                        InputProps={{
                                          style: {
                                            fontSize: "13px",
                                            paddingRight: 0,
                                          },
                                          readOnly: true,
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <Select
                                                id="select-option"
                                                variant="outlined"
                                                size="small"
                                                name="weightType"
                                                value={item?.weightType}
                                                label="Weight Type"
                                                readOnly
                                                onChange={(e) => {
                                                  handleChange(
                                                    e,
                                                    mainindex,
                                                    index1
                                                  );
                                                }}
                                                onMouseLeave={() =>
                                                  handleMouseEnter(
                                                    index1,
                                                    mainindex
                                                  )
                                                }
                                                onClick={() =>
                                                  handleMouseLeave()
                                                }
                                                sx={{
                                                  "& legend": {
                                                    display: "none",
                                                  },
                                                  "& fieldset": { top: 0 },
                                                  fontSize: "13px",
                                                  border: "none",
                                                  "& fieldset": {
                                                    border: "none",
                                                  },
                                                }}
                                              >
                                                <MenuItem value="1rm">
                                                  % 1RM
                                                </MenuItem>
                                                <MenuItem value="body_weight">
                                                  % Bodyweight
                                                </MenuItem>
                                                <MenuItem value="kg">
                                                  kilogram
                                                </MenuItem>
                                                <MenuItem value="lb">
                                                  Pound
                                                </MenuItem>
                                                <MenuItem value="poods">
                                                  Poods
                                                </MenuItem>
                                              </Select>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    )}
                                    {data.parameters.includes(
                                      "Distance/Calories"
                                    ) && (
                                      <TextField
                                        sx={{
                                          borderRadius: "5px",
                                          // "& legend": { display: "none" },
                                          // "& fieldset": { top: 0 },
                                          paddingRight: 0,
                                          // width: "45%",
                                          width: "175px",
                                        }}
                                        // className={classes.customInput}
                                        size="small"
                                        id="custom-input"
                                        variant="outlined"
                                        onMouseLeave={() =>
                                          handleMouseEnter(index1, mainindex)
                                        }
                                        onClick={() => handleMouseLeave()}
                                        placeholder={
                                          item?.distanceType == "calories"
                                            ? "Calories"
                                            : "Distance"
                                        }
                                        name="distance"
                                        label="Distance/Calories"
                                        value={item?.distance}
                                        onChange={(e) => {
                                          handleChange(e, mainindex, index1);
                                        }}
                                        type="number"
                                        InputLabelProps={{
                                          shrink: true,
                                          style: { fontSize: "14px" },
                                        }}
                                        InputProps={{
                                          style: {
                                            fontSize: "13px",
                                            paddingRight: 0,
                                          },
                                          readOnly: true,
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <Select
                                                id="select-option"
                                                variant="outlined"
                                                size="small"
                                                name="distanceType"
                                                value={item?.distanceType}
                                                label="Weight Type"
                                                readOnly
                                                onChange={(e) => {
                                                  handleChange(
                                                    e,
                                                    mainindex,
                                                    index1
                                                  );
                                                }}
                                                onMouseLeave={() =>
                                                  handleMouseEnter(
                                                    index1,
                                                    mainindex
                                                  )
                                                }
                                                onClick={() =>
                                                  handleMouseLeave()
                                                }
                                                sx={{
                                                  "& legend": {
                                                    display: "none",
                                                  },
                                                  "& fieldset": { top: 0 },
                                                  fontSize: "13px",
                                                  border: "none",
                                                  "& fieldset": {
                                                    border: "none",
                                                  },
                                                }}
                                              >
                                                <MenuItem value="m">
                                                  Meter
                                                </MenuItem>
                                                <MenuItem value="km">
                                                  Kilometer
                                                </MenuItem>
                                                <MenuItem value="mi">
                                                  Mile
                                                </MenuItem>
                                                <MenuItem value="calories">
                                                  Calories
                                                </MenuItem>
                                              </Select>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    )}

                                    {data.parameters.includes("Tempo") && (
                                      <TextField
                                        id="outlined-basic"
                                        size="small"
                                        variant="outlined"
                                        placeholder="Tempo"
                                        type="number"
                                        label="Tempo"
                                        value={item?.tempo}
                                        name="tempo"
                                        onChange={(e) => {
                                          handleChangeTempo(
                                            e,
                                            mainindex,
                                            index1
                                          );
                                        }}
                                        InputLabelProps={{
                                          shrink: true,
                                          style: { fontSize: "14px" },
                                        }}
                                        InputProps={{
                                          style: {
                                            fontSize: "13px",
                                          },
                                          readOnly: true,
                                        }}
                                        sx={{
                                          borderRadius: "5px",

                                          fontSize: "13px",
                                          width: "60px",
                                          // width: "13%",
                                          // "& input": {
                                          //   textAlign: "center",
                                          // },
                                          // "& .MuiOutlinedInput-input": {
                                          //   padding: "8px 0px",
                                          // },
                                        }}
                                      />
                                    )}
                                    {data.parameters.includes("Rest") && (
                                      <TextField
                                        id="outlined-basic"
                                        size="small"
                                        variant="outlined"
                                        placeholder="0"
                                        type="number"
                                        value={item?.rest}
                                        name="rest"
                                        onChange={(e) => {
                                          handleChange(e, mainindex, index1);
                                        }}
                                        onMouseLeave={() =>
                                          handleMouseEnter(index1, mainindex)
                                        }
                                        onClick={() => handleMouseLeave()}
                                        InputLabelProps={{
                                          shrink: true,
                                          style: { fontSize: "14px" },
                                        }}
                                        label="Rest"
                                        InputProps={{
                                          style: {
                                            fontSize: "13px",
                                            paddingRight: 0,
                                          },
                                          readOnly: true,
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <Select
                                                id="select-option"
                                                variant="outlined"
                                                size="small"
                                                name="restType"
                                                value={item?.restType}
                                                label="Weight Type"
                                                readOnly
                                                inputProps={{
                                                  style: {
                                                    padding: 0,
                                                  },
                                                }}
                                                onChange={(e) => {
                                                  handleChange(
                                                    e,
                                                    mainindex,
                                                    index1
                                                  );
                                                }}
                                                sx={{
                                                  "& legend": {
                                                    display: "none",
                                                  },
                                                  "& fieldset": {
                                                    top: 0,
                                                  },
                                                  fontSize: "13px",
                                                  border: "none",
                                                  "& fieldset": {
                                                    border: "none",
                                                  },
                                                  "& .MuiOutlinedInput-input": {
                                                    padding: 0,
                                                  },
                                                }}
                                              >
                                                <MenuItem value="sec">
                                                  sec
                                                </MenuItem>
                                                <MenuItem value="min">
                                                  min
                                                </MenuItem>
                                              </Select>
                                            </InputAdornment>
                                          ),
                                          // endAdornment: (
                                          //   <InputAdornment position="end">
                                          //     <div style={{ fontSize: "11px" }}>
                                          //       sec
                                          //     </div>
                                          //   </InputAdornment>
                                          // ),
                                        }}
                                        sx={{
                                          borderRadius: "5px",

                                          fontSize: "13px",
                                          // width: "20%",
                                          width: "100px",
                                        }}
                                      />
                                    )}
                                  </Stack>
                                </div>
                              </Tooltip>
                            ))}
                          {data?.open && (
                            <Tooltip placement="top" title={message}>
                              <TextField
                                id="outlined-basic"
                                size="small"
                                variant="outlined"
                                placeholder="Instructions"
                                label="Instructions"
                                type="number"
                                value={data.description}
                                name="description"
                                multiline
                                rows={2}
                                onChange={(e) => handleChangeDes(e, mainindex)}
                                InputLabelProps={{
                                  shrink: true,
                                  style: { fontSize: "14px" },
                                }}
                                InputProps={{
                                  readOnly: true,
                                  style: { fontSize: "14px" },
                                }}
                                sx={{
                                  borderRadius: "5px",
                                  // "& legend": { display: "none" },
                                  // "& fieldset": { top: 0 },

                                  mt: 2,
                                  width: "100%",
                                }}
                              />
                            </Tooltip>
                          )}
                        </Box>
                      </div>
                      <span
                        className="cross-icon"
                        style={{
                          position: "absolute",
                          bottom: "-12px",

                          width: "100%",
                          justifyContent: "center",
                          zIndex: 1,
                        }}
                      >
                        {addArray.length > 1 &&
                        addArray[mainindex + 1]?.type == "general" &&
                        mainindex < addArray.length - 1 ? (
                          <Tooltip placement="top" title={message}>
                            <div className="">
                              <SyncIcon
                                // onClick={() => handleAgreeExercise(mainindex)}
                                className="sync-icon-add"
                              />
                            </div>
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  )}
                </>
              );
            })}
          </form>
        </div>
      </div>
    </div>
  );
};
export default WorkoutExercisesUILogedClient;
