import {
  Avatar,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Box,
  Typography,
  Chip,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";
import { styled } from "@mui/material/styles";
import React, { useEffect, useMemo, useRef, useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import SyncIcon from "@mui/icons-material/Sync";

import EditIcon from "@mui/icons-material/Edit";
import { makeStyles } from "@mui/styles";

import CloseIcon from "@mui/icons-material/Close";

import { s3baseUrl } from "src/config/config";

// import CustomPopovers from "src/components/GeneralComponents/CustomPopovers";

import Label from "src/components/Label";
import { Icon } from "@iconify/react";
// import ExerciseHistoryModel from "./components/ExerciseHistoryModel";
// import { programme_workout_exercise_history_list_api } from "src/DAL/programmeWorkout/programmeWorkout";
import ToshowExercise from "./component/modalToshowExercise";
import ConfirmationWithDescription from "src/components/ModalPopover/ConfirmationWithDescription";
import ParameterModel from "../Workout/components/ParameterModel";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  loading2: {
    position: "absolute",
    top: "50%",
    right: "50%",
    zIndex: 1000,
  },
  customInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed

      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },

  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      backgroundColor: "#ebebeb",
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
}));

const EMOMExercisesUI = ({
  addArray,
  setaddArray,
  setModalChangeExe,
  isHistory,
  memberId,
  mainindex,
  message,
  isPersonal,
}) => {
  const { state } = useLocation();
  const myInputRef = useRef(null);
  const [exerInfo, setExsrInfo] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenForSuperset, setModalOpenForSuperset] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [historyModalOpenSuperset, setHistoryModalOpenSuperset] =
    useState(false);
  const [historyModalData, setHistoryModalData] = useState([
    {
      exercise: "",

      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          distance: "",
          distanceType: "m",
          reps: "",
          rest: "",
        },
      ],
      parameters: [
        "Duration/Pace",
        "Reps",
        "Weight",
        "Distance/Calories",
        "Rest",
        "Tempo",
      ],
      description: "",

      superSet: [],
      superset_sets: "",
    },
  ]);
  const [superAlterIndex, setSuperAlterIndex] = useState("");
  const [alterChanges, setAlterChanges] = useState("");
  const [toAlterChanges, setToAlterChanges] = useState("");
  const [openExerciseAlter, setOpenExerciseAlter] = useState(false);
  const [openSuperSetAlter, setOpenSuperSetAlter] = useState(false);
  const [alterIndex, setalterIndex] = useState();
  const [intervalTime, setIntervalTime] = useState(0);
  const [intervalSeconds, setIntervalSeconds] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedRound, setSelectedRound] = useState(1);
  const [isReaload, setIsReaload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [detailsData, setDetailsData] = useState("");
  const [exerIndex, setExerIndex] = useState("");
  const [outerIndex, setOuterIndex] = useState("");

  const [openDetails, setOpenDetails] = useState(false);

  const [isHovered, setIsHovered] = useState("");
  const [updatedIndexesArray, setUpdatedIndexesArray] = useState([]);
  const [isExerciseForSuperSet, setIsExerciseForSuperSet] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const handleMinutesChange = (e) => {
    const minutes = parseInt(e.target.value) || 0;
    const seconds = addArray[mainindex].time_interval % 60;
    updateIntervalTime(minutes, seconds);
  };

  const handleSecondsChange = (e) => {
    let seconds = parseInt(e.target.value) || 0;
    seconds = Math.min(Math.max(seconds, 0), 59); // Ensure the value is between 0 and 60
    const minutes = Math.floor(addArray[mainindex].time_interval / 60);
    updateIntervalTime(minutes, seconds);
  };

  // Function to update intervalTime state
  const updateIntervalTime = (minutes, seconds) => {
    const totalSeconds = minutes * 60 + seconds;
    setIntervalTime(totalSeconds);
    const list = [...addArray];

    list[mainindex]["time_interval"] = totalSeconds;

    setaddArray(list);
  };
  const formatWithLeadingZeros = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
  };
  const handleMouseEnter = (setIndex, exerciseIndex) => {
    setIsHovered(setIndex);
    setIsHoveredExercise(exerciseIndex);
  };
  const timeoutRef = useRef(null);

  useEffect(() => {
    // Cleanup function to clear the timeout on component unmount
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);
  const handleMouseLeave = () => {
    // Introduce a delay of 100 milliseconds before updating state on hover out
    const delay = 0;
    timeoutRef.current = setTimeout(() => {
      setIsHovered("");
    }, delay);
  };

  const handleAdd = (i) => {
    let list = addArray;

    list.splice(i + 1, 0, {
      exercise: "",
      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          distance: "",
          distanceType: "m",
          timeType: "sec",
          restType: "sec",
          reps: "",
          rest: "",
          tempo: "",
        },
      ],
      parameters: [
        "Duration/Pace",
        "Reps",
        "Weight",
        "Distance/Calories",
        "Rest",
        "Tempo",
      ],
      time_interval: 60,
      rounds: 10,
      type: "general",
      emomSets: [[]],
      description: "",
      superSet: [],
      superset_sets: "",
    });

    setaddArray([...list]);
    setModalChangeExe(true);
  };
  const handleChangeComplete = (e, index) => {
    const { checked } = e.target;
    // if (original[index]["is_completed"]) {
    //   return;
    // }
    const list = [...addArray];
    list[index]["is_completed"] = checked;

    setModalChangeExe(true);
    setaddArray(list);
  };
  const handleAddInterval = (e) => {
    e.preventDefault();
    let list = addArray;
    list[mainindex].emomSets.push([]);
    setaddArray([...list]);
    setModalChangeExe(true);
  };
  const handleAddMoment = (outerIndex, inerIndex) => {
    let list = addArray;
    const lastIndex = list[mainindex].emomSets[outerIndex].length;
    list[mainindex].emomSets[outerIndex].splice(lastIndex, 0, {
      exercise: "",
      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          distance: "",
          distanceType: "m",
          timeType: "sec",
          restType: "sec",
          reps: "",
          rest: "",
          tempo: "",
        },
      ],
      parameters: [
        "Duration/Pace",
        "Reps",
        "Weight",
        "Distance/Calories",
        "Rest",
        "Tempo",
      ],
      description: "",
      superSet: [],
      superset_sets: "",
    });

    setaddArray([...list]);
    setModalChangeExe(true);
  };
  const handleAddSet = (mainindex, index) => {
    let list = addArray;
    const data = { ...list[mainindex]["set"][index] };
    list[mainindex]["set"].splice(index + 1, 0, data);
    // ======= just add new set without values
    // list[mainindex]["set"].splice(index + 1, 0, {
    //   time: "",
    //   weight: "",
    //   weightType: "kg",
    //   distance: "",
    //   distanceType: "m",
    //   timeType: "sec",
    //   restType: "sec",
    //   reps: "",
    //   rest: "",
    // });

    setaddArray([...list]);
    setModalChangeExe(true);
  };

  const handleDeleteInterval = (mainindex, index) => {
    let list = addArray;

    list[mainindex]["emomSets"].splice(index, 1);

    setaddArray([...list]);
    setModalChangeExe(true);
  };
  const handleDeleteIntervalExercise = (index, exerIndex) => {
    let list = addArray;

    list[mainindex]["emomSets"][index].splice(exerIndex, 1);

    setaddArray([...list]);
    setModalChangeExe(true);
  };
  const handleDeleteExer = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);

    setaddArray(delValue);
    setModalChangeExe(true);
  };

  const removeSuperSet = (mainIndex, index) => {
    //  const { name, value } = e.target;
    const list = [...addArray];
    let firstPart = list[mainIndex]["superSet"].slice(0, index + 1);
    let secondPart = list[mainIndex]["superSet"].slice(index + 1);
    console.log(firstPart, secondPart, "dlfkjksdhfjas");
    let firstObject = { ...firstPart[0] };
    if (index > 0) {
      firstObject.superSet = firstPart;
      firstObject.superset_sets = list[mainIndex].superset_sets;
      console.log(firstObject, "fdjhsjkdfjkajds1", list[mainIndex]);
      // superset_sets;
    }

    let secondObject = { ...secondPart[0] };
    if (secondPart.length > 1) {
      secondObject.superSet = secondPart;
      secondObject.superset_sets = list[mainIndex].superset_sets;
      console.log(firstObject, "fdjhsjkdfjkajds2", list[mainIndex]);
    }

    let list1 = addArray;
    list1.splice(mainIndex + 1, 0, secondObject);
    list1.splice(mainIndex + 1, 0, firstObject);

    setaddArray([...list1]);
    setModalChangeExe(true);
    handleDeleteExer(mainIndex);
  };

  const handleAgreeSuperSet = (mainindex) => {
    setSuperAlterIndex(mainindex);

    setAlterChanges(
      addArray[mainindex + 1].superSet.length
        ? addArray[mainindex + 1].superset_sets
        : addArray[mainindex + 1].set.length
    );

    setToAlterChanges(
      addArray[mainindex].superSet.length
        ? addArray[mainindex].superset_sets
        : addArray[mainindex].set.length
    );

    const first = addArray[mainindex].superSet.length
      ? addArray[mainindex].superset_sets
      : addArray[mainindex].set.length;
    const second = addArray[mainindex + 1].superSet.length
      ? addArray[mainindex + 1].superset_sets
      : addArray[mainindex + 1].set.length;

    if (first == second) {
      addInSuperSet(mainindex);
      console.log(alterChanges, toAlterChanges, "dfjkhakjsdhfa");
    } else setOpenSuperSetAlter(true);
  };
  const handleAgreeExercise = (mainindex) => {
    setalterIndex(mainindex);

    setAlterChanges(
      addArray[mainindex + 1].superSet.length
        ? addArray[mainindex + 1].superset_sets
        : addArray[mainindex + 1].set.length
    );

    setToAlterChanges(
      addArray[mainindex].superSet.length
        ? addArray[mainindex].superSet.length
        : addArray[mainindex].set.length
    );

    const first = addArray[mainindex].superSet.length
      ? addArray[mainindex].superSet.length
      : addArray[mainindex].set.length;
    const second = addArray[mainindex + 1].superSet.length
      ? addArray[mainindex + 1].superset_sets
      : addArray[mainindex + 1].set.length;

    if (first == second) {
      addSuperSet(mainindex);
    } else {
      setOpenExerciseAlter(true);
    }
  };
  const addAgreeSuperSet = (index) => {
    //  const { name, value } = e.target;

    let current = { ...addArray[alterIndex] };
    current.superSet = [];
    let array = [current];

    if (
      addArray[alterIndex + 1]?.superSet &&
      addArray[alterIndex + 1].superSet.length > 0
    ) {
      addArray[alterIndex + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[alterIndex + 1]);
    }

    const list = [...addArray];
    list[alterIndex]["superSet"] = array;

    setaddArray(list);
    setModalChangeExe(true);

    let e = {
      target: {
        name: "superset_sets",
        value: addArray[alterIndex].set.length,
      },
    };
    handleChangeSuperSetCount(e, alterIndex);
    handleDelete(alterIndex + 1);
    setOpenExerciseAlter(false);
  };
  const addSuperSet = (index) => {
    //  const { name, value } = e.target;

    console.log(index, "dsjkhdkfgs", alterIndex);
    let current = { ...addArray[index] };
    current.superSet = [];
    let array = [current];

    if (
      addArray[index + 1]?.superSet &&
      addArray[index + 1].superSet.length > 0
    ) {
      addArray[index + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[index + 1]);
    }

    const list = [...addArray];
    list[index]["superSet"] = array;

    setaddArray(list);
    setModalChangeExe(true);

    let e = {
      target: {
        name: "superset_sets",
        value: addArray[index].set.length,
      },
    };
    handleChangeSuperSetCount(e, index);
    handleDelete(index + 1);
    setOpenExerciseAlter(false);
  };
  const addInSuperSet = (index) => {
    let array = addArray[index].superSet;
    if (addArray[index + 1].superSet.length > 0) {
      addArray[index + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[index + 1]);
    }
    const list = [...addArray];
    list[index]["superSet"] = array;

    setaddArray(list);
    setModalChangeExe(true);
    let e = {
      target: {
        name: "superset_sets",
        value: list[index].superset_sets,
      },
    };
    handleChangeSuperSetCount(e, index);
    handleDelete(index + 1);
    setOpenSuperSetAlter(false);
  };
  const addAgreeInSuperSet = () => {
    let array = addArray[superAlterIndex].superSet;
    if (addArray[superAlterIndex + 1].superSet.length > 0) {
      addArray[superAlterIndex + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[superAlterIndex + 1]);
    }
    const list = [...addArray];
    list[superAlterIndex]["superSet"] = array;

    setaddArray(list);
    setModalChangeExe(true);
    let e = {
      target: {
        name: "superset_sets",
        value: list[superAlterIndex].superset_sets,
      },
    };
    handleChangeSuperSetCount(e, superAlterIndex);
    handleDelete(superAlterIndex + 1);
    setOpenSuperSetAlter(false);
  };
  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);

    setaddArray(delValue);
    setModalChangeExe(true);
  };

  const handleArrow = (value, index) => {
    //  const { name, value } = e.target;

    const list = [...addArray];
    list[index]["open"] = value;

    setaddArray(list);
  };

  const handleArrowSuperSet = (value, index, exerIndex) => {
    //  const { name, value } = e.target;

    const list = [...addArray];
    list[mainindex]["emomSets"][index][exerIndex]["open"] = value;
    // list[mainindex]["superSet"][exerIndex]["open"] = value;

    setaddArray(list);
  };

  const handleChange = (e, index, index1) => {
    const { name, value } = e.target;

    const list = [...addArray];

    list[index]["set"][index1][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };
  const handleChangeTempo = (e, index, index1) => {
    const { name, value } = e.target;
    if (value.length <= 4) {
      const list = [...addArray];

      list[index]["set"][index1][name] = value;

      setaddArray(list);
      setModalChangeExe(true);
    }
  };
  const handleChangeSuperSetDes = (e, index, index1) => {
    const { name, value } = e.target;
    const list = [...addArray];
    list[index]["superSet"][index1][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeDes = (e, index) => {
    const { name, value } = e.target;

    const list = [...addArray];
    list[index][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeEmomSet = (e, index, exerIndex, setIndex) => {
    const { name, value } = e.target;

    const list = [...addArray];
    list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex][name] =
      value;

    setaddArray(list);
    console.log(addArray, "dkjfhkjsjkdkfjaj");
    setModalChangeExe(true);
  };
  const handleChangeEmomSetTempo = (e, index, exerIndex, setIndex) => {
    const { name, value } = e.target;
    if (value.length <= 4) {
      const list = [...addArray];
      list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex][name] =
        value;
      setaddArray(list);
      setModalChangeExe(true);
    }
  };
  const handleAddSetCount = (index) => {
    const list = [...addArray];
    const name = "rounds";
    const value = parseInt(list[index].rounds) + 1;
    // console.log(list[index].superset_sets, "ksdfjhkjsdnfkjs");
    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    // list[index]?.superSet.forEach((superSetItem) => {
    //   const currentSetLength = superSetItem.set.length;

    //   const data = { ...superSetItem.set[currentSetLength - 1] };
    //   if (currentSetLength < targetValue) {
    //     const objectsToAdd = targetValue - currentSetLength;

    //     for (let i = 0; i < objectsToAdd; i++) {
    //       superSetItem.set.push(data);
    //     }
    //   } else if (currentSetLength > targetValue) {
    //     // If the new count is less than the current count, remove excess objects
    //     superSetItem.set.splice(targetValue);
    //   }
    // });

    setaddArray(list);
    setModalChangeExe(true);
  };
  const handleRearageSetCount = (index, count) => {
    const list = [...addArray];
    const name = "superset_sets";
    const value = count;
    // console.log(list[index].superset_sets, "ksdfjhkjsdnfkjs");
    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    list[index]?.superSet.forEach((superSetItem) => {
      const currentSetLength = superSetItem.set.length;

      if (currentSetLength < targetValue) {
        const objectsToAdd = targetValue - currentSetLength;

        for (let i = 0; i < objectsToAdd; i++) {
          superSetItem.set.push({
            time: "",
            weight: "",
            weightType: "kg",
            distance: "",
            distanceType: "m",
            timeType: "sec",
            restType: "sec",
            reps: "",
            rest: "",
          });
        }
      } else if (currentSetLength > targetValue) {
        // If the new count is less than the current count, remove excess objects
        superSetItem.set.splice(targetValue);
      }
    });

    setaddArray(list);
    setModalChangeExe(true);
    setHistoryModalOpenSuperset(false);
  };
  const handleMinusSetCount = (index) => {
    // console.log(addArray[mainindex], "fjksdkjfaj");
    const list = [...addArray];
    const name = "rounds";
    const value = parseInt(list[index].rounds) - 1;
    // console.log(list[index].superset_sets, "ksdfjhkjsdnfkjs");
    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    // list[index]?.superSet.forEach((superSetItem) => {
    //   const currentSetLength = superSetItem.set.length;

    //   if (currentSetLength < targetValue) {
    //     const objectsToAdd = targetValue - currentSetLength;

    //     for (let i = 0; i < objectsToAdd; i++) {
    //       superSetItem.set.push({
    //         time: "",
    //         weight: "",
    //         weightType: "kg",
    //         distance: "",
    //         distanceType: "m",
    //         timeType: "sec",
    //         restType: "sec",
    //         reps: "",
    //         rest: "",
    //       });
    //     }
    //   } else if (currentSetLength > targetValue) {
    //     // If the new count is less than the current count, remove excess objects
    //     superSetItem.set.splice(targetValue);
    //   }
    // });

    setaddArray(list);
    setModalChangeExe(true);
  };
  const getRounds = (count) => {
    const rounds = [];

    for (let i = 1; i <= count; i++) {
      rounds.push(
        <div
          onClick={() => {
            setSelectedRound(i);
          }}
          className={
            selectedRound == i
              ? "emom-round-count-box me-2"
              : "emom-round-count-box-disable me-2"
          }
        >
          <div
            className={
              selectedRound == i
                ? "emom-round-count"
                : "emom-round-count-disable"
            }
          >
            {i}
          </div>
        </div>
      );
    }

    return rounds;
  };

  const getMinutesForEmom = () => {
    // console.log(addArray[mainindex], "fjksdkjfaj");
    let min = 10;
    let sec = 0;
    const list = [...addArray];
    const rounds = list[mainindex].rounds;
    const moments = list[mainindex].emomSets.length;
    const interval = list[mainindex].time_interval;
    min = formatWithLeadingZeros(
      Math.floor((rounds * moments * interval) / 60)
    );
    sec = formatWithLeadingZeros((rounds * moments * interval) % 60);
    // console.log(
    //   rounds * moments * interval,
    //   "jdkjfasd",
    //   (rounds * moments * interval) % 60,
    //   (rounds * moments * interval) / 60
    // );

    return `${
      Math.floor(interval / 60) +
      " " +
      ":" +
      formatWithLeadingZeros(interval % 60) +
      " " +
      "minute" +
      " " +
      "for" +
      " " +
      min +
      ":" +
      sec
    }`;
  };
  const getMinutesForInterval = (index) => {
    console.log(index, "fjksdkjfaj");
    let min = 10;
    let sec = 0;
    const list = [...addArray];
    const rounds = list[mainindex].rounds;
    const moments = list[mainindex].emomSets.length;
    const interval = list[mainindex].time_interval;

    if (interval <= 60) {
      return index + 1;
    }
    if (interval % 60 == 0) {
      return `${
        (interval / 60) * index +
        1 +
        " – " +
        ((interval / 60) * index + interval / 60)
      }`;
    }
    let startInterval = interval * index;
    let endtInterval = interval * index + interval;
    let startintervalText =
      interval * index
        ? Math.floor(startInterval / 60) +
          " " +
          ":" +
          formatWithLeadingZeros(startInterval % 60)
        : 0;
    let endIntervalText =
      Math.floor(endtInterval / 60) +
      " " +
      ":" +
      formatWithLeadingZeros(endtInterval % 60);

    return `${startintervalText + " – " + endIntervalText}`;
  };
  // const getMinutesForInterval = (index, selectedIndex) => {
  //   const list = [...addArray];
  //   const interval = list[mainindex].time_interval;
  //   console.log(selectedIndex, "dsjfjkhkajs", index);
  //   if (interval <= 60) {
  //     return index + selectedIndex;
  //   }

  //   if (interval % 60 === 0) {
  //     const start = (interval / 60) * index + 1 + selectedIndex;
  //     const end = (interval / 60) * index + interval / 60 + selectedIndex;
  //     return `${start} – ${end}`;
  //   }

  //   const startInterval = interval * index + selectedIndex;
  //   const endInterval = interval * (index + 1) + selectedIndex;
  //   const startIntervalText =
  //     Math.floor(startInterval / 60) +
  //     " " +
  //     ":" +
  //     formatWithLeadingZeros(startInterval % 60);

  //   const endIntervalText =
  //     Math.floor(endInterval / 60) +
  //     " " +
  //     ":" +
  //     formatWithLeadingZeros(endInterval % 60);

  //   return `${startIntervalText} – ${endIntervalText}`;
  // };
  useMemo(() => {
    const arraylength = addArray[mainindex].emomSets.length;
    const lastIndex = parseInt(selectedRound) * arraylength;
    const startingIndex = lastIndex - (arraylength - 1);
    let finalArray = [];
    for (let index = startingIndex; index <= lastIndex; index++) {
      finalArray.push(index - 1);
    }
    setUpdatedIndexesArray(finalArray);
    console.log(startingIndex, "kjdshjkfsa", lastIndex, finalArray);
  }, [selectedRound]);
  // const getIndexForInterval = () => {};
  const handleChangeSuperSetCount = (e, index) => {
    const { name, value } = e.target;
    const list = [...addArray];

    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    list[index]?.superSet.forEach((superSetItem) => {
      const currentSetLength = superSetItem.set.length;

      if (currentSetLength < targetValue) {
        const objectsToAdd = targetValue - currentSetLength;

        for (let i = 0; i < objectsToAdd; i++) {
          superSetItem.set.push({
            time: "",
            weight: "",
            weightType: "kg",
            distance: "",
            distanceType: "m",
            reps: "",
            rest: "",
          });
        }
      } else if (currentSetLength > targetValue) {
        // If the new count is less than the current count, remove excess objects
        superSetItem.set.splice(targetValue);
      }
    });

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeUp = (value) => {
    if (value == 0) {
      enqueueSnackbar("You are already on Top !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value - 1, 0, reorderedItem);

    setaddArray(items);
    setModalChangeExe(true);
  };

  const handleExerciseList = (value) => {
    setIsExerciseForSuperSet(false);
    setDetailsData(value);
    setOpenDetails(true);
  };
  const handleSupersetExerciseList = (mainIndex, exerInde) => {
    setDetailsData(mainIndex);
    setExerIndex(exerInde);
    setIsExerciseForSuperSet(true);
  };
  const handleSelectExercise = (value) => {
    const list = [...addArray];
    if (list[detailsData]["exercise"]._id == value._id) {
      return;
    }
    list[detailsData]["exercise"] = value;
    list[detailsData]["description"] = value?.instructions;

    setaddArray(list);
    setModalChangeExe(false);
    if (isHistory) handleHistory(value, detailsData);
  };
  const handleCopyExercise = (value) => {
    const list = [...addArray];
    list[detailsData]["set"] = value.set;
    list[detailsData]["parameters"] = value?.parameters;
    setaddArray(list);
    setModalChangeExe(true);
    setHistoryModalOpen(false);
  };
  const handleCopySupersetExercise = (value) => {
    const list = [...addArray];
    console.log(list[detailsData], "dskjfjkdshafkja");
    list[detailsData]["superSet"][exerIndex]["set"] = value.set;
    list[detailsData]["superSet"][exerIndex]["parameters"] = value?.parameters;
    setaddArray(list);
    setModalChangeExe(true);

    handleRearageSetCount(detailsData, list[detailsData].superset_sets);
  };
  const handleSelectSupersetExercise = (value) => {
    const list = [...addArray];

    // return;

    list[mainindex]["emomSets"][detailsData][exerIndex]["exercise"] = value;
    list[mainindex]["emomSets"][detailsData][exerIndex]["description"] =
      value?.instructions;
    setaddArray(list);
    setModalChangeExe(true);

    if (isHistory) handleHistorySuperset(value, detailsData, exerIndex);
  };

  const handleChangeDown = (value) => {
    if (value >= addArray.length - 1) {
      enqueueSnackbar("You are already on Bottom !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value + 1, 0, reorderedItem);

    setaddArray(items);
    setModalChangeExe(true);
  };

  const handleParameters = (value) => {
    setModalOpen(true);
    setModalOpenForSuperset(false);
    setOuterIndex(value);
    setExsrInfo(addArray[value].parameters);
  };
  const handleHistoryOpen = async (value, index) => {
    setDetailsData(index);

    setHistoryModalData(value.history);

    setHistoryModalOpen(true);
  };
  const handleHistory = async (value, index) => {
    setDetailsData(index);
    let postData = {
      exercise: value?._id,
      member: memberId,
    };

    try {
      let result = await programme_workout_exercise_history_list_api(postData);
      if (result.code == 200) {
        if (!result.exercise_history.length > 0) {
          const list = [...addArray];
          delete list[index]["history"];
          setaddArray(list);

          return;
        }
        let givenExercise = result.exercise_history.map((item) => {
          return {
            date: item?.date,
            exercise: item?.exercise_info,
            set: item?.sets.map((item1) => {
              return {
                time: item1?.duration,
                weight: item1?.weight,
                weightType: item1?.weight_unit,
                reps: item1?.reps,
                rest: item1?.rest,
                distance: item1?.distance,
                distanceType: item1?.distance_unit,
                timeType: item1?.duration_unit,
                restType: item1?.rest_unit,
                tempo: item1?.tempo,
              };
            }),
            parameters: item?.parameters,
            open: true,
          };
        });

        setHistoryModalData(givenExercise);

        // setHistoryModalOpen(true);
        const list = [...addArray];
        list[index]["history"] = givenExercise;
        setaddArray(list);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleHistorySupersetOpen = async (value, mainIndex, exerInde) => {
    setDetailsData(mainIndex);
    setExerIndex(exerInde);
    setHistoryModalData(value.history);
    setHistoryModalOpenSuperset(true);
  };
  const handleHistorySuperset = async (value, mainIndex, exerInde) => {
    setDetailsData(mainIndex);
    setExerIndex(exerInde);
    let postData = {
      exercise: value?._id,
      member: memberId,
    };

    try {
      let result = await programme_workout_exercise_history_list_api(postData);
      if (result.code == 200) {
        if (!result.exercise_history.length > 0) {
          const list = [...addArray];
          delete list[mainindex]["emomSets"][detailsData][exerIndex]["history"];
          // delete list[mainIndex]["superSet"][exerInde]["history"];
          setaddArray(list);

          return;
        }
        let givenExercise = result.exercise_history.map((item) => {
          return {
            date: item?.date,
            exercise: item?.exercise_info,
            set: item?.sets.map((item1) => {
              return {
                time: item1?.duration,
                weight: item1?.weight,
                weightType: item1?.weight_unit,
                reps: item1?.reps,
                rest: item1?.rest,
                distance: item1?.distance,
                distanceType: item1?.distance_unit,
                timeType: item1?.duration_unit,
                restType: item1?.rest_unit,
                tempo: item1?.tempo,
              };
            }),
            parameters: item?.parameters,
            open: true,
          };
        });
        console.log(givenExercise, "kjdshakfjsdfhaj");
        setHistoryModalData(givenExercise);
        // setIsLoading(false);
        // setHistoryModalOpenSuperset(true);
        const list = [...addArray];
        list[mainindex]["emomSets"][detailsData][exerIndex]["history"] =
          givenExercise;
        setaddArray(list);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleParametersSuperset = (main, inr) => {
    setModalOpen(true);
    setModalOpenForSuperset(true);
    setOuterIndex(main);
    setExerIndex(inr);
    setExsrInfo(addArray[mainindex].emomSets[main][inr].parameters);
  };
  const handleChangeParametersInsetFieldValueSuperSet = (
    value,
    outerIndex,
    exerIndex
  ) => {
    const items = Array.from(addArray);

    const sets = items[mainindex].emomSets[outerIndex][exerIndex].set.map(
      (item) => {
        return {
          time: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Duration/Pace")
            ? item?.time
            : "",
          weight: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Weight")
            ? item?.weight
            : "",
          weightType: item?.weightType,
          reps: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Reps")
            ? item?.reps
            : "",
          rest: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Rest")
            ? item?.rest
            : "",
          distance: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Distance/Calories")
            ? item?.distance
            : "",
          distanceType: item?.distanceType,
          timeType: item?.timeType,
          restType: item?.restType,
          tempo: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Tempo")
            ? item?.tempo
            : "",
        };
      }
    );
    // setExsrInfo(items[outerIndex].superSet[exerIndex].parameters);
    items[mainindex].emomSets[outerIndex][exerIndex].set = sets;
    setaddArray(items);
  };
  const handleChangeParametersInsetFieldValue = (value, outerIndex) => {
    const items = Array.from(addArray);
    const sets = items[outerIndex].set.map((item) => {
      return {
        time: items[outerIndex].parameters.includes("Duration/Pace")
          ? item?.time
          : "",
        weight: items[outerIndex].parameters.includes("Weight")
          ? item?.weight
          : "",
        weightType: item?.weightType,
        reps: items[outerIndex].parameters.includes("Reps") ? item?.reps : "",
        rest: items[outerIndex].parameters.includes("Rest") ? item?.rest : "",
        distance: items[outerIndex].parameters.includes("Distance/Calories")
          ? item?.distance
          : "",
        distanceType: item?.distanceType,
        timeType: item?.timeType,
        restType: item?.restType,
        tempo: items[outerIndex].parameters.includes("Tempo")
          ? item?.tempo
          : "",
      };
    });
    console.log(items[outerIndex], "sdkfjklsdjfjlkakj");
    // setExsrInfo(items[outerIndex].parameters);
    items[outerIndex].set = sets;
    setaddArray(items);
  };
  const handleChangeParametersInset = (value) => {
    const items = Array.from(addArray);
    if (modalOpenForSuperset) {
      items[mainindex].emomSets[outerIndex][exerIndex].parameters = value;
      setExsrInfo(items[mainindex].emomSets[outerIndex][exerIndex].parameters);
      setaddArray(items);
      setModalChangeExe(true);
      handleChangeParametersInsetFieldValueSuperSet(
        value,
        outerIndex,
        exerIndex
      );
      return;
    }
    items[outerIndex].parameters = value;
    setExsrInfo(items[outerIndex].parameters);
    setaddArray(items);
    setModalChangeExe(true);
    handleChangeParametersInsetFieldValue(value, outerIndex);
  };

  const memueOptions = (value) => {
    const MENU_OPTIONS = [];
    MENU_OPTIONS.unshift(
      {
        label: "Move Up",
        icon: "mdi:arrow-up",
        handleClick: handleChangeUp,
        disabled: value == 0 ? true : false,
      },
      {
        label: "Move Down",
        icon: "ic:outline-arrow-downward",
        handleClick: handleChangeDown,
        disabled: value >= addArray.length - 1 ? true : false,
      }
      // {
      //   label: "Parameters",
      //   icon: "pajamas:labels",
      //   handleClick: handleParameters,
      // }
    );

    return MENU_OPTIONS;
  };

  if (isReaload === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="my-2">
      {isLoading && (
        <CircularProgress className={classes.loading2} color="primary" />
      )}
      <ConfirmationWithDescription
        open={openSuperSetAlter}
        setOpen={setOpenSuperSetAlter}
        title={"Are you sure you want to take this action?"}
        descriotion={`You are trying to alternate an exercise with  ${toAlterChanges} sets with an exercise with ${alterChanges} sets. If you proceed, we’ll modify the exercise to have  ${toAlterChanges} sets.`}
        handleAgree={addAgreeInSuperSet}
      />
      <ConfirmationWithDescription
        open={openExerciseAlter}
        setOpen={setOpenExerciseAlter}
        title={"Are you sure you want to take this action?"}
        descriotion={`You are trying to alternate an exercise with  ${toAlterChanges} sets with an exercise with ${alterChanges} sets. If you proceed, we’ll modify the exercise to have  ${toAlterChanges} sets.`}
        handleAgree={addAgreeSuperSet}
      />
      <ToshowExercise
        open={openDetails}
        setOpen={setOpenDetails}
        handleChange={handleSelectExercise}
      />
      <ToshowExercise
        open={isExerciseForSuperSet}
        setOpen={setIsExerciseForSuperSet}
        handleChange={handleSelectSupersetExercise}
      />
      <ParameterModel
        open={modalOpen}
        setOpen={setModalOpen}
        data={exerInfo}
        handleChange={handleChangeParametersInset}
      />
      {/* <ExerciseHistoryModel
        open={historyModalOpen}
        setOpen={setHistoryModalOpen}
        data={historyModalData}
        handleChange={setHistoryModalData}
        handleCopyExercise={handleCopyExercise}
      />
      <ExerciseHistoryModel
        open={historyModalOpenSuperset}
        setOpen={setHistoryModalOpenSuperset}
        data={historyModalData}
        handleChange={setHistoryModalData}
        handleCopyExercise={handleCopySupersetExercise}
      /> */}
      <div className="mt-2 ">
        <div className="characters">
          {/* <form onSubmit={handleSubmit}> */}

          <form onSubmit={() => {}}>
            <div
              style={{ position: "relative" }}
              className="workout-Superset-card my-1"
            >
              {/* <SuperSets length={data?.superset_sets} data={data} /> */}
              <Box>
                <div className=" mb-3">
                  {/* <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Interval Duration"
                    label="Interval Duration"
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: "14px" },
                    }}
                    InputProps={{
                      style: {
                        fontSize: "13px",
                        paddingLeft: "0px",
                        paddingRight: "0px",

                        justifyContent: "center",
                      },
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <TextField
                            id="outlined-basic-minutes"
                            type="number"
                            size="small"
                            placeholder="Min"
                            value={formatWithLeadingZeros(
                              Math.floor(addArray[mainindex].time_interval / 60)
                            )}
                            onChange={handleMinutesChange}
                            InputProps={{
                              style: {
                                fontSize: "19px",
                                fontWeight: "500",
                                // width: "40px",
                              },
                            }}
                            sx={{
                              "& fieldset": {
                                border: "none",
                              },
                              "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                {
                                  padding: "0px",
                                },
                              "& input": {
                                textAlign: "center",
                              },
                              width: "100%",
                            }}
                          />
                          <div className="fs-4 fw-semibold ">:</div>

                          <TextField
                            id="outlined-basic-seconds"
                            type="number"
                            size="small"
                            placeholder="Sec"
                            value={formatWithLeadingZeros(
                              addArray[mainindex].time_interval % 60
                            )}
                            onChange={handleSecondsChange}
                            InputProps={{
                              style: {
                                fontSize: "19px",
                                fontWeight: "500",
                                // width: "40px",
                              },
                            }}
                            sx={{
                              "& fieldset": {
                                border: "none",
                              },
                              "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                {
                                  padding: "0px",
                                },
                              "& input": {
                                textAlign: "center",
                              },
                              width: "100%",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      borderRadius: "5px",
                      fontSize: "13px",
                      "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          width: "0px",
                          padding: "9px 0px",
                        },

                      width: "110px",
                    }}
                  /> */}
                  <div className="d-flex align-items-start justify-content-between mb-3">
                    <Tooltip placement="top" title={message}>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        label="Interval Duration"
                        InputLabelProps={{
                          shrink: true,
                          style: { fontSize: "14px" },
                        }}
                        InputProps={{
                          style: {
                            fontSize: "13px",
                            paddingLeft: "0px",
                            paddingRight: "0px",

                            justifyContent: "center",
                          },
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <TextField
                                id="outlined-basic-minutes"
                                type="number"
                                size="small"
                                placeholder="Min"
                                value={formatWithLeadingZeros(
                                  Math.floor(
                                    addArray[mainindex].time_interval / 60
                                  )
                                )}
                                onChange={handleMinutesChange}
                                InputProps={{
                                  style: {
                                    fontSize: "19px",
                                    fontWeight: "500",
                                    // width: "40px",
                                  },
                                  readOnly: true,
                                }}
                                sx={{
                                  "& fieldset": {
                                    border: "none",
                                  },
                                  // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                  //   {
                                  //     padding: "0px !important",
                                  //   },
                                  "& input": {
                                    textAlign: "center",
                                    padding: "0px",
                                  },
                                  width: "100%",
                                }}
                              />
                              <div className="fs-4 fw-semibold ">:</div>

                              <TextField
                                id="outlined-basic-seconds"
                                type="number"
                                size="small"
                                placeholder="Sec"
                                value={formatWithLeadingZeros(
                                  addArray[mainindex].time_interval % 60
                                )}
                                onChange={handleSecondsChange}
                                InputProps={{
                                  style: {
                                    fontSize: "19px",
                                    fontWeight: "500",
                                    // width: "40px",
                                  },
                                  readOnly: true,
                                }}
                                sx={{
                                  "& fieldset": {
                                    border: "none",
                                  },
                                  // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                  //   {
                                  //     padding: "0px !important",
                                  //   },
                                  "& input": {
                                    textAlign: "center",
                                    padding: "0px",
                                  },
                                  width: "100%",
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          borderRadius: "5px",
                          fontSize: "13px",
                          "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input":
                            {
                              width: "0px !important",
                              padding: "9px 0px !important",
                            },
                          "& .css-1nuss9t": {
                            width: "0px !important",
                            padding: "9px 0px !important",
                          },
                          // "& input": {
                          //   textAlign: "center",
                          //   padding: "0px",
                          // },
                          width: "110px",
                        }}
                      />
                    </Tooltip>
                    <div className="d-flex align-items-center justify-content-center  my-2">
                      <div>Every {getMinutesForEmom()} minutes </div>
                      <div className="ms-2 cross-icon ">
                        <RemoveCircleIcon
                          // onClick={() => handleMinusSetCount(mainindex)}
                          className="diary-icon-plus-minus"
                        />
                      </div>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        variant="outlined"
                        placeholder="Rounds"
                        type="number"
                        value={addArray[mainindex].rounds}
                        name="superset_sets"
                        disabled
                        // onChange={(e) => handleChangeSuperSetCount(e, mainindex)}
                        InputLabelProps={{
                          shrink: true,
                          style: { fontSize: "14px" },
                        }}
                        //   label="Set"
                        InputProps={{
                          style: { fontSize: "13px", padding: "0" },
                        }}
                        sx={{
                          mx: 1,
                          borderRadius: "5px",
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                          fontSize: "13px",
                          "& input": {
                            padding: "3px 5px",
                            textAlign: "center",
                          },
                          width: "33px",
                        }}
                      />

                      <div className=" cross-icon me-2">
                        <AddCircleIcon
                          // onClick={() => handleAddSetCount(mainindex)}
                          className="diary-icon-plus-minus"
                        />
                      </div>
                      <div>rounds</div>
                    </div>
                    <div className=" cross-icon ">
                      {addArray.length > 1 ? (
                        <>
                          <Tooltip placement="top" title={message}>
                            <RemoveCircleOutlineIcon
                              // onClick={() => handleDelete(mainindex)}
                              className="diary-icon-remove"
                            />
                          </Tooltip>
                        </>
                      ) : (
                        ""
                      )}
                      <Tooltip placement="top" title={message}>
                        <AddCircleOutlineIcon
                          // onClick={() => handleAdd(mainindex)}
                          className="diary-icon-add"
                        />
                      </Tooltip>
                      <Tooltip placement="top" title={message}>
                        <span>
                          <CustomPopoverSection menu={[]} data={mainindex} />
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                  {isPersonal && (
                    <div className="d-flex align-items-center justify-content-center  my-2">
                      <div className="emom-round-title">Rounds: </div>
                      {getRounds(addArray[mainindex].rounds)}
                    </div>
                  )}
                </div>

                {addArray[mainindex].emomSets.length > 0 &&
                  addArray[mainindex].emomSets.map((item, index) => {
                    return (
                      <div className=" my-2 ">
                        <div className="d-flex justify-content-between align-items-center ">
                          <div>
                            Minute{" "}
                            {getMinutesForInterval(updatedIndexesArray[index])}
                          </div>
                          {/* <Tooltip
                            title={`${
                              addArray[mainindex].emomSets.length > 1
                                ? "Delete"
                                : "Can't be Deleted"
                            }`}
                          >
                            <DeleteIcon
                              className={`${
                                addArray[mainindex].emomSets.length > 1
                                  ? "del-icon-calendar-workout-working"
                                  : "del-icon-calendar-workout-notwork"
                              }`}
                              onClick={
                                addArray[mainindex].emomSets.length > 1
                                  ? () => handleDeleteInterval(mainindex, index)
                                  : () => {}
                              }
                            />
                          </Tooltip> */}
                        </div>

                        {item.length > 0 &&
                          item.map((exerData, exerIndex) => {
                            return (
                              <div className="ms-4 mt-1">
                                <div
                                  className="d-flex workout-set-card  "
                                  style={{
                                    width: "100%",
                                    position: "relative",
                                    overflow: "hidden",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      position: "relative",
                                      width: "100%",
                                    }}
                                  >
                                    <div className="d-flex justify-content-between align-items-center ">
                                      <div className="d-flex align-items-center ">
                                        <Tooltip
                                          placement="top"
                                          title={message}
                                        >
                                          <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            placeholder="Exercise"
                                            value={exerData?.exercise?.title}
                                            name="Exercise"
                                            // onClick={() =>
                                            //   handleSupersetExerciseList(
                                            //     index,
                                            //     exerIndex
                                            //   )
                                            // }
                                            // onChange={(e) => {}}
                                            InputLabelProps={{
                                              shrink: true,
                                              style: { fontSize: "14px" },
                                            }}
                                            label="Exercise"
                                            InputProps={{
                                              style: {
                                                fontSize: "13px",
                                                paddingLeft: "6px",
                                              },
                                              startAdornment: (
                                                <InputAdornment position="start">
                                                  {exerData?.exercise?.image
                                                    ?.thumbnail_3 && (
                                                    <Avatar
                                                      sx={{
                                                        borderRadius: "5px",
                                                      }}
                                                      alt="User Image"
                                                      src={
                                                        s3baseUrl +
                                                        exerData?.exercise
                                                          ?.image?.thumbnail_3
                                                      }
                                                    />
                                                  )}
                                                </InputAdornment>
                                              ),
                                              // endAdornment: (
                                              //   <InputAdornment position="end">
                                              //     <div
                                              //       style={{ fontSize: "16px" }}
                                              //     >
                                              //       {exerData?.exercise ? (
                                              //         <EditIcon
                                              //           fontSize="12px"
                                              //           sx={{ opacity: 0.5 }}
                                              //         />
                                              //       ) : (
                                              //         <AddIcon
                                              //           sx={{ opacity: 0.5 }}
                                              //         />
                                              //       )}
                                              //     </div>
                                              //   </InputAdornment>
                                              // ),
                                              inputProps: {
                                                readOnly: true, // Disable editing
                                              },
                                            }}
                                            sx={{
                                              borderRadius: "5px",

                                              fontSize: "13px",
                                              width: "450px",
                                            }}
                                          />
                                        </Tooltip>

                                        <div className="d-flex align-items-center mx-1">
                                          <div className="text-center">
                                            {!exerData?.open ? (
                                              <KeyboardArrowDownIcon
                                                sx={{
                                                  cursor: "pointer",
                                                  opacity: 0.5,
                                                  fontSize: "20px",
                                                }}
                                                onClick={() => {
                                                  handleArrowSuperSet(
                                                    true,
                                                    index,
                                                    exerIndex
                                                  );
                                                }}
                                              />
                                            ) : (
                                              <KeyboardArrowUpIcon
                                                sx={{
                                                  cursor: "pointer",
                                                  opacity: 0.5,
                                                  fontSize: "20px",
                                                }}
                                                onClick={() => {
                                                  handleArrowSuperSet(
                                                    false,
                                                    index,
                                                    exerIndex
                                                  );
                                                }}
                                              />
                                            )}
                                          </div>
                                          <Typography
                                            variant="body2"
                                            sx={{ opacity: 0.6 }}
                                          >
                                            {` Sets 1`}
                                          </Typography>
                                          <Tooltip
                                            placement="top"
                                            title={message}
                                          >
                                            <div className="d-flex align-items-center ms-3 set-parameters ">
                                              <Icon
                                                icon="pajamas:labels"
                                                fontSize="10px"
                                              />
                                              <Typography
                                                variant="body2"
                                                sx={{ ml: 0.5 }}
                                              >
                                                {`Parameters`}
                                              </Typography>
                                            </div>
                                          </Tooltip>
                                          {isHistory &&
                                            exerData?.exercise &&
                                            exerData?.history && (
                                              <div
                                                className="d-flex align-items-center ms-3 set-parameters "
                                                onClick={() => {
                                                  handleHistorySupersetOpen(
                                                    exerData,
                                                    mainindex,
                                                    exerIndex
                                                  );
                                                }}
                                              >
                                                <Icon
                                                  icon="fa-solid:history"
                                                  fontSize="10px"
                                                />
                                                <Typography
                                                  variant="body2"
                                                  sx={{ ml: 0.5 }}
                                                >
                                                  {`History`}
                                                </Typography>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                      {/* <Tooltip
                                        title={`${
                                          addArray[mainindex].emomSets.length >
                                          1
                                            ? "Delete"
                                            : "Can't be Deleted"
                                        }`}
                                      >
                                        <DeleteIcon
                                          className={`${"del-icon-calendar-workout-working"}`}
                                          onClick={() =>
                                            handleDeleteIntervalExercise(
                                              index,
                                              exerIndex
                                            )
                                          }
                                        />
                                      </Tooltip> */}
                                    </div>

                                    {
                                      exerData?.open && (
                                        // exerData?.set?.map((item, setIndex) => (
                                        <div className="d-flex align-items-center my-3 workout-card-inset">
                                          <Tooltip
                                            placement="top"
                                            title={message}
                                          >
                                            <Stack
                                              direction="row"
                                              spacing={1}
                                              alignItems="center"
                                              justifyContent="start"
                                              sx={{
                                                padding: "6px 0px",
                                                overflowX: "auto", // Add this line to enable horizontal scrolling
                                                "&::-webkit-scrollbar": {
                                                  height: "5px", // Customize the scrollbar height
                                                },
                                                "&::-webkit-scrollbar-thumb": {
                                                  backgroundColor: "#888", // Customize the scrollbar thumb color
                                                  borderRadius: "5px", // Optional: Customize the thumb border radius
                                                },
                                                "&::-webkit-scrollbar-track": {
                                                  backgroundColor: "#f1f1f1", // Optional: Customize the track color
                                                },
                                              }}
                                            >
                                              {/* <Label
                                              variant={"ghost"}
                                              sx={{ padding: "25px 4px" }}
                                            >
                                              {setIndex + 1}
                                            </Label>
                                           
                                            <CloseIcon sx={{ opacity: 0.3 }} /> */}
                                              {exerData.parameters.includes(
                                                "Duration/Pace"
                                              ) && (
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="0"
                                                  type="number"
                                                  value={
                                                    exerData.set[
                                                      selectedRound - 1
                                                    ]?.time
                                                      ? exerData.set[
                                                          selectedRound - 1
                                                        ]?.time
                                                      : ""
                                                  }
                                                  name="time"
                                                  onChange={(e) => {
                                                    handleChangeEmomSet(
                                                      e,
                                                      index,
                                                      exerIndex,
                                                      selectedRound - 1
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  label="Duration/Pace"
                                                  InputProps={{
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    readOnly: true,
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="timeType"
                                                          value={
                                                            exerData.set[
                                                              selectedRound - 1
                                                            ]?.timeType
                                                          }
                                                          label="Weight Type"
                                                          inputProps={{
                                                            style: {
                                                              padding: 0,
                                                            },
                                                            readOnly: true,
                                                          }}
                                                          onChange={(e) => {
                                                            handleChangeEmomSet(
                                                              e,
                                                              index,
                                                              exerIndex,
                                                              selectedRound - 1
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                            "& .MuiOutlinedInput-input":
                                                              {
                                                                padding: 0,
                                                              },
                                                          }}
                                                        >
                                                          <MenuItem value="sec">
                                                            sec
                                                          </MenuItem>
                                                          <MenuItem value="min">
                                                            min
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                    // endAdornment: (
                                                    //   <InputAdornment position="end">
                                                    //     <div
                                                    //       style={{
                                                    //         fontSize: "11px",
                                                    //       }}
                                                    //     >
                                                    //       sec
                                                    //     </div>
                                                    //   </InputAdornment>
                                                    // ),
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    fontSize: "13px",
                                                    // width: "20%",
                                                    width: "100px",
                                                  }}
                                                />
                                              )}
                                              {exerData.parameters.includes(
                                                "Reps"
                                              ) && (
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="Reps"
                                                  type="number"
                                                  label="Reps"
                                                  value={
                                                    exerData.set[
                                                      selectedRound - 1
                                                    ]?.reps
                                                      ? exerData.set[
                                                          selectedRound - 1
                                                        ]?.reps
                                                      : ""
                                                  }
                                                  name="reps"
                                                  onChange={(e) => {
                                                    handleChangeEmomSet(
                                                      e,
                                                      index,
                                                      exerIndex,
                                                      selectedRound - 1
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                    style: { fontSize: "14px" },
                                                  }}
                                                  InputProps={{
                                                    style: { fontSize: "13px" },
                                                    readOnly: true,
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    // "& legend": { display: "none" },
                                                    // "& fieldset": { top: 0 },
                                                    fontSize: "13px",
                                                    // width: "13%",
                                                    width: "60px",
                                                  }}
                                                />
                                              )}
                                              {exerData.parameters.includes(
                                                "Weight"
                                              ) && (
                                                <TextField
                                                  sx={{
                                                    borderRadius: "5px",
                                                    // "& legend": { display: "none" },
                                                    // "& fieldset": { top: 0 },
                                                    paddingRight: 0,
                                                    // width: "45%",
                                                    width: "175px",
                                                  }}
                                                  // className={classes.customInput}
                                                  size="small"
                                                  id="custom-input"
                                                  variant="outlined"
                                                  placeholder="Weight"
                                                  name="weight"
                                                  label="Weight"
                                                  value={
                                                    exerData?.set[
                                                      selectedRound - 1
                                                    ]?.weight
                                                      ? exerData?.set[
                                                          selectedRound - 1
                                                        ]?.weight
                                                      : ""
                                                  }
                                                  onChange={(e) => {
                                                    handleChangeEmomSet(
                                                      e,
                                                      index,
                                                      exerIndex,
                                                      selectedRound - 1
                                                    );
                                                  }}
                                                  type="number"
                                                  InputLabelProps={{
                                                    shrink: true,
                                                    style: { fontSize: "14px" },
                                                  }}
                                                  InputProps={{
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    readOnly: true,
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="weightType"
                                                          value={
                                                            exerData.set[
                                                              selectedRound - 1
                                                            ]?.weightType
                                                          }
                                                          readOnly
                                                          label="Weight Type"
                                                          onChange={(e) => {
                                                            handleChangeEmomSet(
                                                              e,
                                                              index,
                                                              exerIndex,
                                                              selectedRound - 1
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                          }}
                                                        >
                                                          <MenuItem value="1rm">
                                                            % 1RM
                                                          </MenuItem>
                                                          <MenuItem value="body_weight">
                                                            % Bodyweight
                                                          </MenuItem>
                                                          <MenuItem value="kg">
                                                            kilogram
                                                          </MenuItem>
                                                          <MenuItem value="lb">
                                                            Pound
                                                          </MenuItem>
                                                          <MenuItem value="poods">
                                                            Poods
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                              )}
                                              {exerData.parameters.includes(
                                                "Distance/Calories"
                                              ) && (
                                                <TextField
                                                  sx={{
                                                    borderRadius: "5px",
                                                    // "& legend": { display: "none" },
                                                    // "& fieldset": { top: 0 },
                                                    paddingRight: 0,
                                                    // width: "45%",
                                                    width: "175px",
                                                  }}
                                                  // className={classes.customInput}
                                                  size="small"
                                                  id="custom-input"
                                                  variant="outlined"
                                                  placeholder={
                                                    exerData.set[
                                                      selectedRound - 1
                                                    ].distanceType == "calories"
                                                      ? "Calories"
                                                      : "Distance"
                                                  }
                                                  name="distance"
                                                  label="Distance/Calories"
                                                  value={
                                                    exerData?.set[
                                                      selectedRound - 1
                                                    ]?.distance
                                                      ? exerData?.set[
                                                          selectedRound - 1
                                                        ]?.distance
                                                      : ""
                                                  }
                                                  onChange={(e) => {
                                                    handleChangeEmomSet(
                                                      e,
                                                      index,
                                                      exerIndex,
                                                      selectedRound - 1
                                                    );
                                                  }}
                                                  type="number"
                                                  InputLabelProps={{
                                                    shrink: true,
                                                    style: { fontSize: "14px" },
                                                  }}
                                                  InputProps={{
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    readOnly: true,
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="distanceType"
                                                          value={
                                                            exerData.set[
                                                              selectedRound - 1
                                                            ]?.distanceType
                                                          }
                                                          readOnly
                                                          label="Weight Type"
                                                          onChange={(e) => {
                                                            handleChangeEmomSet(
                                                              e,
                                                              index,
                                                              exerIndex,
                                                              selectedRound - 1
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                          }}
                                                        >
                                                          <MenuItem value="m">
                                                            Meter
                                                          </MenuItem>
                                                          <MenuItem value="km">
                                                            Kilometer
                                                          </MenuItem>
                                                          <MenuItem value="mi">
                                                            Mile
                                                          </MenuItem>
                                                          <MenuItem value="calories">
                                                            Calories
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                              )}

                                              {exerData.parameters.includes(
                                                "Tempo"
                                              ) && (
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="Tempo"
                                                  type="number"
                                                  label="Tempo"
                                                  value={
                                                    exerData.set[
                                                      selectedRound - 1
                                                    ]?.tempo
                                                      ? exerData.set[
                                                          selectedRound - 1
                                                        ]?.tempo
                                                      : ""
                                                  }
                                                  name="tempo"
                                                  onChange={(e) => {
                                                    handleChangeEmomSetTempo(
                                                      e,
                                                      index,
                                                      exerIndex,
                                                      selectedRound - 1
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                    style: { fontSize: "14px" },
                                                  }}
                                                  InputProps={{
                                                    style: {
                                                      fontSize: "13px",
                                                    },
                                                    readOnly: true,
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",

                                                    fontSize: "13px",
                                                    width: "60px",
                                                    // width: "13%",
                                                    // "& input": {
                                                    //   textAlign: "center",
                                                    // },
                                                    "& .MuiOutlinedInput-input":
                                                      {
                                                        padding: "8px 7px",
                                                      },
                                                  }}
                                                />
                                              )}
                                              {exerData.parameters.includes(
                                                "Rest"
                                              ) && (
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="0"
                                                  type="number"
                                                  value={
                                                    exerData.set[
                                                      selectedRound - 1
                                                    ]?.rest
                                                      ? exerData.set[
                                                          selectedRound - 1
                                                        ]?.rest
                                                      : ""
                                                  }
                                                  name="rest"
                                                  onChange={(e) => {
                                                    handleChangeEmomSet(
                                                      e,
                                                      index,
                                                      exerIndex,
                                                      selectedRound - 1
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    shrink: true,
                                                    style: { fontSize: "14px" },
                                                  }}
                                                  label="Rest"
                                                  InputProps={{
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    readOnly: true,
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="restType"
                                                          value={
                                                            exerData.set[
                                                              selectedRound - 1
                                                            ]?.restType
                                                          }
                                                          label="Weight Type"
                                                          inputProps={{
                                                            style: {
                                                              padding: 0,
                                                            },
                                                            readOnly: true,
                                                          }}
                                                          onChange={(e) => {
                                                            handleChangeEmomSet(
                                                              e,
                                                              index,
                                                              exerIndex,
                                                              selectedRound - 1
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                            "& .MuiOutlinedInput-input":
                                                              {
                                                                padding: 0,
                                                              },
                                                          }}
                                                        >
                                                          <MenuItem value="sec">
                                                            sec
                                                          </MenuItem>
                                                          <MenuItem value="min">
                                                            min
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                    // endAdornment: (
                                                    //   <InputAdornment position="end">
                                                    //     <div
                                                    //       style={{
                                                    //         fontSize: "11px",
                                                    //       }}
                                                    //     >
                                                    //       sec
                                                    //     </div>
                                                    //   </InputAdornment>
                                                    // ),
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    fontSize: "13px",
                                                    // width: "20%",
                                                    width: "100px",
                                                  }}
                                                />
                                              )}
                                            </Stack>
                                          </Tooltip>
                                        </div>
                                      )
                                      // ))
                                    }
                                  </Box>
                                </div>
                              </div>
                            );
                          })}
                        {/* <div
                          className="d-flex align-items-center ms-4 set-parameters mt-1 "
                          onClick={() => {
                            handleAddMoment(index, exerIndex);
                          }}
                        >
                          <AddCircleIcon />
                          <Typography variant="body2" sx={{ ml: 0.2 }}>
                            {`Add Movement`}
                          </Typography>
                        </div> */}
                      </div>
                    );
                  })}
                {/* <div className="d-flex justify-content-end mt-3">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{ p: "3px" }}
                          checked={addArray[mainindex].is_completed}
                          onChange={(e) => handleChangeComplete(e, mainindex)}
                        />
                      }
                      label="Mark as Complete"
                    />
                  </FormGroup>
                </div> */}
                {/* <div className="text-center container my-2">
                  <button
                    className="small-contained-button "
                    onClick={(e) => {
                      handleAddInterval(e);
                    }}
                  >
                    Add interval
                  </button>
                </div> */}
              </Box>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default EMOMExercisesUI;
