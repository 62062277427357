import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  TextField,
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Grid,
  Box,
  Stack,
  IconButton,
  Card,
  Radio,
  Backdrop,
  Chip,
  CircularProgress,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getSurveyTemplateDetail,
  getTemplateDetail,
} from "src/DAL/FormTemplate/feedbackTemplates";
// import { logo } from "src/assets";
import RatingComponent from "./component/RaingStylesView";
import { makeStyles } from "@mui/styles";
import AvatarGallery from "./component/imageViewOnLength";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function TemplatePreview() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const param = useParams();
  const _id = param.id;
  const classes = useStyles();
  const [templateTitle, setTemplateTitle] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [templateDescription, setTemplateDescription] = useState("");
  const [inputs, setInputs] = React.useState({
    question_type: "multiple_choice",
    question_statement: "",
    active_status: "",
  });
  const [rating, setRating] = useState(null);

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleBack = () => {
    navigate(-1);
  };

  async function getDetailsOfSurveyTemplate() {
    try {
      const res = await getTemplateDetail(_id);
      if (res.code === 200) {
        // console.log(res, "kjdsah");
        let data = [];
        res?.form_template?.questions.map((item) => {
          data.push({ display: false });
        });

        setTemplateTitle(res?.form_template?.title);
        setQuestions(res?.form_template?.questions);
        setTemplateDescription(res?.form_template?.description);
        setInputs({
          ["active_status"]: res?.form_template?.status,
          ["question_type"]: "multiple_choice",
        });
        // console.log(res?.support_ticket?.emp_obj_id, "getMySupportDetail");
        // return res;
      } else {
        enqueueSnackbar(res.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    getDetailsOfSurveyTemplate();
  }, []);
  //===================================================================
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <Container sx={{ width: { sm: 950, md: 950 } }}>
      <Stack
        direction="column"
        alignItems="left"
        justifyContent="space-between"
      >
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
      </Stack>
      <>
        <Stack
          direction="column"
          alignItems="center"
          spacing={1.5}
          justifyContent="space-between"
          mb={4.5}
        >
          {/* <img loading="lazy" src={logo} height="100px" width="100px" /> */}
          <h2 className="text-start w-100">{templateTitle}</h2>

          <Typography
            className="text-start w-100"
            sx={{ fontWeight: "500", fontSize: 16, opacity: 0.6 }}
          >
            {templateDescription}
          </Typography>
          {questions &&
            questions.map((item, index) => {
              // console.log(item, "afsoiusd", editShow[index].display);
              return (
                <Card
                  // variant="outlined"
                  // square={true}
                  sx={{
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    mb: 1,
                    width: { sm: "100%", md: "100%" },
                    margin: "auto",
                    p: 2,
                    backgroundColor: "",
                    borderRadius: 0.5,

                    // background:
                    //   "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)",
                    position: "relative",
                  }}
                >
                  {item?.required && (
                    <Chip
                      label={"Required"}
                      variant="contained"
                      color={"error"}
                      sx={{
                        fontSize: "10px",
                        height: "auto",
                        width: "auto",
                        position: "absolute",
                        top: 8,
                        left: 16,
                      }}
                    />
                  )}
                  <Box sx={{ width: "100%", margin: "auto" }} margin={0}>
                    <Grid
                      container
                      rowSpacing={0}
                      columnSpacing={{ xs: 0, sm: 0, md: 2 }}
                      mt={1}
                    >
                      <Grid item md={12} xs={12}>
                        <div className="d-flex align-items-center mb-2">
                          <div className="">
                            <Typography
                              sx={{ fontWeight: "900", fontSize: 16, mr: 1 }}
                            >
                              Q{index + 1}.
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: 14, mr: 1 }}
                            >
                              {item?.title}
                            </Typography>
                          </div>
                        </div>
                      </Grid>

                      {item?.type == "multiple_choice" ? (
                        <Grid item md={12} xs={12}>
                          {item?.options.map((item) => {
                            return (
                              <Grid item md={12}>
                                <div className="d-flex align-items-center mb-2">
                                  <div>
                                    <Radio disabled />
                                  </div>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      fontSize: 14,
                                      mr: 1,
                                    }}
                                  >
                                    {item}
                                  </Typography>
                                </div>
                              </Grid>
                            );
                          })}
                        </Grid>
                      ) : item?.type == "multiple_select" ? (
                        <Grid item md={12} xs={12}>
                          {item?.options.map((item) => {
                            return (
                              <Grid item md={12}>
                                <div className="d-flex align-items-center mb-2">
                                  <div>
                                    <Radio disabled />
                                  </div>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      fontSize: 14,
                                      mr: 1,
                                    }}
                                  >
                                    {item}
                                  </Typography>
                                </div>
                              </Grid>
                            );
                          })}
                        </Grid>
                      ) : item?.type == "image" ? (
                        <Grid item md={12} xs={12}>
                          <div className="d-flex align-items-center mb-2">
                            <div className="">
                              <Typography
                                sx={{
                                  fontWeight: "500",
                                  fontSize: 14,
                                  mr: 1,
                                }}
                              >
                                {item?.options[0]}
                              </Typography>
                            </div>
                            {/* <div>
                              <div>
                                <Rating
                                  name="read-only"
                                  value={0}
                                  max={item?.options_count}
                                  readOnly
                                />
                              </div>

                              <div className="d-flex justify-content-around">
                                <div>
                                  <small>1</small>
                                </div>
                                <div>
                                  <small>1</small>
                                </div>
                                <div>
                                  <small>1</small>
                                </div>
                                <div>
                                  <small>1</small>
                                </div>
                                <div>
                                  <small>1</small>
                                </div>
                              </div>
                            </div> */}
                            {/* <RatingComponent
                              rating={0}
                              onRatingChange={handleRatingChange}
                              details={true}
                              maxLength={item?.options_count}
                            /> */}
                            <AvatarGallery length={item?.options_count} />
                            <div className="">
                              <Typography
                                sx={{
                                  fontWeight: "500",
                                  fontSize: 14,
                                  ml: 1,
                                }}
                              >
                                {item?.options[1]}
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                      ) : (
                        item?.type == "free_text" && (
                          <Grid item md={12} xs={12}>
                            <TextField
                              id="outlined-multiline-static"
                              label="Answer"
                              multiline
                              disabled
                              rows={4}
                              sx={{ width: "100%" }}
                            />
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Box>
                </Card>
              );
            })}
        </Stack>
        {/* </form> */}
      </>
    </Container>
  );
}
