import { invokeApi } from "../../bl_libs/invokeApi";

export const WorkoutListingApi = async (page, limt, search, data) => {
  const requestObj = {
    path: `/api/workout?page=${page}&limit=${limt}&search=${search}&workout_type=${data?.workout_type}&status=${data?.status}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AllActiveWorkoutTypeListingApi = async () => {
  const requestObj = {
    path: `/api/workout_type/all_active/workout_types`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const workout_detail_api = async (slug) => {
  const requestObj = {
    path: `/api/workout/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const AddWorkoutApi = async (data) => {
  const requestObj = {
    path: `/api/workout`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const AddWorkoutAgainstMemberApi = async (data) => {
  const requestObj = {
    path: `/api/workout/add_workout/to_program_or_members`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditWorkoutApi = async (data, slug) => {
  const requestObj = {
    path: `/api/workout/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteWorkouteApi = async (slug) => {
  const requestObj = {
    path: `/api/workout/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
