import { invokeApi } from "../../bl_libs/invokeApi";

export const eventListApi = async (slug) => {
  const requestObj = {
    path: `/api/event/`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addEventApi = async (data) => {
  const requestObj = {
    path: `/api/event/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editEventApi = async (data, slug) => {
  const requestObj = {
    path: `/api/event/${slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const delete_program_workout_Api = async (slug) => {
  const requestObj = {
    path: `/api/program_workout/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const delete_program_workout_details_Api = async (id) => {
  const requestObj = {
    path: `/api/program_workout/details_for_delete/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const delete_program_workout_client_Api = async (data) => {
  const requestObj = {
    path: `/api/member/delete_member_workout/by_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const eventDetailApi = async (slug) => {
  const requestObj = {
    path: `/api/event/detail/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const add_program_workout_api = async (data) => {
  const requestObj = {
    path: `/api/program_workout`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const add_edit_program_workout_member_api = async (data) => {
  const requestObj = {
    path: `/api/member/update_workouts/for_member`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const delete_event_api = async (data) => {
  const requestObj = {
    path: `/api/event/delete`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_program_workout_api = async (data, workout_slug) => {
  const requestObj = {
    path: `/api/program_workout/${workout_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const programme_workout_list_api = async (data) => {
  const requestObj = {
    path: `/api/program_workout/?start=${data.start_date}&end=${data.end_date}&program_slug=${data.slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const programme_workout_exercise_history_list_api = async (data) => {
  const requestObj = {
    path: `/api/member/member_exercise_history/by_admin?exercise_id=${data.exercise}&member_id=${data.member}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const update_event_iteration_api = async (data, event_slug) => {
  const requestObj = {
    path: `/api/event/event_iteration/update/${event_slug}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
