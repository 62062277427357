import React, { useEffect, useState } from "react";

import Modal from "@mui/material/Modal";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
} from "@mui/material";

import { FormControlLabel, Box, Checkbox, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { get_root_value } from "src/utils/domUtils";
import WorkoutExercisesUI from "../WorkoutExercisesUI";
import WorkoutExercisesHistoryUI from "../WorkoutExercisesHistoryUI";
const fields = [
  "Duration/Pace",
  "Reps",
  "Weight",
  "Distance/Calories",
  "Rest",
  "Tempo",
];
function ExerciseHistoryModel({
  open,
  setOpen,
  data,
  handleChange,
  handleCopyExercise,
}) {
  const [addArray, setaddArray] = useState([
    {
      exercise: "",

      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          distance: "",
          distanceType: "m",
          timeType: "sec",
          restType: "sec",
          reps: "",
          rest: "",
          tempo: "",
        },
      ],
      parameters: [
        "Duration/Pace",
        "Reps",
        "Weight",
        "Distance/Calories",
        "Rest",
        "Tempo",
      ],
      description: "",

      superSet: [],
      superset_sets: "",
    },
  ]);
  const handleClose = () => {
    setOpen(false);
  };
  const handleTagToggle = (tag) => {
    const newSelectedTags = [...data];

    const index = newSelectedTags.indexOf(tag);
    if (index === -1) {
      newSelectedTags.push(tag);
    } else {
      newSelectedTags.splice(index, 1);
    }
    handleChange(newSelectedTags);
    // setSelectedFields(newSelectedTags);
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          },
        }}
      >
        <div
          className={`text-end mt-1 d-flex justify-content-between align-items-center`}
        >
          <DialogTitle
            sx={{
              paddingY: 0,
              color: get_root_value("--portal-theme-primary"),
            }}
          >
            History
          </DialogTitle>

          <IconButton
            className="back-screen-button me-1 "
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {/* <DialogTitle>
          <CloseIcon onClick={handleClose} />
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ minWidth: "850px" }}
          >
            <WorkoutExercisesHistoryUI
              addArray={data}
              setaddArray={handleChange}
              handleCopyExercise={handleCopyExercise}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ExerciseHistoryModel;
