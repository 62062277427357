import {
  Avatar,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Box,
  Typography,
  Chip,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import SyncIcon from "@mui/icons-material/Sync";

import EditIcon from "@mui/icons-material/Edit";
import { makeStyles } from "@mui/styles";

import CloseIcon from "@mui/icons-material/Close";

import { s3baseUrl } from "src/config/config";

// import CustomPopovers from "src/components/GeneralComponents/CustomPopovers";
import ToshowExercise from "./components/modalToshowExercise";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import ConfirmationWithDescription from "src/components/ModalPopover/ConfirmationWithDescription";
import Label from "src/components/Label";
import ParameterModel from "./components/ParameterModel";
import { Icon } from "@iconify/react";
import ExerciseHistoryModel from "./components/ExerciseHistoryModel";
import moment from "moment";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  customInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed

      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },

  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      backgroundColor: "#ebebeb",
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
}));

const WorkoutExercisesHistoryUI = ({
  addArray,
  setaddArray,
  setModalChangeExe,
  handleCopyExercise,
}) => {
  const { state } = useLocation();
  const myInputRef = useRef(null);
  const [exerInfo, setExsrInfo] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenForSuperset, setModalOpenForSuperset] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);

  const [superAlterIndex, setSuperAlterIndex] = useState("");
  const [alterChanges, setAlterChanges] = useState("");
  const [toAlterChanges, setToAlterChanges] = useState("");
  const [openExerciseAlter, setOpenExerciseAlter] = useState(false);
  const [openSuperSetAlter, setOpenSuperSetAlter] = useState(false);
  const [alterIndex, setalterIndex] = useState();

  const { enqueueSnackbar } = useSnackbar();

  const [isReaload, setIsReaload] = useState(false);

  const [detailsData, setDetailsData] = useState("");
  const [exerIndex, setExerIndex] = useState("");
  const [outerIndex, setOuterIndex] = useState("");

  const [openDetails, setOpenDetails] = useState(false);

  const [isHovered, setIsHovered] = useState("");
  const [isHoveredExercise, setIsHoveredExercise] = useState("");
  const [isExerciseForSuperSet, setIsExerciseForSuperSet] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();

  const handleMouseEnter = (setIndex, exerciseIndex) => {
    setIsHovered(setIndex);
    setIsHoveredExercise(exerciseIndex);
  };
  const timeoutRef = useRef(null);

  useEffect(() => {
    // Cleanup function to clear the timeout on component unmount
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);
  const handleMouseLeave = () => {
    // Introduce a delay of 100 milliseconds before updating state on hover out
    const delay = 0;
    timeoutRef.current = setTimeout(() => {
      setIsHovered("");
    }, delay);
  };

  const handleAdd = (i) => {
    let list = addArray;
    list.splice(i + 1, 0, {
      exercise: "",
      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          distance: "",
          distanceType: "m",
          timeType: "sec",
          restType: "sec",
          reps: "",
          rest: "",
          tempo: "",
        },
      ],
      parameters: [
        "Duration/Pace",
        "Reps",
        "Weight",
        "Distance/Calories",
        "Rest",
        "Tempo",
      ],
      description: "",
      superSet: [],
      superset_sets: "",
    });

    setaddArray([...list]);
    setModalChangeExe(true);
  };
  const handleAddSet = (mainindex, index) => {
    let list = addArray;

    list[mainindex]["set"].splice(index + 1, 0, {
      time: "",
      weight: "",
      weightType: "kg",
      distance: "",
      distanceType: "m",
      timeType: "sec",
      restType: "sec",
      reps: "",
      rest: "",
    });

    setaddArray([...list]);
    setModalChangeExe(true);
  };

  const handleDeleteSet = (mainindex, index) => {
    let list = addArray;

    list[mainindex]["set"].splice(index, 1);

    setaddArray([...list]);
    setModalChangeExe(true);
  };

  const handleDeleteExer = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);

    setaddArray(delValue);
    setModalChangeExe(true);
  };

  const removeSuperSet = (mainIndex, index) => {
    //  const { name, value } = e.target;
    const list = [...addArray];
    let firstPart = list[mainIndex]["superSet"].slice(0, index + 1);
    let secondPart = list[mainIndex]["superSet"].slice(index + 1);
    console.log(firstPart, secondPart, "dlfkjksdhfjas");
    let firstObject = { ...firstPart[0] };
    if (index > 0) {
      firstObject.superSet = firstPart;
      firstObject.superset_sets = list[mainIndex].superset_sets;
      console.log(firstObject, "fdjhsjkdfjkajds1", list[mainIndex]);
      // superset_sets;
    }

    let secondObject = { ...secondPart[0] };
    if (secondPart.length > 1) {
      secondObject.superSet = secondPart;
      secondObject.superset_sets = list[mainIndex].superset_sets;
      console.log(firstObject, "fdjhsjkdfjkajds2", list[mainIndex]);
    }

    let list1 = addArray;
    list1.splice(mainIndex + 1, 0, secondObject);
    list1.splice(mainIndex + 1, 0, firstObject);

    setaddArray([...list1]);
    setModalChangeExe(true);
    handleDeleteExer(mainIndex);
  };

  const handleAgreeSuperSet = (mainindex) => {
    setSuperAlterIndex(mainindex);

    setAlterChanges(
      addArray[mainindex + 1].superSet.length
        ? addArray[mainindex + 1].superset_sets
        : addArray[mainindex + 1].set.length
    );

    setToAlterChanges(
      addArray[mainindex].superSet.length
        ? addArray[mainindex].superset_sets
        : addArray[mainindex].set.length
    );

    const first = addArray[mainindex].superSet.length
      ? addArray[mainindex].superset_sets
      : addArray[mainindex].set.length;
    const second = addArray[mainindex + 1].superSet.length
      ? addArray[mainindex + 1].superset_sets
      : addArray[mainindex + 1].set.length;

    if (first == second) {
      addInSuperSet(mainindex);
      console.log(alterChanges, toAlterChanges, "dfjkhakjsdhfa");
    } else setOpenSuperSetAlter(true);
  };
  const handleAgreeExercise = (mainindex) => {
    setalterIndex(mainindex);

    setAlterChanges(
      addArray[mainindex + 1].superSet.length
        ? addArray[mainindex + 1].superset_sets
        : addArray[mainindex + 1].set.length
    );

    setToAlterChanges(
      addArray[mainindex].superSet.length
        ? addArray[mainindex].superSet.length
        : addArray[mainindex].set.length
    );

    const first = addArray[mainindex].superSet.length
      ? addArray[mainindex].superSet.length
      : addArray[mainindex].set.length;
    const second = addArray[mainindex + 1].superSet.length
      ? addArray[mainindex + 1].superset_sets
      : addArray[mainindex + 1].set.length;

    if (first == second) {
      addSuperSet(mainindex);
    } else {
      setOpenExerciseAlter(true);
    }
  };
  const addAgreeSuperSet = (index) => {
    //  const { name, value } = e.target;

    let current = { ...addArray[alterIndex] };
    current.superSet = [];
    let array = [current];

    if (
      addArray[alterIndex + 1]?.superSet &&
      addArray[alterIndex + 1].superSet.length > 0
    ) {
      addArray[alterIndex + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[alterIndex + 1]);
    }

    const list = [...addArray];
    list[alterIndex]["superSet"] = array;

    setaddArray(list);
    setModalChangeExe(true);

    let e = {
      target: {
        name: "superset_sets",
        value: addArray[alterIndex].set.length,
      },
    };
    handleChangeSuperSetCount(e, alterIndex);
    handleDelete(alterIndex + 1);
    setOpenExerciseAlter(false);
  };
  const addSuperSet = (index) => {
    //  const { name, value } = e.target;

    console.log(index, "dsjkhdkfgs", alterIndex);
    let current = { ...addArray[index] };
    current.superSet = [];
    let array = [current];

    if (
      addArray[index + 1]?.superSet &&
      addArray[index + 1].superSet.length > 0
    ) {
      addArray[index + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[index + 1]);
    }

    const list = [...addArray];
    list[index]["superSet"] = array;

    setaddArray(list);
    setModalChangeExe(true);

    let e = {
      target: {
        name: "superset_sets",
        value: addArray[index].set.length,
      },
    };
    handleChangeSuperSetCount(e, index);
    handleDelete(index + 1);
    setOpenExerciseAlter(false);
  };
  const addInSuperSet = (index) => {
    let array = addArray[index].superSet;
    if (addArray[index + 1].superSet.length > 0) {
      addArray[index + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[index + 1]);
    }
    const list = [...addArray];
    list[index]["superSet"] = array;

    setaddArray(list);
    setModalChangeExe(true);
    let e = {
      target: {
        name: "superset_sets",
        value: list[index].superset_sets,
      },
    };
    handleChangeSuperSetCount(e, index);
    handleDelete(index + 1);
    setOpenSuperSetAlter(false);
  };
  const addAgreeInSuperSet = () => {
    let array = addArray[superAlterIndex].superSet;
    if (addArray[superAlterIndex + 1].superSet.length > 0) {
      addArray[superAlterIndex + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[superAlterIndex + 1]);
    }
    const list = [...addArray];
    list[superAlterIndex]["superSet"] = array;

    setaddArray(list);
    setModalChangeExe(true);
    let e = {
      target: {
        name: "superset_sets",
        value: list[superAlterIndex].superset_sets,
      },
    };
    handleChangeSuperSetCount(e, superAlterIndex);
    handleDelete(superAlterIndex + 1);
    setOpenSuperSetAlter(false);
  };
  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);

    setaddArray(delValue);
    setModalChangeExe(true);
  };

  const handleArrow = (value, index) => {
    //  const { name, value } = e.target;

    const list = [...addArray];
    list[index]["open"] = value;

    setaddArray(list);
  };

  const handleArrowSuperSet = (value, mainindex, exerIndex) => {
    //  const { name, value } = e.target;

    const list = [...addArray];
    list[mainindex]["superSet"][exerIndex]["open"] = value;

    setaddArray(list);
  };

  const handleChange = (e, index, index1) => {
    const { name, value } = e.target;

    const list = [...addArray];

    list[index]["set"][index1][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };
  const handleChangeTempo = (e, index, index1) => {
    const { name, value } = e.target;
    if (value.length <= 4) {
      const list = [...addArray];

      list[index]["set"][index1][name] = value;

      setaddArray(list);
      setModalChangeExe(true);
    }
  };
  const handleChangeSuperSetDes = (e, index, index1) => {
    const { name, value } = e.target;
    const list = [...addArray];
    list[index]["superSet"][index1][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeDes = (e, index) => {
    const { name, value } = e.target;

    const list = [...addArray];
    list[index][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeSuperSet = (e, mainindex, exerIndex, setIndex) => {
    const { name, value } = e.target;

    const list = [...addArray];

    list[mainindex]["superSet"][exerIndex]["set"][setIndex][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };
  const handleChangeSuperSetTempo = (e, mainindex, exerIndex, setIndex) => {
    const { name, value } = e.target;
    if (value.length <= 4) {
      const list = [...addArray];
      list[mainindex]["superSet"][exerIndex]["set"][setIndex][name] = value;
      setaddArray(list);
      setModalChangeExe(true);
    }
  };
  const handleAddSetCount = (index) => {
    const list = [...addArray];
    const name = "superset_sets";
    const value = parseInt(list[index].superset_sets) + 1;
    // console.log(list[index].superset_sets, "ksdfjhkjsdnfkjs");
    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    list[index]?.superSet.forEach((superSetItem) => {
      const currentSetLength = superSetItem.set.length;

      if (currentSetLength < targetValue) {
        const objectsToAdd = targetValue - currentSetLength;

        for (let i = 0; i < objectsToAdd; i++) {
          superSetItem.set.push({
            time: "",
            weight: "",
            weightType: "kg",
            distance: "",
            distanceType: "m",
            timeType: "sec",
            restType: "sec",
            reps: "",
            rest: "",
          });
        }
      } else if (currentSetLength > targetValue) {
        // If the new count is less than the current count, remove excess objects
        superSetItem.set.splice(targetValue);
      }
    });

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleMinusSetCount = (index) => {
    const list = [...addArray];
    const name = "superset_sets";
    const value = parseInt(list[index].superset_sets) - 1;
    // console.log(list[index].superset_sets, "ksdfjhkjsdnfkjs");
    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    list[index]?.superSet.forEach((superSetItem) => {
      const currentSetLength = superSetItem.set.length;

      if (currentSetLength < targetValue) {
        const objectsToAdd = targetValue - currentSetLength;

        for (let i = 0; i < objectsToAdd; i++) {
          superSetItem.set.push({
            time: "",
            weight: "",
            weightType: "kg",
            distance: "",
            distanceType: "m",
            timeType: "sec",
            restType: "sec",
            reps: "",
            rest: "",
          });
        }
      } else if (currentSetLength > targetValue) {
        // If the new count is less than the current count, remove excess objects
        superSetItem.set.splice(targetValue);
      }
    });

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeSuperSetCount = (e, index) => {
    const { name, value } = e.target;
    const list = [...addArray];

    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    list[index]?.superSet.forEach((superSetItem) => {
      const currentSetLength = superSetItem.set.length;

      if (currentSetLength < targetValue) {
        const objectsToAdd = targetValue - currentSetLength;

        for (let i = 0; i < objectsToAdd; i++) {
          superSetItem.set.push({
            time: "",
            weight: "",
            weightType: "kg",
            distance: "",
            distanceType: "m",
            reps: "",
            rest: "",
          });
        }
      } else if (currentSetLength > targetValue) {
        // If the new count is less than the current count, remove excess objects
        superSetItem.set.splice(targetValue);
      }
    });

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeUp = (value) => {
    if (value == 0) {
      enqueueSnackbar("You are already on Top !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value - 1, 0, reorderedItem);

    setaddArray(items);
    setModalChangeExe(true);
  };

  const handleExerciseList = (value) => {
    setIsExerciseForSuperSet(false);
    setDetailsData(value);
    setOpenDetails(true);
  };
  const handleSupersetExerciseList = (mainIndex, exerInde) => {
    setDetailsData(mainIndex);
    setExerIndex(exerInde);
    setIsExerciseForSuperSet(true);
  };
  const handleSelectExercise = (value) => {
    const list = [...addArray];
    list[detailsData]["exercise"] = value;
    list[detailsData]["description"] = value?.instructions;

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleSelectSupersetExercise = (value) => {
    const list = [...addArray];

    list[detailsData]["superSet"][exerIndex]["exercise"] = value;
    list[detailsData]["superSet"][exerIndex]["description"] =
      value?.instructions;

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeDown = (value) => {
    if (value >= addArray.length - 1) {
      enqueueSnackbar("You are already on Bottom !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value + 1, 0, reorderedItem);

    setaddArray(items);
    setModalChangeExe(true);
  };

  // const handleCopyExercise = (value) => {
  //   handelCopyChange(value);
  // };
  const handleHistory = (value) => {
    setHistoryModalOpen(true);
    setModalOpenForSuperset(false);
    setOuterIndex(value);
    setExsrInfo(addArray[value].parameters);
  };
  const handleParametersSuperset = (main, inr) => {
    setModalOpen(true);
    setModalOpenForSuperset(true);
    setOuterIndex(main);
    setExerIndex(inr);
    setExsrInfo(addArray[main].superSet[inr].parameters);
  };
  const handleChangeParametersInset = (value) => {
    const items = Array.from(addArray);
    if (modalOpenForSuperset) {
      items[outerIndex].superSet[exerIndex].parameters = value;
      setExsrInfo(items[outerIndex].superSet[exerIndex].parameters);
      setaddArray(items);

      setModalChangeExe(true);
      return;
    }
    items[outerIndex].parameters = value;
    setExsrInfo(items[outerIndex].parameters);
    setaddArray(items);
    setModalChangeExe(true);
  };

  const memueOptions = (value) => {
    const MENU_OPTIONS = [];
    MENU_OPTIONS.unshift(
      {
        label: "Move Up",
        icon: "mdi:arrow-up",
        handleClick: handleChangeUp,
        disabled: value == 0 ? true : false,
      },
      {
        label: "Move Down",
        icon: "ic:outline-arrow-downward",
        handleClick: handleChangeDown,
        disabled: value >= addArray.length - 1 ? true : false,
      }
      // {
      //   label: "Parameters",
      //   icon: "pajamas:labels",
      //   handleClick: handleCopyExercise,
      // }
    );

    return MENU_OPTIONS;
  };

  if (isReaload === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-md">
      <ConfirmationWithDescription
        open={openSuperSetAlter}
        setOpen={setOpenSuperSetAlter}
        title={"Are you sure you want to take this action?"}
        descriotion={`You are trying to alternate an exercise with  ${toAlterChanges} sets with an exercise with ${alterChanges} sets. If you proceed, we’ll modify the exercise to have  ${toAlterChanges} sets.`}
        handleAgree={addAgreeInSuperSet}
      />
      <ConfirmationWithDescription
        open={openExerciseAlter}
        setOpen={setOpenExerciseAlter}
        title={"Are you sure you want to take this action?"}
        descriotion={`You are trying to alternate an exercise with  ${toAlterChanges} sets with an exercise with ${alterChanges} sets. If you proceed, we’ll modify the exercise to have  ${toAlterChanges} sets.`}
        handleAgree={addAgreeSuperSet}
      />
      <ToshowExercise
        open={openDetails}
        setOpen={setOpenDetails}
        handleChange={handleSelectExercise}
      />
      <ToshowExercise
        open={isExerciseForSuperSet}
        setOpen={setIsExerciseForSuperSet}
        handleChange={handleSelectSupersetExercise}
      />
      <ParameterModel
        open={modalOpen}
        setOpen={setModalOpen}
        data={exerInfo}
        handleChange={handleChangeParametersInset}
      />
      <ExerciseHistoryModel
        open={historyModalOpen}
        setOpen={setHistoryModalOpen}
        data={exerInfo}
        handleChange={handleChangeParametersInset}
      />
      <div className="mt-2 ">
        <div className="characters">
          {/* <form onSubmit={handleSubmit}> */}

          <form onSubmit={() => {}}>
            {addArray?.map((data, mainindex) => {
              return (
                <>
                  {data?.superSet && data?.superSet.length > 0 ? (
                    <>
                      <div
                        style={{ position: "relative" }}
                        className="workout-Superset-card my-1"
                      >
                        {/* <SuperSets length={data?.superset_sets} data={data} /> */}
                        <Box>
                          <div className="d-flex align-items-start justify-content-between ">
                            <div className="d-flex align-items-center w-100  mb-2">
                              <div>Superset of</div>

                              <TextField
                                id="outlined-basic"
                                size="small"
                                variant="outlined"
                                placeholder="Sets"
                                type="number"
                                value={data.superset_sets}
                                name="superset_sets"
                                disabled
                                onChange={(e) =>
                                  handleChangeSuperSetCount(e, mainindex)
                                }
                                InputLabelProps={{
                                  shrink: true,
                                  style: { fontSize: "14px" },
                                }}
                                //   label="Set"
                                InputProps={{
                                  style: { fontSize: "13px", padding: "0" },
                                }}
                                sx={{
                                  mx: 1,
                                  borderRadius: "5px",
                                  "& legend": { display: "none" },
                                  "& fieldset": { top: 0 },
                                  fontSize: "13px",
                                  "& input": {
                                    padding: "3px 5px",
                                    textAlign: "center",
                                  },
                                  width: "4%",
                                }}
                              />

                              <div>sets</div>
                            </div>
                          </div>

                          <div className=" mb-2 ">
                            {data?.superSet?.map((exerData, exerIndex) => (
                              <div
                                style={{
                                  width: "100%",
                                  padding: "1px",
                                  position: "relative",
                                }}
                              >
                                <div
                                  className="d-flex workout-set-card"
                                  style={{
                                    width: "100%",
                                    position: "relative",
                                    overflow: "hidden",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      position: "relative",
                                      width: "100%",
                                    }}
                                  >
                                    {exerData?.is_modified && (
                                      <span
                                        style={{
                                          position: "absolute",
                                          top: "-11px",
                                          justifyContent: "center",
                                          zIndex: "1",
                                          left: "-38px",
                                          transform: "rotate(-53deg)",
                                        }}
                                      >
                                        <Chip
                                          label={"Modified"}
                                          variant="contained"
                                          color={"error"}
                                          sx={{
                                            fontSize: "10px",
                                            height: "auto",
                                            width: "auto",
                                          }}
                                        />
                                      </span>
                                    )}
                                    <div className="d-flex align-items-center ">
                                      <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        placeholder="Exercise"
                                        value={exerData?.exercise?.title}
                                        name="Exercise"
                                        onClick={() =>
                                          handleSupersetExerciseList(
                                            mainindex,
                                            exerIndex
                                          )
                                        }
                                        // onChange={(e) => {}}
                                        InputLabelProps={{
                                          shrink: true,
                                          style: { fontSize: "14px" },
                                        }}
                                        label="Exercise"
                                        InputProps={{
                                          style: {
                                            fontSize: "13px",
                                            paddingLeft: "6px",
                                          },
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              {exerData?.exercise?.image
                                                ?.thumbnail_3 && (
                                                <Avatar
                                                  sx={{ borderRadius: "5px" }}
                                                  alt="User Image"
                                                  src={
                                                    s3baseUrl +
                                                    exerData?.exercise?.image
                                                      ?.thumbnail_3
                                                  }
                                                />
                                              )}
                                            </InputAdornment>
                                          ),
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <div style={{ fontSize: "16px" }}>
                                                {exerData?.exercise ? (
                                                  <EditIcon
                                                    fontSize="12px"
                                                    sx={{ opacity: 0.5 }}
                                                  />
                                                ) : (
                                                  <AddIcon
                                                    sx={{ opacity: 0.5 }}
                                                  />
                                                )}
                                              </div>
                                            </InputAdornment>
                                          ),
                                          inputProps: {
                                            readOnly: true, // Disable editing
                                          },
                                        }}
                                        sx={{
                                          borderRadius: "5px",

                                          fontSize: "13px",
                                          width: "50%",
                                        }}
                                      />
                                      <div className="d-flex align-items-center mx-1">
                                        <div className="text-center">
                                          {!exerData?.open ? (
                                            <KeyboardArrowDownIcon
                                              sx={{
                                                cursor: "pointer",
                                                opacity: 0.5,
                                                fontSize: "20px",
                                              }}
                                              onClick={() => {
                                                handleArrowSuperSet(
                                                  true,
                                                  mainindex,
                                                  exerIndex
                                                );
                                              }}
                                            />
                                          ) : (
                                            <KeyboardArrowUpIcon
                                              sx={{
                                                cursor: "pointer",
                                                opacity: 0.5,
                                                fontSize: "20px",
                                              }}
                                              onClick={() => {
                                                handleArrowSuperSet(
                                                  false,
                                                  mainindex,
                                                  exerIndex
                                                );
                                              }}
                                            />
                                          )}
                                        </div>
                                        <Typography
                                          variant="body2"
                                          sx={{ opacity: 0.6 }}
                                        >
                                          {` Sets ${exerData?.set?.length}`}
                                        </Typography>
                                      </div>
                                    </div>

                                    {exerData?.open &&
                                      exerData?.set?.map((item, setIndex) => (
                                        <div className="d-flex align-items-center my-3 workout-card-inset">
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                            justifyContent="start"
                                            sx={{
                                              overflowX: "auto", // Add this line to enable horizontal scrolling
                                              "&::-webkit-scrollbar": {
                                                height: "5px", // Customize the scrollbar height
                                              },
                                              "&::-webkit-scrollbar-thumb": {
                                                backgroundColor: "#888", // Customize the scrollbar thumb color
                                                borderRadius: "5px", // Optional: Customize the thumb border radius
                                              },
                                              "&::-webkit-scrollbar-track": {
                                                backgroundColor: "#f1f1f1", // Optional: Customize the track color
                                              },
                                            }}
                                          >
                                            <Label
                                              variant={"ghost"}
                                              sx={{ padding: "25px 4px" }}
                                            >
                                              {setIndex + 1}
                                            </Label>
                                            {/* <TextField
                                            id="outlined-basic"
                                            size="small"
                                            variant="outlined"
                                            placeholder="Set"
                                            type="number"
                                            // value={item.set}
                                            name="set"
                                            disabled
                                            InputLabelProps={{
                                              shrink: true,
                                              style: { fontSize: "14px" },
                                            }}
                                            label="Set"
                                            InputProps={{
                                              style: { fontSize: "13px" },
                                            }}
                                            sx={{
                                              borderRadius: "5px",
                                              fontSize: "13px",
                                              width: "13%",
                                            }}
                                          /> */}
                                            <CloseIcon sx={{ opacity: 0.3 }} />
                                            {exerData.parameters.includes(
                                              "Duration/Pace"
                                            ) && (
                                              <TextField
                                                id="outlined-basic"
                                                size="small"
                                                variant="outlined"
                                                placeholder="0"
                                                type="number"
                                                value={item?.time}
                                                name="time"
                                                onChange={(e) => {
                                                  handleChangeSuperSet(
                                                    e,
                                                    mainindex,
                                                    exerIndex,
                                                    setIndex
                                                  );
                                                }}
                                                InputLabelProps={{
                                                  shrink: true,
                                                  style: {
                                                    fontSize: "14px",
                                                  },
                                                }}
                                                label="Duration/Pace"
                                                InputProps={{
                                                  style: {
                                                    fontSize: "13px",
                                                    paddingRight: 0,
                                                  },
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      <Select
                                                        id="select-option"
                                                        variant="outlined"
                                                        size="small"
                                                        name="timeType"
                                                        value={item?.timeType}
                                                        label="Weight Type"
                                                        inputProps={{
                                                          style: {
                                                            padding: 0,
                                                          },
                                                        }}
                                                        onChange={(e) => {
                                                          handleChangeSuperSet(
                                                            e,
                                                            mainindex,
                                                            exerIndex,
                                                            setIndex
                                                          );
                                                        }}
                                                        sx={{
                                                          "& legend": {
                                                            display: "none",
                                                          },
                                                          "& fieldset": {
                                                            top: 0,
                                                          },
                                                          fontSize: "13px",
                                                          border: "none",
                                                          "& fieldset": {
                                                            border: "none",
                                                          },
                                                          "& .MuiOutlinedInput-input":
                                                            {
                                                              padding: 0,
                                                            },
                                                        }}
                                                      >
                                                        <MenuItem value="sec">
                                                          sec
                                                        </MenuItem>
                                                        <MenuItem value="min">
                                                          min
                                                        </MenuItem>
                                                      </Select>
                                                    </InputAdornment>
                                                  ),
                                                  // endAdornment: (
                                                  //   <InputAdornment position="end">
                                                  //     <div
                                                  //       style={{
                                                  //         fontSize: "11px",
                                                  //       }}
                                                  //     >
                                                  //       sec
                                                  //     </div>
                                                  //   </InputAdornment>
                                                  // ),
                                                }}
                                                sx={{
                                                  borderRadius: "5px",
                                                  fontSize: "13px",
                                                  // width: "20%",
                                                  width: "100px",
                                                }}
                                              />
                                            )}
                                            {exerData.parameters.includes(
                                              "Reps"
                                            ) && (
                                              <TextField
                                                id="outlined-basic"
                                                size="small"
                                                variant="outlined"
                                                placeholder="Reps"
                                                type="number"
                                                label="Reps"
                                                value={item?.reps}
                                                name="reps"
                                                onChange={(e) => {
                                                  handleChangeSuperSet(
                                                    e,
                                                    mainindex,
                                                    exerIndex,
                                                    setIndex
                                                  );
                                                }}
                                                InputLabelProps={{
                                                  shrink: true,
                                                  style: { fontSize: "14px" },
                                                }}
                                                InputProps={{
                                                  style: { fontSize: "13px" },
                                                }}
                                                sx={{
                                                  borderRadius: "5px",
                                                  // "& legend": { display: "none" },
                                                  // "& fieldset": { top: 0 },
                                                  fontSize: "13px",
                                                  // width: "13%",
                                                  width: "60px",
                                                }}
                                              />
                                            )}
                                            {exerData.parameters.includes(
                                              "Weight"
                                            ) && (
                                              <TextField
                                                sx={{
                                                  borderRadius: "5px",
                                                  // "& legend": { display: "none" },
                                                  // "& fieldset": { top: 0 },
                                                  paddingRight: 0,
                                                  // width: "45%",
                                                  width: "175px",
                                                }}
                                                // className={classes.customInput}
                                                size="small"
                                                id="custom-input"
                                                variant="outlined"
                                                placeholder="Weight"
                                                name="weight"
                                                label="Weight"
                                                value={item.weight}
                                                onChange={(e) => {
                                                  handleChangeSuperSet(
                                                    e,
                                                    mainindex,
                                                    exerIndex,
                                                    setIndex
                                                  );
                                                }}
                                                type="number"
                                                InputLabelProps={{
                                                  shrink: true,
                                                  style: { fontSize: "14px" },
                                                }}
                                                InputProps={{
                                                  style: {
                                                    fontSize: "13px",
                                                    paddingRight: 0,
                                                  },
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      <Select
                                                        id="select-option"
                                                        variant="outlined"
                                                        size="small"
                                                        name="weightType"
                                                        value={item?.weightType}
                                                        label="Weight Type"
                                                        onChange={(e) => {
                                                          handleChangeSuperSet(
                                                            e,
                                                            mainindex,
                                                            exerIndex,
                                                            setIndex
                                                          );
                                                        }}
                                                        sx={{
                                                          "& legend": {
                                                            display: "none",
                                                          },
                                                          "& fieldset": {
                                                            top: 0,
                                                          },
                                                          fontSize: "13px",
                                                          border: "none",
                                                          "& fieldset": {
                                                            border: "none",
                                                          },
                                                        }}
                                                      >
                                                        <MenuItem value="1rm">
                                                          % 1RM
                                                        </MenuItem>
                                                        <MenuItem value="body_weight">
                                                          % Bodyweight
                                                        </MenuItem>
                                                        <MenuItem value="kg">
                                                          kilogram
                                                        </MenuItem>
                                                        <MenuItem value="lb">
                                                          Pound
                                                        </MenuItem>
                                                        <MenuItem value="poods">
                                                          Poods
                                                        </MenuItem>
                                                      </Select>
                                                    </InputAdornment>
                                                  ),
                                                }}
                                              />
                                            )}
                                            {exerData.parameters.includes(
                                              "Distance/Calories"
                                            ) && (
                                              <TextField
                                                sx={{
                                                  borderRadius: "5px",
                                                  // "& legend": { display: "none" },
                                                  // "& fieldset": { top: 0 },
                                                  paddingRight: 0,
                                                  // width: "45%",
                                                  width: "175px",
                                                }}
                                                // className={classes.customInput}
                                                size="small"
                                                id="custom-input"
                                                variant="outlined"
                                                placeholder={
                                                  item?.distanceType ==
                                                  "calories"
                                                    ? "Calories"
                                                    : "Distance"
                                                }
                                                name="distance"
                                                label="Distance/Calories"
                                                value={item?.distance}
                                                onChange={(e) => {
                                                  handleChangeSuperSet(
                                                    e,
                                                    mainindex,
                                                    exerIndex,
                                                    setIndex
                                                  );
                                                }}
                                                type="number"
                                                InputLabelProps={{
                                                  shrink: true,
                                                  style: { fontSize: "14px" },
                                                }}
                                                InputProps={{
                                                  style: {
                                                    fontSize: "13px",
                                                    paddingRight: 0,
                                                  },
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      <Select
                                                        id="select-option"
                                                        variant="outlined"
                                                        size="small"
                                                        name="distanceType"
                                                        value={
                                                          item?.distanceType
                                                        }
                                                        label="Weight Type"
                                                        onChange={(e) => {
                                                          handleChangeSuperSet(
                                                            e,
                                                            mainindex,
                                                            exerIndex,
                                                            setIndex
                                                          );
                                                        }}
                                                        sx={{
                                                          "& legend": {
                                                            display: "none",
                                                          },
                                                          "& fieldset": {
                                                            top: 0,
                                                          },
                                                          fontSize: "13px",
                                                          border: "none",
                                                          "& fieldset": {
                                                            border: "none",
                                                          },
                                                        }}
                                                      >
                                                        <MenuItem value="m">
                                                          Meter
                                                        </MenuItem>
                                                        <MenuItem value="km">
                                                          Kilometer
                                                        </MenuItem>
                                                        <MenuItem value="mi">
                                                          Mile
                                                        </MenuItem>
                                                        <MenuItem value="calories">
                                                          Calories
                                                        </MenuItem>
                                                      </Select>
                                                    </InputAdornment>
                                                  ),
                                                }}
                                              />
                                            )}

                                            {exerData.parameters.includes(
                                              "Tempo"
                                            ) && (
                                              <TextField
                                                id="outlined-basic"
                                                size="small"
                                                variant="outlined"
                                                placeholder="Tempo"
                                                type="number"
                                                label="Tempo"
                                                value={item?.tempo}
                                                name="tempo"
                                                onChange={(e) => {
                                                  handleChangeSuperSetTempo(
                                                    e,
                                                    mainindex,
                                                    exerIndex,
                                                    setIndex
                                                  );
                                                }}
                                                InputLabelProps={{
                                                  shrink: true,
                                                  style: { fontSize: "14px" },
                                                }}
                                                InputProps={{
                                                  style: {
                                                    fontSize: "13px",
                                                  },
                                                }}
                                                sx={{
                                                  borderRadius: "5px",

                                                  fontSize: "13px",
                                                  width: "60px",
                                                  // width: "13%",
                                                  // "& input": {
                                                  //   textAlign: "center",
                                                  // },
                                                  // "& .MuiOutlinedInput-input": {
                                                  //   padding: "8px 0px",
                                                  // },
                                                }}
                                              />
                                            )}
                                            {exerData.parameters.includes(
                                              "Rest"
                                            ) && (
                                              <TextField
                                                id="outlined-basic"
                                                size="small"
                                                variant="outlined"
                                                placeholder="0"
                                                type="number"
                                                value={item?.rest}
                                                name="rest"
                                                onChange={(e) => {
                                                  handleChangeSuperSet(
                                                    e,
                                                    mainindex,
                                                    exerIndex,
                                                    setIndex
                                                  );
                                                }}
                                                InputLabelProps={{
                                                  shrink: true,
                                                  style: { fontSize: "14px" },
                                                }}
                                                label="Rest"
                                                InputProps={{
                                                  style: {
                                                    fontSize: "13px",
                                                    paddingRight: 0,
                                                  },
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      <Select
                                                        id="select-option"
                                                        variant="outlined"
                                                        size="small"
                                                        name="restType"
                                                        value={item?.restType}
                                                        label="Weight Type"
                                                        inputProps={{
                                                          style: {
                                                            padding: 0,
                                                          },
                                                        }}
                                                        onChange={(e) => {
                                                          handleChangeSuperSet(
                                                            e,
                                                            mainindex,
                                                            exerIndex,
                                                            setIndex
                                                          );
                                                        }}
                                                        sx={{
                                                          "& legend": {
                                                            display: "none",
                                                          },
                                                          "& fieldset": {
                                                            top: 0,
                                                          },
                                                          fontSize: "13px",
                                                          border: "none",
                                                          "& fieldset": {
                                                            border: "none",
                                                          },
                                                          "& .MuiOutlinedInput-input":
                                                            {
                                                              padding: 0,
                                                            },
                                                        }}
                                                      >
                                                        <MenuItem value="sec">
                                                          sec
                                                        </MenuItem>
                                                        <MenuItem value="min">
                                                          min
                                                        </MenuItem>
                                                      </Select>
                                                    </InputAdornment>
                                                  ),
                                                  // endAdornment: (
                                                  //   <InputAdornment position="end">
                                                  //     <div
                                                  //       style={{
                                                  //         fontSize: "11px",
                                                  //       }}
                                                  //     >
                                                  //       sec
                                                  //     </div>
                                                  //   </InputAdornment>
                                                  // ),
                                                }}
                                                sx={{
                                                  borderRadius: "5px",
                                                  fontSize: "13px",
                                                  // width: "20%",
                                                  width: "100px",
                                                }}
                                              />
                                            )}
                                          </Stack>
                                        </div>
                                      ))}
                                    {exerData?.open && (
                                      <TextField
                                        id="outlined-basic"
                                        size="small"
                                        variant="outlined"
                                        placeholder="Instructions"
                                        label="Instructions"
                                        type="number"
                                        value={exerData.description}
                                        name="description"
                                        multiline
                                        rows={2}
                                        onChange={(e) =>
                                          handleChangeSuperSetDes(
                                            e,
                                            mainindex,
                                            exerIndex
                                          )
                                        }
                                        InputLabelProps={{
                                          shrink: true,
                                          style: { fontSize: "14px" },
                                        }}
                                        InputProps={{
                                          style: { fontSize: "14px" },
                                        }}
                                        sx={{
                                          borderRadius: "5px",
                                          // "& legend": { display: "none" },
                                          // "& fieldset": { top: 0 },

                                          mt: 2,
                                          width: "100%",
                                        }}
                                      />
                                    )}
                                  </Box>
                                </div>
                                <span
                                  className="cross-icon"
                                  style={{
                                    position: "absolute",
                                    bottom: "-10px",

                                    width: "100%",
                                    justifyContent: "center",
                                    zIndex: 1,
                                  }}
                                >
                                  {data?.superSet.length > 1 &&
                                  exerIndex < data?.superSet.length - 1 ? (
                                    <div className="">
                                      <SyncDisabledIcon
                                        onClick={() =>
                                          removeSuperSet(mainindex, exerIndex)
                                        }
                                        className="sync-icon-remove"
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                            ))}
                          </div>
                        </Box>
                        <span
                          className="cross-icon"
                          style={{
                            position: "absolute",
                            bottom: "-17px",
                            width: "100%",
                            justifyContent: "center",
                            zIndex: 1,
                            // backgroundColor: "gray",
                            // borderRadius: "50%",
                            // padding: "4px 5px",
                          }}
                        >
                          {addArray.length > 1 &&
                          mainindex < addArray.length - 1 ? (
                            <div className="">
                              <SyncIcon
                                onClick={() => handleAgreeSuperSet(mainindex)}
                                className="sync-icon-add"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        padding: "1px",
                        position: "relative",
                      }}
                    >
                      <div
                        // direction="row"

                        className="d-flex my-1 workout-set-card w-100"
                        onMouseEnter={() => handleMouseLeave()}
                        style={{
                          width: "100%",
                          position: "relative",
                          overflow: "hidden",
                        }}
                      >
                        <Box
                          sx={{
                            position: "relative",
                            width: "100%",
                          }}
                          onMouseEnter={() => handleMouseLeave()}
                        >
                          <div
                            className=" align-items-start justify-content-between w-100 "
                            onMouseEnter={() => handleMouseLeave()}
                          >
                            <Typography className="text-end">
                              {moment(data?.date).format("DD-MMM-YYYY")}
                            </Typography>
                            <div className="w-100 d-flex align-items-center">
                              {data?.is_modified && (
                                <span
                                  style={{
                                    position: "absolute",
                                    top: "-11px",
                                    justifyContent: "center",
                                    zIndex: "1",
                                    left: "-38px",
                                    transform: "rotate(-53deg)",
                                  }}
                                >
                                  <Chip
                                    label={"Modified"}
                                    variant="contained"
                                    color={"error"}
                                    sx={{
                                      fontSize: "10px",
                                      height: "auto",
                                      width: "auto",
                                    }}
                                  />
                                </span>
                              )}
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                placeholder="Exercise"
                                value={
                                  data?.exercise?.title
                                    ? data?.exercise?.title
                                    : ""
                                }
                                name="Exercise"
                                // onClick={() => handleExerciseList(mainindex)}
                                // onChange={(e) => {}}
                                InputLabelProps={{
                                  shrink: true,
                                  style: { fontSize: "14px" },
                                }}
                                label="Exercise"
                                InputProps={{
                                  style: {
                                    fontSize: "13px",
                                    paddingLeft: "6px",
                                  },
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {data?.exercise?.image?.thumbnail_3 && (
                                        <Avatar
                                          sx={{ borderRadius: "5px" }}
                                          alt="User Image"
                                          src={
                                            s3baseUrl +
                                            data?.exercise?.image?.thumbnail_3
                                          }
                                        />
                                      )}
                                    </InputAdornment>
                                  ),
                                  // endAdornment: (
                                  //   <InputAdornment position="end">
                                  //     <div style={{ fontSize: "16px" }}>
                                  //       {data?.exercise ? (
                                  //         <EditIcon
                                  //           fontSize="12px"
                                  //           sx={{ opacity: 0.5 }}
                                  //         />
                                  //       ) : (
                                  //         <AddIcon sx={{ opacity: 0.5 }} />
                                  //       )}
                                  //     </div>
                                  //   </InputAdornment>
                                  // ),
                                  inputProps: {
                                    readOnly: true, // Disable editing
                                  },
                                }}
                                sx={{
                                  borderRadius: "5px",

                                  fontSize: "13px",
                                  width: "50%",
                                }}
                              />
                              <div className="d-flex align-items-center mx-1">
                                <div className="text-center">
                                  {!data?.open ? (
                                    <KeyboardArrowDownIcon
                                      sx={{
                                        cursor: "pointer",
                                        opacity: 0.5,
                                        fontSize: "20px",
                                      }}
                                      onClick={() => {
                                        handleArrow(true, mainindex);
                                      }}
                                    />
                                  ) : (
                                    <KeyboardArrowUpIcon
                                      sx={{
                                        cursor: "pointer",
                                        opacity: 0.5,
                                        fontSize: "20px",
                                      }}
                                      onClick={() => {
                                        handleArrow(false, mainindex);
                                      }}
                                    />
                                  )}
                                </div>
                                <Typography
                                  variant="body2"
                                  sx={{ opacity: 0.6 }}
                                >
                                  {` Sets ${data?.set?.length}`}
                                </Typography>
                                {/* <div
                                  className="d-flex align-items-center ms-3 set-parameters "
                                  onClick={() => {
                                    handleCopyExercise(data);
                                  }}
                                >
                                  <Icon
                                    icon="radix-icons:copy"
                                    fontSize="10px"
                                  />
                                  <Typography variant="body2" sx={{ ml: 0.5 }}>
                                    {`Copy Exercise`}
                                  </Typography>
                                </div> */}
                              </div>
                            </div>
                          </div>

                          {data?.open &&
                            data?.set?.map((item, index1) => (
                              <div
                                className="d-flex align-items-center my-3 workout-card-inset overflow-scroll"
                                onMouseEnter={() =>
                                  handleMouseEnter(index1, mainindex)
                                }

                                // onMouseLeave={() => handleMouseLeave()}
                              >
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                  justifyContent="start"
                                >
                                  <Label
                                    variant={"ghost"}
                                    sx={{ padding: "25px 4px" }}
                                  >
                                    {index1 + 1}
                                  </Label>
                                  {/* <TextField
                                  id="outlined-basic"
                                  onMouseLeave={() =>
                                    handleMouseEnter(index1, mainindex)
                                  }
                                  onClick={() => handleMouseLeave()}
                                  size="small"
                                  variant="outlined"
                                  placeholder="Set"
                                  type="number"
                                  // value={item.set}
                                  name="set"
                                  disabled
                                  InputLabelProps={{
                                    shrink: true,
                                    style: { fontSize: "14px" },
                                  }}
                                  label="Set"
                                  InputProps={{
                                    style: { fontSize: "13px" },
                                  }}
                                  sx={{
                                    borderRadius: "5px",
                                    // "& legend": { display: "none" },
                                    // "& fieldset": { top: 0 },
                                    fontSize: "13px",
                                    width: "13%",
                                  }}
                                /> */}
                                  <CloseIcon sx={{ opacity: 0.3 }} />

                                  {data.parameters.includes(
                                    "Duration/Pace"
                                  ) && (
                                    <TextField
                                      id="outlined-basic"
                                      size="small"
                                      variant="outlined"
                                      onMouseLeave={() =>
                                        handleMouseEnter(index1, mainindex)
                                      }
                                      onClick={() => handleMouseLeave()}
                                      placeholder="0"
                                      type="number"
                                      value={item?.time}
                                      // inputRef={(ref) => {
                                      //   // Assign the ref to the focusedInputRef
                                      //   focusedInputRef.current = ref;
                                      // }}
                                      name="time"
                                      onChange={(e) => {
                                        handleChange(e, mainindex, index1);
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { fontSize: "14px" },
                                      }}
                                      label="Duration/Pace"
                                      InputProps={{
                                        style: {
                                          fontSize: "13px",
                                          paddingRight: 0,
                                        },
                                        readOnly: true,
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <Select
                                              id="select-option"
                                              variant="outlined"
                                              size="small"
                                              name="timeType"
                                              value={item?.timeType}
                                              label="Weight Type"
                                              readOnly
                                              inputProps={{
                                                style: {
                                                  padding: 0,
                                                },
                                              }}
                                              onChange={(e) => {
                                                handleChange(
                                                  e,
                                                  mainindex,
                                                  index1
                                                );
                                              }}
                                              sx={{
                                                "& legend": {
                                                  display: "none",
                                                },
                                                "& fieldset": {
                                                  top: 0,
                                                },
                                                fontSize: "13px",
                                                border: "none",
                                                "& fieldset": {
                                                  border: "none",
                                                },
                                                "& .MuiOutlinedInput-input": {
                                                  padding: 0,
                                                },
                                              }}
                                            >
                                              <MenuItem value="sec">
                                                sec
                                              </MenuItem>
                                              <MenuItem value="min">
                                                min
                                              </MenuItem>
                                            </Select>
                                          </InputAdornment>
                                        ),
                                        // endAdornment: (
                                        //   <InputAdornment position="end">
                                        //     <div style={{ fontSize: "11px" }}>
                                        //       sec
                                        //     </div>
                                        //   </InputAdornment>
                                        // ),
                                      }}
                                      sx={{
                                        borderRadius: "5px",

                                        fontSize: "13px",
                                        // width: "20%",
                                        width: "100px",
                                      }}
                                    />
                                  )}
                                  {data.parameters.includes("Reps") && (
                                    <TextField
                                      id="outlined-basic"
                                      size="small"
                                      variant="outlined"
                                      placeholder="Reps"
                                      type="number"
                                      onMouseLeave={() =>
                                        handleMouseEnter(index1, mainindex)
                                      }
                                      onClick={() => handleMouseLeave()}
                                      label="Reps"
                                      value={item?.reps}
                                      name="reps"
                                      onChange={(e) => {
                                        handleChange(e, mainindex, index1);
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { fontSize: "14px" },
                                      }}
                                      InputProps={{
                                        style: { fontSize: "13px" },
                                        readOnly: true,
                                      }}
                                      sx={{
                                        borderRadius: "5px",
                                        // "& legend": { display: "none" },
                                        // "& fieldset": { top: 0 },
                                        fontSize: "13px",
                                        // width: "13%",
                                        width: "60px",
                                      }}
                                    />
                                  )}
                                  {data.parameters.includes("Weight") && (
                                    <TextField
                                      sx={{
                                        borderRadius: "5px",
                                        // "& legend": { display: "none" },
                                        // "& fieldset": { top: 0 },
                                        paddingRight: 0,
                                        // width: "45%",
                                        width: "175px",
                                      }}
                                      // className={classes.customInput}
                                      size="small"
                                      id="custom-input"
                                      variant="outlined"
                                      onMouseLeave={() =>
                                        handleMouseEnter(index1, mainindex)
                                      }
                                      onClick={() => handleMouseLeave()}
                                      placeholder="Weight"
                                      name="weight"
                                      label="Weight"
                                      value={item.weight}
                                      onChange={(e) => {
                                        handleChange(e, mainindex, index1);
                                      }}
                                      type="number"
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { fontSize: "14px" },
                                      }}
                                      InputProps={{
                                        style: {
                                          fontSize: "13px",
                                          paddingRight: 0,
                                        },
                                        readOnly: true,
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <Select
                                              id="select-option"
                                              variant="outlined"
                                              size="small"
                                              name="weightType"
                                              value={item?.weightType}
                                              label="Weight Type"
                                              readOnly
                                              onChange={(e) => {
                                                handleChange(
                                                  e,
                                                  mainindex,
                                                  index1
                                                );
                                              }}
                                              onMouseLeave={() =>
                                                handleMouseEnter(
                                                  index1,
                                                  mainindex
                                                )
                                              }
                                              onClick={() => handleMouseLeave()}
                                              sx={{
                                                "& legend": {
                                                  display: "none",
                                                },
                                                "& fieldset": { top: 0 },
                                                fontSize: "13px",
                                                border: "none",
                                                "& fieldset": {
                                                  border: "none",
                                                },
                                              }}
                                            >
                                              <MenuItem value="1rm">
                                                % 1RM
                                              </MenuItem>
                                              <MenuItem value="body_weight">
                                                % Bodyweight
                                              </MenuItem>
                                              <MenuItem value="kg">
                                                kilogram
                                              </MenuItem>
                                              <MenuItem value="lb">
                                                Pound
                                              </MenuItem>
                                              <MenuItem value="poods">
                                                Poods
                                              </MenuItem>
                                            </Select>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  )}
                                  {data.parameters.includes(
                                    "Distance/Calories"
                                  ) && (
                                    <TextField
                                      sx={{
                                        borderRadius: "5px",
                                        // "& legend": { display: "none" },
                                        // "& fieldset": { top: 0 },
                                        paddingRight: 0,
                                        // width: "45%",
                                        width: "175px",
                                      }}
                                      // className={classes.customInput}
                                      size="small"
                                      id="custom-input"
                                      variant="outlined"
                                      onMouseLeave={() =>
                                        handleMouseEnter(index1, mainindex)
                                      }
                                      onClick={() => handleMouseLeave()}
                                      placeholder={
                                        item?.distanceType == "calories"
                                          ? "Calories"
                                          : "Distance"
                                      }
                                      name="distance"
                                      label="Distance/Calories"
                                      value={item?.distance}
                                      onChange={(e) => {
                                        handleChange(e, mainindex, index1);
                                      }}
                                      type="number"
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { fontSize: "14px" },
                                      }}
                                      InputProps={{
                                        style: {
                                          fontSize: "13px",
                                          paddingRight: 0,
                                        },
                                        readOnly: true,
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <Select
                                              id="select-option"
                                              variant="outlined"
                                              size="small"
                                              name="distanceType"
                                              value={item?.distanceType}
                                              label="Weight Type"
                                              readOnly
                                              onChange={(e) => {
                                                handleChange(
                                                  e,
                                                  mainindex,
                                                  index1
                                                );
                                              }}
                                              onMouseLeave={() =>
                                                handleMouseEnter(
                                                  index1,
                                                  mainindex
                                                )
                                              }
                                              onClick={() => handleMouseLeave()}
                                              sx={{
                                                "& legend": {
                                                  display: "none",
                                                },
                                                "& fieldset": { top: 0 },
                                                fontSize: "13px",
                                                border: "none",
                                                "& fieldset": {
                                                  border: "none",
                                                },
                                              }}
                                            >
                                              <MenuItem value="m">
                                                Meter
                                              </MenuItem>
                                              <MenuItem value="km">
                                                Kilometer
                                              </MenuItem>
                                              <MenuItem value="mi">
                                                Mile
                                              </MenuItem>
                                              <MenuItem value="calories">
                                                Calories
                                              </MenuItem>
                                            </Select>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  )}

                                  {data.parameters.includes("Tempo") && (
                                    <TextField
                                      id="outlined-basic"
                                      size="small"
                                      variant="outlined"
                                      placeholder="Tempo"
                                      type="number"
                                      label="Tempo"
                                      value={item?.tempo}
                                      name="tempo"
                                      onChange={(e) => {
                                        handleChangeTempo(e, mainindex, index1);
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { fontSize: "14px" },
                                      }}
                                      InputProps={{
                                        style: {
                                          fontSize: "13px",
                                        },
                                        readOnly: true,
                                      }}
                                      sx={{
                                        borderRadius: "5px",

                                        fontSize: "13px",
                                        width: "60px",
                                        // width: "13%",
                                        // "& input": {
                                        //   textAlign: "center",
                                        // },
                                        "& .MuiOutlinedInput-input": {
                                          padding: "8px 7px",
                                        },
                                      }}
                                    />
                                  )}
                                  {data.parameters.includes("Rest") && (
                                    <TextField
                                      id="outlined-basic"
                                      size="small"
                                      variant="outlined"
                                      placeholder="0"
                                      type="number"
                                      value={item?.rest}
                                      name="rest"
                                      onChange={(e) => {
                                        handleChange(e, mainindex, index1);
                                      }}
                                      onMouseLeave={() =>
                                        handleMouseEnter(index1, mainindex)
                                      }
                                      onClick={() => handleMouseLeave()}
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { fontSize: "14px" },
                                      }}
                                      label="Rest"
                                      InputProps={{
                                        style: {
                                          fontSize: "13px",
                                          paddingRight: 0,
                                        },
                                        readOnly: true,
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <Select
                                              id="select-option"
                                              variant="outlined"
                                              size="small"
                                              name="restType"
                                              value={item?.restType}
                                              label="Weight Type"
                                              readOnly
                                              inputProps={{
                                                style: {
                                                  padding: 0,
                                                },
                                              }}
                                              onChange={(e) => {
                                                handleChange(
                                                  e,
                                                  mainindex,
                                                  index1
                                                );
                                              }}
                                              sx={{
                                                "& legend": {
                                                  display: "none",
                                                },
                                                "& fieldset": {
                                                  top: 0,
                                                },
                                                fontSize: "13px",
                                                border: "none",
                                                "& fieldset": {
                                                  border: "none",
                                                },
                                                "& .MuiOutlinedInput-input": {
                                                  padding: 0,
                                                },
                                              }}
                                            >
                                              <MenuItem value="sec">
                                                sec
                                              </MenuItem>
                                              <MenuItem value="min">
                                                min
                                              </MenuItem>
                                            </Select>
                                          </InputAdornment>
                                        ),
                                        // endAdornment: (
                                        //   <InputAdornment position="end">
                                        //     <div style={{ fontSize: "11px" }}>
                                        //       sec
                                        //     </div>
                                        //   </InputAdornment>
                                        // ),
                                      }}
                                      sx={{
                                        borderRadius: "5px",

                                        fontSize: "13px",
                                        // width: "20%",
                                        width: "100px",
                                      }}
                                    />
                                  )}
                                </Stack>
                              </div>
                            ))}
                          {/* {data?.open && (
                            <TextField
                              id="outlined-basic"
                              size="small"
                              variant="outlined"
                              placeholder="Instructions"
                              label="Instructions"
                              type="number"
                              value={data.description}
                              name="description"
                              multiline
                              rows={2}
                              onChange={(e) => handleChangeDes(e, mainindex)}
                              InputLabelProps={{
                                shrink: true,
                                style: { fontSize: "14px" },
                              }}
                              InputProps={{
                                style: { fontSize: "14px" },
                              }}
                              sx={{
                                borderRadius: "5px",
                                // "& legend": { display: "none" },
                                // "& fieldset": { top: 0 },

                                mt: 2,
                                width: "100%",
                              }}
                            />
                          )} */}
                        </Box>
                      </div>
                      <span
                        className="cross-icon"
                        style={{
                          position: "absolute",
                          bottom: "-12px",

                          width: "100%",
                          justifyContent: "center",
                          zIndex: 1,
                        }}
                      >
                        {addArray.length > 1 &&
                        mainindex < addArray.length - 1 ? (
                          <div className="">
                            <SyncIcon
                              onClick={() => handleAgreeExercise(mainindex)}
                              className="sync-icon-add"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  )}
                </>
              );
            })}
          </form>
        </div>
      </div>
    </div>
  );
};
export default WorkoutExercisesHistoryUI;
