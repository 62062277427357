import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Autocomplete,
  InputAdornment,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { get_root_value } from "src/utils/domUtils";
import { useState } from "react";
import { s3baseUrl } from "src/config/config";

import AddWorkoutCalender from "../AddWorkoutCalender";
import AddWorkoutCalenderModel from "./AddWorkoutCalenderModel";
import { AllActiveWorkoutTypeListingApi } from "src/DAL/workoutType/workoutType";
import { useEffect } from "react";

import { WorkoutListingApi } from "src/DAL/workout/workout";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { useSnackbar } from "notistack";
import moment from "moment";
import { add_program_workout_api } from "src/DAL/programmeWorkout/programmeWorkout";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "10%",
    marginBottom: "10%",
  },
}));
export default function AddWorkoutPopup({
  open,
  setOpen,
  date,
  programme,
  reloadList,
  day,
}) {
  const [selectedOption, setSelectedOption] = useState("existing");
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const [workouts, setWorkouts] = useState([]);
  const [dialogWidth, setDialogWidth] = useState("sm");
  const [workoutType, setWorkoutType] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalChangeExe, setModalChangeExe] = useState(false);
  const [inputs, setInputs] = React.useState({
    title: "",
    workout: null,
  });
  const [newWorkout, setNewWorkout] = useState(false);

  const [serchText, setSerchText] = useState("");
  const [deleteDoc, setDeleteDoc] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [workoutAsTem, setWorkoutAsTem] = useState();
  const [openCloseDialog, setOpenCloseDialog] = useState(false);
  const childRef = React.useRef();

  // Function to be called in the child component
  const handleParentButtonClick = () => {
    // Call the exposed function in the child component using the ref
    childRef.current.childButtonClick();
  };
  const handleParentSaveAsTemplateButtonClick = () => {
    // Call the exposed function in the child component using the ref
    childRef.current.childButtonClick2();
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveExistingWorkout = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!inputs.workout) {
      enqueueSnackbar("please select Workout", { variant: "error" });
      return;
    }

    const exerciseId = inputs?.workout?.exercises?.map((item) => {
      console.log(item, "sfdhsdjkfa");
      let dataToSend = { ...item };
      dataToSend.exercise_info = item.exercise_info;

      return dataToSend;
    });
    const formDataObject = {
      title: inputs.title,
      workout_type: null,
      description: inputs?.workout?.description,
      status: true,
      exercises: exerciseId,
      program_id: programme?._id,
      workout_day: day ? day : 1,
      workout_date: moment(date).format("YYYY-MM-DD"),
    };
    console.log(formDataObject, "dslfkdjsaa", inputs.workout, programme);

    const result = await add_program_workout_api(formDataObject);
    if (result.code === 200) {
      reloadList();
      setIsLoading(false);
      setOpen(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleAgreeDelete = (value) => {
    // setDeleteDoc(value);
    setOpenCloseDialog(true);
  };
  const handleDelete = async () => {
    handleClose();
    setOpenCloseDialog(false);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleSearch = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSerchText(value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const openSaveAsDialog = () => {
    setOpen(false);
    setModalOpen(true);
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const getWorkoutTypes = async () => {
    try {
      const result = await AllActiveWorkoutTypeListingApi();
      if (result.code === 200) {
        // console.log(result, "dfkjsdahaf");
        setWorkoutType(result?.workout_types);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {}
  };
  const getExerciseList = async () => {
    let postData = {
      workout_type: "",
      status: "active",
    };
    let result = await WorkoutListingApi(0, 100, serchText, postData);
    if (result.code == 200) {
      console.log(result, "kfaskjdfkla");
      // setIsLoading(false);

      setWorkouts(result?.workouts);
    }
  };
  useEffect(() => {
    console.log(modalChangeExe, "flksjdkfajkldsj");
  }, [modalChangeExe]);
  useEffect(() => {
    getExerciseList();
  }, [serchText]);
  useEffect(() => {
    getWorkoutTypes();
  }, []);
  React.useEffect(() => {
    if (workoutAsTem) setModalOpen(true);
  }, [workoutAsTem]);
  React.useEffect(() => {
    if (newWorkout) setDialogWidth("lg");
    setModalChangeExe(false);
  }, [newWorkout]);
  React.useEffect(() => {
    if (open) {
      setDialogWidth("sm");
      setNewWorkout(false);
      setWorkoutAsTem("");
      setModalChangeExe(false);
      setSelectedOption("existing");
      setInputs((values) => ({
        ...values,
        workout: "",
        title: "",
      }));
    }
  }, [open]);

  return (
    <div>
      <AddWorkoutCalenderModel
        onOpen={modalOpen}
        setOnOpen={setModalOpen}
        workoutTypes={workoutType}
        workoutAsTem={workoutAsTem}
      />
      <CustomConfirmation
        openDelete={openCloseDialog}
        setOpenDelete={setOpenCloseDialog}
        title={"Are you sure you want to Close ?"}
        handleAgree={handleDelete}
      />
      <Dialog
        open={open}
        // onClose={handleClose}
        // scroll="body"
        fullWidth
        maxWidth={dialogWidth}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="show-dialog-on-full"
      >
        {!newWorkout ? (
          <div
            className={`text-end mt-1 d-flex justify-content-between align-items-center`}
          >
            <DialogTitle
              sx={{
                paddingY: 0,
                color: get_root_value("--portal-theme-primary"),
              }}
            >
              Choose an Option
            </DialogTitle>

            <IconButton
              className="back-screen-button me-1 "
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
        ) : (
          <div className={`text-end mt-1 me-1  `}>
            {modalChangeExe ? (
              <IconButton
                className="back-screen-button"
                onClick={handleAgreeDelete}
              >
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton
                className="back-screen-button me-1 "
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
        )}

        <>
          {!newWorkout ? (
            isLoading ? (
              <CircularProgress className={classes.loading} color="primary" />
            ) : (
              <DialogContent
                sx={{
                  // paddingY: 0,
                  maxHeight: "60vh", // Set a maximum height for the scrollable area
                  overflowY: "auto", // Enable vertical scrolling when content overflows
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ opacity: 0.8, fontSize: 12, mb: 2 }}
                >
                  If you add the workout, it will be added to the calendars of{" "}
                  {programme?.members} users
                </Typography>
                <FormControl component="fieldset" fullWidth>
                  <InputLabel id="select-label">Select Option</InputLabel>
                  <Select
                    labelId="select-label"
                    id="select"
                    value={selectedOption}
                    onChange={handleOptionChange}
                    label="Select Option"
                  >
                    <MenuItem value="existing">
                      Use From Workout Library
                    </MenuItem>
                    <MenuItem value="new">New Workout</MenuItem>
                  </Select>
                </FormControl>
                {/* <RadioGroup
                sx={{
                  ml: 1,
                }}
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <FormControlLabel
                  value="new"
                  control={<Radio sx={{ padding: "0px" }} />}
                  label="New Workout"
                />
                <FormControlLabel
                  value="existing"
                  control={<Radio sx={{ padding: "0px" }} />}
                  label="Use Existing Workout"
                />
              </RadioGroup> */}

                {selectedOption == "existing" && (
                  <div className="row mt-3">
                    <div className="col-lg-6 col-md-6 col-sm-12 ">
                      <Autocomplete
                        id="tags-outlined"
                        options={workouts}
                        getOptionLabel={(option) => option.title}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar
                                src={s3baseUrl + option?.image?.thumbnail_1}
                                alt={option.title}
                                sx={{
                                  width: 30,
                                  height: 30,
                                  marginRight: 1,
                                  borderRadius: "50%",
                                }}
                              />
                              {option.title}
                            </div>
                          </li>
                        )}
                        filterSelectedOptions
                        value={inputs.workout || null}
                        onChange={(event, newValue) => {
                          setInputs((values) => ({
                            ...values,
                            workout: newValue,
                            title: newValue?.title || "",
                          }));
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Workouts"
                            placeholder="Workouts"
                            onChange={handleSearch}
                            InputProps={{
                              ...params.InputProps,
                              style: { paddingLeft: "6px" },
                              startAdornment: (
                                <InputAdornment position="start">
                                  {inputs?.workout?.image?.thumbnail_1 && (
                                    <Avatar
                                      sx={{ borderRadius: "5px" }}
                                      alt="User Image"
                                      src={
                                        s3baseUrl +
                                        inputs?.workout?.image?.thumbnail_1
                                      }
                                    />
                                  )}
                                </InputAdornment>
                              ),
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 ">
                      <TextField
                        id="outlined-basic"
                        label="Workout Name"
                        variant="outlined"
                        fullWidth
                        required
                        name="title"
                        value={inputs.title}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                )}
              </DialogContent>
            )
          ) : (
            <>
              <DialogContent
                sx={{
                  paddingY: 0,
                  height: "80vh", // Set a maximum height for the scrollable area
                  overflowY: "auto", // Enable vertical scrolling when content overflows
                }}
              >
                <AddWorkoutCalender
                  ref={childRef}
                  date={date}
                  programme={programme}
                  setOpen={setOpen}
                  setWorkoutAsTem={setWorkoutAsTem}
                  reloadList={reloadList}
                  day={day}
                  setIsLoading={setIsLoading}
                  setModalChangeExe={setModalChangeExe}
                />
              </DialogContent>
            </>
          )}
          {selectedOption == "existing" ? (
            <DialogActions>
              <Button
                disabled={isLoading}
                sx={{ textTransform: "inherit" }}
                onClick={isLoading ? "" : handleSaveExistingWorkout}
                size="small"
                color="primary"
                variant="contained"
                className="small-contained-dialog-button"
              >
                Save
              </Button>
            </DialogActions>
          ) : !newWorkout ? (
            <DialogActions>
              <Button
                onClick={() => {
                  setNewWorkout(true);
                }}
                sx={{ textTransform: "inherit" }}
                size="small"
                color="primary"
                variant="contained"
                className="small-contained-dialog-button"
              >
                Continue
              </Button>
            </DialogActions>
          ) : (
            <DialogActions>
              <Button
                disabled={isLoading}
                onClick={isLoading ? "" : handleParentButtonClick}
                sx={{ textTransform: "inherit" }}
                size="small"
                color="primary"
                variant="contained"
                className="small-contained-dialog-button"
              >
                Save only
              </Button>
              <Button
                disabled={isLoading}
                onClick={() => {
                  // openSaveAsDialog();
                  isLoading ? "" : handleParentSaveAsTemplateButtonClick();
                }}
                size="small"
                color="primary"
                variant="contained"
                className="small-contained-dialog-button"
                sx={{ textTransform: "inherit" }}
              >
                Save and save as template
              </Button>
            </DialogActions>
          )}
        </>
      </Dialog>
    </div>
  );
}
