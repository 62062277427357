import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { IconButton, Divider, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

import { makeStyles } from "@mui/styles";

import AddExercise from "src/pages/Exercises/AddExercise";
import TagesToShow from "src/pages/Exercises/component/modalToshowTages";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";

import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import { AddExerciseApi } from "src/DAL/exercise/exercise";
import { AllActiveExerciseTypeListingApi } from "src/DAL/exerciseType/exerciseType";
import { Icon } from "@iconify/react";

const Input = styled("input")({
  display: "none",
});
// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const ProgrammesImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

const exercise = [
  "Single Leg Knee Dominant",
  "Single Leg Hip Dominant",
  "Double Leg Knee Dominant",
  "Double Leg Hip Dominant",
  "Alternating",
  "Anti - Extension",
  "Anti - Rotation",
  "Anti - Lateral Flexion",
  "Anti - Flexion",
  "Rotatory",
  "Bilateral",
  "Contralateral",
  "Conditioning",
  "Horizontal Push",
  "Horizontal Pull",
  "Vertical Push",
  "Vertical Pull",
  "Ipsilateral",
  "Mobility",
  "Flexibility",
  "Static Stretch",
  "Dynamic Stretch",
  "Isolation Exercise",
  "Compound Exercise",
];
const difficulty = ["Novice", "Intermediate", "Advanced"];
const main_muscle = [
  "Abductors",
  "Abs",
  "Adductor",
  "Back",
  "Bicep",
  "Calves",
  "Chest",
  "Arms",
  "Glutes",
  "Hamstrings",
  "Lats",
  "Neck",
  "Obliques",
  "Quads",
  "Shoulders",
  "Traps",
  "Triceps",
];
function ToshowAddExercise({ open, setOpen }) {
  const handleCloseDialog1 = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [exerciseTags, setExerciseTags] = useState([]);
  const [difficultyTags, setDifficultyTags] = useState([]);
  const [mainMuscleTags, setMainMuscleTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setProfileImage] = React.useState();
  const [fileGif, setProfileImageGif] = React.useState();
  const [exerciseType, setExerciseType] = useState([]);
  const [openDetails, setOpenDetails] = useState(false);
  //   const [value, setValue] = React.useState(new Date());

  const [inputs, setInputs] = React.useState({
    title: "",
    status: true,
    programStatus: "true",
    image: {},
    type: "",
    videoUrl: "",
    short_description: "",
    detailed_description: "",
    upload_type: "video",
    gif: "",
    video_duration: new Date("00"),
  });

  const handleChangeTags = (exerciseTags, difficultyTags, mainMuscleTags) => {
    setExerciseTags(exerciseTags);
    setDifficultyTags(difficultyTags);
    setMainMuscleTags(mainMuscleTags);
    setOpenDetails(false);
  };

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };
  const fileChangedGif = (e) => {
    setProfileImageGif(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["gif"]: e.target.files[0],
    });
  };

  const handleDetails = (value) => {
    setOpenDetails(true);
  };

  const urlPatternValidation = (URL) => {
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    return regex.test(URL);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.upload_type == "video") {
      if (urlPatternValidation(inputs.videoUrl) === false) {
        enqueueSnackbar("Enter Valid Video URL", { variant: "error" });
        return;
      }
    }
    if (inputs.upload_type == "gif") {
      if (!inputs.gif) {
        enqueueSnackbar("Upload Gif", { variant: "error" });
        return;
      }
    }

    const postTags = {
      difficulty: difficultyTags,
      main_muscle: mainMuscleTags,
      exercise: exerciseTags,
    };
    const formData = new FormData();
    formData.append("title", inputs.title);

    if (file) {
      formData.append("image", inputs.image);
    }
    if (fileGif) {
      formData.append("gif", inputs.upload_type == "gif" ? inputs.gif : "");
    }
    formData.append("type", inputs.type);
    formData.append("instructions", inputs.short_description);
    formData.append("status", inputs.status);
    formData.append(
      "video_url",
      inputs.upload_type == "video" ? inputs.videoUrl : ""
    );
    formData.append("tags", JSON.stringify(postTags));
    //console form data

    console.log(...formData, "form data value", postTags);

    setIsLoading(true);
    const result = await AddExerciseApi(formData);
    if (result.code === 200) {
      //console.log(result, "result");
      enqueueSnackbar(result.message, { variant: "success" });
      handleCloseDialog1();
    } else {
      //console.log(result, "error case");
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
    // }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const getExerciseTypes = async () => {
    try {
      const result = await AllActiveExerciseTypeListingApi();
      if (result.code === 200) {
        console.log(result, "dfkjsdahaf");
        setExerciseType(result?.exercise_types);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {}
  };
  React.useEffect(() => {
    getExerciseTypes();
  }, []);
  React.useEffect(() => {
    setInputs({
      title: "",
      status: true,
      programStatus: "true",
      image: {},
      type: "",
      videoUrl: "",
      short_description: "",
      detailed_description: "",
      upload_type: "video",
      gif: "",
      video_duration: new Date("00"),
    });
    setExerciseTags([]);
    setDifficultyTags([]);
    setMainMuscleTags([]);
    setProfileImage("");
    setProfileImageGif("");
    setIsLoading(false);
  }, [open]);
  //   //console.log(typeof audioFile, "values of audio to be send ");

  return (
    <>
      {/* New to solve delete issue  */}
      <Dialog
        open={open}
        // onClose={handleCloseDialog1}
        // scroll="body"
        fullWidth
        maxWidth="md"
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
            minHeight: "550px",
          },
        }}
      >
        <div className="d-flex justify-content-between align-items-center ">
          <div className="row w-100">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <DialogTitle>Add Exercises</DialogTitle>
            </div>
            <div className="col-lg-9 col-md-9 my-auto">
              <div className="text-end ">
                <IconButton
                  className="back-screen-button "
                  onClick={handleCloseDialog1}
                >
                  <Icon icon="lets-icons:close-round" />
                </IconButton>
              </div>
            </div>
          </div>
        </div>

        <DialogContent>
          {isLoading ? (
            <CircularProgress className={classes.loading} color="primary" />
          ) : (
            <div className="">
              <TagesToShow
                open={openDetails}
                setOpen={setOpenDetails}
                handleOk={handleChangeTags}
                exerciseTags={exerciseTags}
                difficultyTags={difficultyTags}
                mainMuscleTags={mainMuscleTags}
              />

              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Exercise Name*"
                    variant="outlined"
                    fullWidth
                    name="title"
                    value={inputs.title}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Exercise Type *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="type"
                      value={inputs.type}
                      label="Exercise Type *"
                      onChange={handleChange}
                    >
                      {exerciseType.map((item) => {
                        // console.log(item, "sdkjfjsd");
                        return (
                          <MenuItem value={item._id} key={item._id}>
                            {item.title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Exercise Status *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="status"
                      value={inputs.status}
                      label="lesson Status *"
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>Active</MenuItem>
                      <MenuItem value={false}>Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Upload Type *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="upload_type"
                      value={inputs.upload_type}
                      label="Upload Type *"
                      onChange={handleChange}
                    >
                      <MenuItem value="video">Video URL</MenuItem>
                      <MenuItem value="gif">GIF</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {inputs?.upload_type == "video" ? (
                  <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                    <TextField
                      id="outlined-basic"
                      label="Landing Exercise Video Url *"
                      variant="outlined"
                      fullWidth
                      name="videoUrl"
                      value={inputs.videoUrl}
                      onChange={handleChange}
                    />
                  </div>
                ) : (
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                    <div className="row w-100 div-style ms-0 pt-0">
                      <div className="col-5">
                        <p className="">Upload Gif *</p>
                        <FormHelperText className="pt-0">
                          Gif Size(1000 X 670)
                        </FormHelperText>
                      </div>
                      <div className="col-2">
                        {fileGif && (
                          <img
                            className="image-border"
                            src={fileGif}
                            height="50"
                          />
                        )}
                      </div>
                      <div className="col-5 text-end pt-2">
                        <label htmlFor="contained-button-file-1">
                          <Input
                            accept="image/GIF"
                            id="contained-button-file-1"
                            // multiple
                            type="file"
                            name="gif"
                            onChange={fileChangedGif}
                          />

                          <Button
                            className="small-contained-button"
                            startIcon={<FileUploadIcon />}
                            component="span"
                          >
                            Upload
                          </Button>
                        </label>
                      </div>
                    </div>
                    {inputs.gif.name == "" ? (
                      ""
                    ) : (
                      <p className="text-secondary">{inputs.gif.name}</p>
                    )}
                  </div>
                )}

                {/* <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Upload Audio</p>
              <FormHelperText className="pt-0">
                Audio mp3 (max 200mb)
              </FormHelperText>
            </div>
            <div className="col-2">
              {audioFile && (
                <DeleteIcon
                  onClick={handldeDeleteAudio}
                  className="mt-3 icon-color"
                />
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="audio">
                <Input
                  accept="audio/mp3,audio/*;capture=microphone"
                  id="audio"
                  multiple
                  name="audio"
                  type="file"
                  onChange={audioFileChange}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          <p className="text-secondary">{audioFile && audioFile.name}</p>
        </div> */}
                <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                  <div className="row w-100 div-style ms-0 pt-0">
                    <div className="col-5">
                      <p className="">Upload Image *</p>
                      <FormHelperText className="pt-0">
                        Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                      </FormHelperText>
                    </div>
                    <div className="col-2">
                      {file && (
                        <img className="image-border" src={file} height="50" />
                      )}
                    </div>
                    <div className="col-5 text-end pt-2">
                      <label htmlFor="contained-button-file">
                        {/* <input
                  type="file"
                  accept=".jpg, .jpeg, .png, .webp, .gif"
                  onChange={handleOtherImageFileChange}
                /> */}
                        <Input
                          accept=".jpg, .jpeg, .png, .webp"
                          id="contained-button-file"
                          multiple
                          type="file"
                          name="image"
                          onChange={fileChangedHandler}
                        />

                        <Button
                          className="small-contained-button"
                          startIcon={<FileUploadIcon />}
                          component="span"
                        >
                          Upload
                        </Button>
                      </label>
                    </div>
                  </div>
                  {inputs.image.name == "" ? (
                    ""
                  ) : (
                    <p className="text-secondary">{inputs.image.name}</p>
                  )}
                </div>
                <div className="col-12 mt-3 d-flex align-items-start">
                  <div> Tags:</div>
                  <Box sx={{ marginTop: 0 }}>
                    {exerciseTags.map((tag) => (
                      <Chip
                        key={tag}
                        label={tag}
                        size="small"
                        // onDelete={handleDelete(tag)}
                        sx={{ marginX: 0.5, marginY: 0.5 }}
                      />
                    ))}
                    {mainMuscleTags.map((tag) => (
                      <Chip
                        key={tag}
                        label={tag}
                        size="small"
                        // onDelete={handleDelete(tag)}
                        sx={{ marginX: 0.5, marginY: 0.5 }}
                      />
                    ))}
                    {difficultyTags.map((tag) => (
                      <Chip
                        key={tag}
                        label={tag}
                        size="small"
                        // onDelete={handleDelete(tag)}
                        sx={{ marginX: 0.5, marginY: 0.5 }}
                      />
                    ))}
                    <Button
                      variant="text"
                      size="small"
                      sx={{
                        p: 0,
                        backgroundColor: "transparent",
                        ml: 2,
                        textTransform: "inherit",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      onClick={() => handleDetails()}
                    >
                      Add tags +
                    </Button>
                  </Box>
                </div>
                <div className="col-12 mt-5">
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-multiline-flexible"
                      label="Instructions*"
                      multiline
                      rows={6}
                      name="short_description"
                      value={inputs.short_description}
                      onChange={handleChange}
                    />
                    <FormHelperText>
                      Maximum limit 500 characters
                    </FormHelperText>
                  </FormControl>
                </div>
                {/* <div className="col-12 mt-5">
          <h4>instructions *</h4>
          <TinyEditor
            setDetailDescription={setDetailDescriptionCk}
            detailDescriptionCk={detailDescriptionCk}
          />
        </div> */}

                {/* <div className="text-end mt-4">
                <button
                  onClick={handleSubmit}
                  className="small-contained-button"
                >
                  Submit
                </button>
              </div> */}
              </div>
            </div>
          )}
        </DialogContent>

        <DialogActions>
          <Button className="model-button-hover" onClick={handleCloseDialog1}>
            close
          </Button>
          <Button className="model-button-hover" onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ToshowAddExercise;
