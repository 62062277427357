import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  Drawer,
  Button,
  TextField,
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Grid,
  Box,
  Stack,
  IconButton,
  Card,
  Radio,
  Chip,
} from "@mui/material";
import Rating from "@mui/material/Rating";

import { Icon } from "@iconify/react";

import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MultipleChoiceOptions from "./component/MultipleChoiceOptions";
import ImageView from "./component/ImageView";
import EditTemplate from "./component/EditUI";
import { addFormTemplate } from "src/DAL/FormTemplate/feedbackTemplates";
import RatingComponent from "./component/RaingStylesView";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import AvatarGallery from "./component/imageViewOnLength";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function AddFormTemplate() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const classes = useStyles();

  const [goNext, setGoNext] = useState(false);
  const [templateTitle, setTemplateTitle] = useState("");
  const [templateDescription, setTemplateDescription] = useState("");
  const [templateType, setTemplateType] = useState("");
  const [multipleChoiceOptions, setMultipleChoiceOptions] = useState(["", ""]);
  const [rating, setRating] = useState(4);
  const [maximumRating, setMaximumRating] = useState("");
  const [minimumRating, setMinimumRating] = useState("");
  const [questionsToSend, setQuestionsToSend] = useState([]);
  const [editShow, setEditShow] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteData, setDeleteData] = useState("");
  const [goToSaveTemplate, setGoToSaveTemplate] = useState(true);
  const [answerGeniusTitle, setAnswerGeniusTitle] = useState("");
  const [saveTemplate, setSaveTemplate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [inputs, setInputs] = React.useState({
    question_type: "multiple_choice",
    question_statement: "",
    question_type_required: false,
  });
  const answerGenius = [
    {
      title: "Agree-Disagree",
      data: ["Strongly agree", "Agree", "Disagree", "Strongly disagree"],
    },
    {
      title: "Exceeds-Meets",
      data: ["Exceeds", "Meets", "Need Improvement"],
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (inputs.question_type == "multiple_choice") {
      const updatedList = [...questionsToSend];
      updatedList.push({
        title: inputs.question_statement,
        required: inputs.question_type_required,
        order: 1,
        type: inputs.question_type,
        options: multipleChoiceOptions,
        options_count: multipleChoiceOptions.length,
      });
      editShow.push({ display: false });
      setQuestionsToSend(updatedList);
      setInputs((inputs) => {
        return {
          ...inputs,
          ["question_type"]: "multiple_choice",
          ["question_statement"]: "",
          ["question_type_required"]: false,
        };
      });
      setMultipleChoiceOptions(["", ""]);
    }
    if (inputs.question_type == "multiple_select") {
      const updatedList = [...questionsToSend];
      updatedList.push({
        title: inputs.question_statement,
        required: inputs.question_type_required,
        order: 1,
        type: inputs.question_type,
        options: multipleChoiceOptions,
        options_count: multipleChoiceOptions.length,
      });
      editShow.push({ display: false });
      setQuestionsToSend(updatedList);
      setInputs((inputs) => {
        return {
          ...inputs,
          ["question_type"]: "multiple_choice",
          ["question_statement"]: "",
          ["question_type_required"]: false,
        };
      });
      setMultipleChoiceOptions(["", ""]);
    }
    if (inputs.question_type == "image") {
      const updatedList = [...questionsToSend];
      updatedList.push({
        title: inputs.question_statement,
        required: inputs.question_type_required,
        order: 1,
        type: inputs.question_type,
        options: [minimumRating, maximumRating],
        options_count: parseInt(rating),
      });
      editShow.push({ display: false });
      setQuestionsToSend(updatedList);
      setInputs((inputs) => {
        return {
          ...inputs,
          ["question_type"]: "multiple_choice",
          ["question_statement"]: "",
          ["question_type_required"]: false,
        };
      });
      setMinimumRating("");
      setMaximumRating("");
    }
    if (inputs.question_type == "free_text") {
      const updatedList = [...questionsToSend];
      updatedList.push({
        title: inputs.question_statement,
        required: inputs.question_type_required,
        order: 1,
        type: inputs.question_type,
        options: [],
        options_count: 0,
      });
      editShow.push({ display: false });
      setQuestionsToSend(updatedList);
      setInputs((inputs) => {
        return {
          ...inputs,
          ["question_type"]: "multiple_choice",
          ["question_statement"]: "",
          ["question_type_required"]: false,
        };
      });
    }
    setAnswerGeniusTitle("");
    setSaveTemplate(true);
  };

  const handleNext = (e) => {
    e.preventDefault();
    setGoNext(true);
    // console.log("handleNext");
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleBack = () => {
    navigate(-1);
  };
  const handleChangeInMultipleChoice = (e, index) => {
    if (e.target.value[0] === " ") {
      return;
    }
    multipleChoiceOptions[index] = e.target.value;
    setMultipleChoiceOptions([...multipleChoiceOptions]);
  };
  const handelAddChoice = (index) => {
    let list = multipleChoiceOptions;
    list.splice(index + 1, 0, "");
    setMultipleChoiceOptions([...list]);
  };

  const handelDelChoice = (index) => {
    if (multipleChoiceOptions.length === 2) {
      return;
    }
    let list = multipleChoiceOptions;
    list.splice(index, 1);
    setMultipleChoiceOptions([...list]);
  };
  const handelDelQuestion = () => {
    let index = deleteData;
    // console.log(index, "kjsdjk");
    let list = questionsToSend;
    let list2 = editShow;
    list2.splice(index, 1);
    list.splice(index, 1);
    setEditShow([...list2]);
    setQuestionsToSend([...list]);

    setOpenDelete(false);
  };
  const handleAgreeDelete = (value) => {
    // console.log(value, "value");
    setDeleteData(value);
    setOpenDelete(true);
  };
  const handelEditQuestions = (index) => {
    editShow[index].display = true;
    setEditShow([...editShow]);
    setGoToSaveTemplate(false);
    setSaveTemplate(true);
  };
  const handelcloseQuestionsEntry = () => {
    setInputs((values) => ({
      ...values,
      ["question_type"]: "multiple_choice",
      ["question_statement"]: "",
    }));
    setMinimumRating("");
    setMaximumRating("");
    setMultipleChoiceOptions(["", ""]);
    setAnswerGeniusTitle("");
    // setGoToSaveTemplate(true);
    setRating("");
    setSaveTemplate(true);
  };
  const handleChangeAnswerGenius = (event) => {
    setAnswerGeniusTitle(event.target.value);
    answerGenius.filter((ans) => {
      if (ans.title == event.target.value) {
        // console.log(ans, "sdfklks");
        setMultipleChoiceOptions(ans.data);
      }
    });
  };

  const handelSendDataToApi = async (event) => {
    // console.log("dsflkjdsk");
    let postData = {
      title: templateTitle,
      receiver_type: templateType,
      description: templateDescription,
      questions: questionsToSend,
    };
    console.log(postData, "flkjlasfi");
    setIsLoading(true);
    try {
      const res = await addFormTemplate(postData);
      if (res.code === 200) {
        navigate(-1);

        // showAddedDataWithoutApi(res.company, companies, setCompanies);
        // setCount(count + 1);
        // handleClose();

        enqueueSnackbar(res.message, { variant: "success" });
      } else {
        setIsLoading(false);
        enqueueSnackbar(res.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  useEffect(() => {
    console.log(editShow.length, "sdkfs");
    let count = 0;
    editShow.map((item, index) => {
      if (item.display) {
        count--;
      } else {
        count++;
      }
    });
    if (count == editShow.length) {
      setGoToSaveTemplate(true);
    } else {
      setGoToSaveTemplate(false);
    }
  }, [editShow]);
  // useEffect(() => {
  //   console.log(questionsToSend, "dfdslfksdkfa", editShow);
  // }, [questionsToSend, editShow]);
  //===================================================================
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <Container sx={{ width: { sm: 950, md: 950 } }}>
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handelDelQuestion}
      />
      <Stack
        direction="column"
        alignItems="left"
        justifyContent="space-between"
        mb={4.5}
      >
        {!goNext ? (
          <div className="col-12">
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}
            >
              <Icon icon="akar-icons:arrow-left" />
            </IconButton>
          </div>
        ) : (
          <div className="col-12">
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => {
                setGoNext(false);
              }}
            >
              <Icon icon="akar-icons:arrow-left" />
            </IconButton>
          </div>
        )}
        <h2>Add Template</h2>
      </Stack>
      {!goNext ? (
        <form onSubmit={handleNext} autoComplete="off">
          <Box
            sx={{ width: { sm: "100%", md: "100%" }, margin: "auto" }}
            margin={0}
          >
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 0, sm: 0, md: 2 }}
              mt={1}
            >
              <Grid item md={6} xs={12}>
                <TextField
                  id="outlined-basic"
                  label="Title"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  // fullWidth
                  required
                  name="title"
                  value={templateTitle}
                  onChange={(e) => {
                    if (e.target.value[0] === " ") {
                      return;
                    }
                    setTemplateTitle(e.target.value);
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl sx={{ width: "100%" }} required>
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="active_status"
                    value={templateType}
                    label="Type"
                    onChange={(e) => setTemplateType(e.target.value)}
                  >
                    <MenuItem value="member">Member</MenuItem>
                    <MenuItem value="coach">Coach</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                {" "}
                <TextField
                  id="outlined-basic"
                  label="Description"
                  multiline
                  variant="outlined"
                  sx={{ width: "100%" }}
                  // fullWidth
                  rows={4}
                  required
                  name="description"
                  value={templateDescription}
                  onChange={(e) => {
                    if (e.target.value[0] === " ") {
                      return;
                    }
                    setTemplateDescription(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <div className="text-center">
              {" "}
              <Button
                variant="contained"
                startIcon={<Icon icon="eva:plus-fill" />}
                type="submit"
                className="small-contained-button  text-center"
                sx={{ width: "50%", mt: 2 }}
              >
                form Template
              </Button>
            </div>
          </Box>
        </form>
      ) : (
        <>
          <Stack
            direction="column"
            alignItems="center"
            spacing={1.5}
            justifyContent="space-between"
            mb={4.5}
          >
            <Typography sx={{ fontWeight: "500", fontSize: 16, mr: 1 }}>
              {templateTitle}
            </Typography>
            {questionsToSend &&
              questionsToSend.map((item, index) => {
                // console.log(item, "afsoiusd", editShow[index].display);
                return !editShow[index].display ? (
                  <Card
                    sx={{
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      mb: 1,
                      width: { sm: "100%", md: "100%" },
                      margin: "auto",
                      p: 2,
                      backgroundColor: "#f5f5f2",
                      position: "relative",
                    }}
                  >
                    {item.required && (
                      <Chip
                        label={"Required"}
                        variant="contained"
                        color={"error"}
                        sx={{
                          fontSize: "10px",
                          height: "auto",
                          width: "auto",
                          position: "absolute",
                          top: 8,
                          left: 16,
                        }}
                      />
                    )}
                    <Box sx={{ width: "100%", margin: "auto" }} margin={0}>
                      <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 0, sm: 0, md: 2 }}
                        mt={1}
                      >
                        <Grid item md={12} xs={12}>
                          <div className=" "></div>
                          <div className="d-flex align-items-center mb-2">
                            <div className="">
                              <Typography
                                sx={{ fontWeight: "900", fontSize: 16, mr: 1 }}
                              >
                                Q{index + 1}.
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: 14, mr: 1 }}
                              >
                                {item?.title}
                              </Typography>
                            </div>
                          </div>
                        </Grid>

                        {item?.type == "multiple_choice" ? (
                          <Grid item md={12} xs={12}>
                            {item?.options.map((item) => {
                              return (
                                <Grid item md={12}>
                                  <div className="d-flex align-items-center mb-2">
                                    <div>
                                      <Radio disabled />
                                    </div>
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        fontSize: 14,
                                        mr: 1,
                                      }}
                                    >
                                      {item}
                                    </Typography>
                                  </div>
                                </Grid>
                              );
                            })}
                            <div className="w-100 mt-2 d-flex justify-content-end">
                              <Button
                                variant="outlined"
                                sx={{ mr: 1 }}
                                onClick={() => {
                                  handelEditQuestions(index);
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={() => {
                                  handleAgreeDelete(index);
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          </Grid>
                        ) : item?.type == "multiple_select" ? (
                          <Grid item md={12} xs={12}>
                            {item?.options.map((item) => {
                              return (
                                <Grid item md={12}>
                                  <div className="d-flex align-items-center mb-2">
                                    <div>
                                      <Radio disabled />
                                    </div>
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        fontSize: 14,
                                        mr: 1,
                                      }}
                                    >
                                      {item}
                                    </Typography>
                                  </div>
                                </Grid>
                              );
                            })}
                            <div className="w-100 mt-2 d-flex justify-content-end">
                              <Button
                                variant="outlined"
                                sx={{ mr: 1 }}
                                onClick={() => {
                                  handelEditQuestions(index);
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={() => {
                                  handleAgreeDelete(index);
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          </Grid>
                        ) : item?.type == "image" ? (
                          <Grid item md={12} xs={12}>
                            <div className="d-flex align-items-center mb-2">
                              <div className="">
                                <Typography
                                  sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    mr: 1,
                                  }}
                                >
                                  {item?.options[0]}
                                </Typography>
                              </div>
                              {/* <div>
                                <Rating
                                  name="read-only"
                                  value={0}
                                  max={item?.options_count}
                                  readOnly
                                />
                              </div> */}
                              {/* <RatingComponent
                                rating={0}
                                onRatingChange={() => {}}
                                details={true}
                                maxLength={item?.options_count}
                              /> */}
                              <AvatarGallery length={item?.options_count} />
                              <div className="">
                                <Typography
                                  sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    ml: 1,
                                  }}
                                >
                                  {item?.options[1]}
                                </Typography>
                              </div>
                            </div>
                            <div className="w-100 mt-2 d-flex justify-content-end">
                              <Button
                                variant="outlined"
                                sx={{ mr: 1 }}
                                onClick={() => {
                                  handelEditQuestions(index);
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={() => {
                                  handleAgreeDelete(index);
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          </Grid>
                        ) : (
                          item?.type == "free_text" && (
                            <Grid item md={12} xs={12}>
                              <TextField
                                id="outlined-multiline-static"
                                label="Answer"
                                multiline
                                disabled
                                rows={4}
                                sx={{ width: "100%" }}
                              />
                              <div className="w-100 mt-2 d-flex justify-content-end">
                                <Button
                                  variant="outlined"
                                  sx={{ mr: 1 }}
                                  onClick={() => {
                                    handelEditQuestions(index);
                                  }}
                                >
                                  Edit
                                </Button>{" "}
                                <Button
                                  variant="outlined"
                                  color="error"
                                  onClick={() => {
                                    handleAgreeDelete(index);
                                  }}
                                >
                                  Delete
                                </Button>
                              </div>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Box>
                  </Card>
                ) : (
                  <EditTemplate
                    questionsToSend={questionsToSend}
                    index={index}
                    answerGenius={answerGenius}
                    setQuestionsToSend={setQuestionsToSend}
                    editShow={editShow}
                    setGoToSaveTemplate={setGoToSaveTemplate}
                    setEditShow={setEditShow}
                  />
                );
              })}
            {!saveTemplate ? (
              <Card
                sx={{
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  mb: 1,
                  width: { sm: "100%", md: "100%" },
                  margin: "auto",
                  p: 2,
                }}
              >
                <form onSubmit={handleSubmit} autoComplete="off">
                  <Box sx={{ width: "100%", margin: "auto" }} margin={0}>
                    <Grid
                      container
                      rowSpacing={2}
                      columnSpacing={{ xs: 0, sm: 0, md: 2 }}
                      mt={1}
                    >
                      <Grid item md={12} xs={12}>
                        <TextField
                          id="outlined-basic"
                          label="Question Statement"
                          variant="outlined"
                          sx={{ width: "100%" }}
                          // fullWidth
                          required
                          name="question_statement"
                          value={inputs.question_statement}
                          onChange={(event) => {
                            if (event.target.value[0] === " ") {
                              return;
                            }
                            setInputs((values) => ({
                              ...values,
                              [event.target.name]: event.target.value,
                            }));
                          }}
                        />
                      </Grid>
                      <Grid item md={4.5} xs={12}>
                        <FormControl sx={{ mb: 0, width: "100%" }} required>
                          <InputLabel id="demo-simple-selec-label" size="small">
                            Question Type
                          </InputLabel>
                          <Select
                            size="small"
                            required
                            labelId="demo-simple-selec-label"
                            id="demo-simple-select"
                            name="question_type"
                            value={inputs?.question_type}
                            label="Question Type"
                            onChange={handleChange}
                          >
                            <MenuItem value="multiple_choice">
                              Multiple Choice (single select)
                            </MenuItem>
                            <MenuItem value="multiple_select">
                              Multiple Choice (Multiple select)
                            </MenuItem>
                            <MenuItem value="free_text">Text</MenuItem>
                            <MenuItem value="image">Image</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <FormControl sx={{ mb: 0, width: "100%" }} required>
                          <InputLabel
                            id="demo-simple-selec-label1"
                            size="small"
                          >
                            Is it Requird?
                          </InputLabel>
                          <Select
                            size="small"
                            required
                            labelId="demo-simple-selec-label1"
                            id="demo-simple-select"
                            name="question_type_required"
                            value={inputs?.question_type_required}
                            label="Is it Requird"
                            onChange={handleChange}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={12} xs={12} sx={{ mb: 1 }}>
                        <hr className="mb-0 " />
                      </Grid>
                      {inputs?.question_type == "multiple_choice" ? (
                        <MultipleChoiceOptions
                          answerGeniusTitle={answerGeniusTitle}
                          handleChangeAnswerGenius={handleChangeAnswerGenius}
                          answerGenius={answerGenius}
                          multipleChoiceOptions={multipleChoiceOptions}
                          handleChangeInMultipleChoice={
                            handleChangeInMultipleChoice
                          }
                          handelAddChoice={handelAddChoice}
                          handelDelChoice={handelDelChoice}
                        />
                      ) : inputs?.question_type == "multiple_select" ? (
                        <MultipleChoiceOptions
                          answerGeniusTitle={answerGeniusTitle}
                          handleChangeAnswerGenius={handleChangeAnswerGenius}
                          answerGenius={answerGenius}
                          multipleChoiceOptions={multipleChoiceOptions}
                          handleChangeInMultipleChoice={
                            handleChangeInMultipleChoice
                          }
                          handelAddChoice={handelAddChoice}
                          handelDelChoice={handelDelChoice}
                        />
                      ) : inputs?.question_type == "image" ? (
                        <ImageView rating={rating} setRating={setRating} />
                      ) : (
                        <Grid item md={12} xs={12}>
                          <TextField
                            id="outlined-multiline-static"
                            label="Answer"
                            multiline
                            disabled
                            rows={6}
                            sx={{ width: "100%" }}
                          />
                        </Grid>
                      )}

                      <Grid item md={12} xs={12}>
                        <hr className="m-0 " />
                      </Grid>
                    </Grid>

                    <div className="col-12 mt-3 text-end">
                      <Button
                        sx={{
                          mr: 1,
                        }}
                        variant="contained"
                        type="submit"
                        startIcon={<Icon icon="eva:plus-fill" />}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        startIcon={<Icon icon="ic:twotone-close-fullscreen" />}
                        onClick={() => handelcloseQuestionsEntry()}
                      >
                        Close
                      </Button>
                    </div>
                  </Box>
                </form>
              </Card>
            ) : (
              goToSaveTemplate && (
                <div className="w-100 mt-3 text-end">
                  <Button
                    variant="contained"
                    onClick={() => setSaveTemplate(false)}
                    sx={{
                      mr: 1,
                    }}
                    startIcon={<Icon icon="eva:plus-fill" />}
                  >
                    Add Question
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<Icon icon="eva:plus-fill" />}
                    onClick={() => {
                      handelSendDataToApi();
                    }}
                  >
                    Save Template
                  </Button>
                </div>
              )
            )}
          </Stack>
        </>
      )}
    </Container>
  );
}
