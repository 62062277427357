import * as React from "react";
import Box from "@mui/material/Box";
import { useState, useMemo } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
//import Editor from "../../components/Editor/Editor";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
// import { VaultListing } from "src/DAL/Vault/Vault";
import Autocomplete from "@mui/material/Autocomplete";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Stack from "@mui/material/Stack";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import { TimeZones } from "src/utils/constant";
import countryList from "react-select-country-list";

import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import {
  IconButton,
  Divider,
  CircularProgress,
  Checkbox,
  InputAdornment,
} from "@mui/material";
// import { AddProgram } from "src/DAL/Programmes/Programmes";
import { object } from "prop-types";
import { menuList } from "src/DAL/Menu/Menu";
import { AddProgrammeApi } from "src/DAL/Programme/Programme";
import { countries } from "src/utils/country";
import {
  AddMemberApi,
  EditMemberApi,
  memberAllDetailApi,
} from "src/DAL/member/member";
import { coachListing } from "src/DAL/coach/coach";
import { s3baseUrl } from "src/config/config";
import { height } from "@mui/system";
import ActiveLastBreadcrumb from "src/components/ActivePaths";

// import { get_root_value } from "src/utils/domUtils";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Input = styled("input")({
  display: "none",
});

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

export default function EditMember() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [iswaiting, setiswaiting] = useState(false);
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [memberData, setMemberData] = useState();

  const [file, setProfileImage] = React.useState();
  const [audioFile, setAudio] = React.useState();
  const [groups, setGroups] = React.useState([]);
  const [country, setCountry] = React.useState("");
  const [menuLists, setMenuList] = React.useState([]);
  const [date, setDate] = React.useState(null);
  const [programName, setProgramName] = React.useState("");
  const [vaultName, setVaultName] = React.useState("");
  const [navItems, setNavitems] = React.useState([]);
  const [coachValue, setCoachValue] = React.useState([]);
  const [oldImage, setOldImage] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [selectCountry, setSelectCountry] = useState("");
  const [imageStatus, setImageStatus] = useState(false);
  const [timeZoneValue, setTimeZoneValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [checked, setChecked] = React.useState();
  const [postalCountry, setPostalCountry] = React.useState("");
  const [memberVdotData, setMemberVdotDataData] = useState({
    time_interval: 0,
  });
  const [intervalTime, setIntervalTime] = useState(0);
  const [inputs, setInputs] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    contact_number: "",
    goal_status: "",
    member_status: "",
    street: "",
    city: "",
    zip_code: "",
    state: "",
    coach_id: "",
    first_payment_date: new Date(),
    member_biography: "",
    image: {},
    short_description: "",
    time_zone: "",
    coins_count: "",
    referral_name: "",
    is_membership_purchase: "",
    is_executive: "",
    street2: "",
    postalStreet: "",
    postalStreet2: "",
    postalZip_code: "",
    postalState: "",
    postalCity: "",
    distance: "",
  });
  let breadCrumbMenu = [
    {
      title: "Members",
      navigation: `/member`,
      active: false,
    },

    {
      title: "Edit Member",
      navigation: null,
      active: true,
    },
  ];
  const changeHandlerCountry = (value) => {
    //console.log(value.target.value, "asdfghjkl");
    setSelectCountry(value.target.value);
  };
  const getNavItemList = async () => {
    const result = await menuList();
    if (result.code === 200) {
      setMenuList(result.menu);
      //   setVaultName(result.vault_category[0].vault_slug);
    }
  };
  console.log(state, "statestate");
  const memberDetails = async () => {
    const result = await memberAllDetailApi(state?._id);

    if (result.code == 200) {
      setIsLoading(false);

      console.log(result, "sdljkfjklsdjklfja");
      let obj = {};
      if (result?.member?.is_postal_address_same) {
        let beforePars =
          result?.member?.postal_addess_info == undefined
            ? ""
            : JSON.stringify(result?.member?.postal_addess_info);

        obj = beforePars == "" ? "" : JSON.parse(beforePars);
      } else {
        let strigifyObj = JSON.stringify(result?.member?.postal_addess_info);
        obj = result?.member?.postal_addess_info && JSON.parse(strigifyObj);
      }

      let postalInfo = obj;

      console.log(postalInfo, "ok info");
      setChecked(result?.member?.is_postal_address_same);
      let findCountry = countries.find((item) => {
        if (String(item.code) == String(result?.member?.country)) {
          return item;
        }
      });
      let findPostalCountry = countries.find((item) => {
        if (String(item.code) == String(postalInfo?.country)) {
          return item;
        }
      });
      console.log(findCountry, "found");
      // setDate(
      //   first_payment_date == "" || first_payment_date == "Invalid date"
      //     ? null
      //     : first_payment_date
      // );
      setOldImage(result?.member?.profile_image);
      setSelectCountry(result?.member?.country);
      setCountry(findCountry);
      setPostalCountry(findPostalCountry);
      setTimeZoneValue(result?.member?.time_zone);

      setInputs((inputs) => ({
        ...inputs,
        ["first_name"]: result?.member?.first_name,
        ["last_name"]: result?.member?.last_name,
        ["last_name"]: result?.member?.last_name,
        ["email"]: result?.member?.email,
        // ["password"]:result?.member?. password,
        ["contact_number"]: result?.member?.contact_number,
        ["member_status"]: result?.member?.status,
        ["goal_status"]: result?.member?.goal,
        ["street"]: result?.member?.street,
        ["city"]: result?.member?.city,
        ["state"]: result?.member?.state,
        ["zip_code"]: result?.member?.zip_code,
        ["time_zone"]: result?.member?.time_zone,
        ["coach_id"]: result?.member?.coach,
        ["short_description"]: result?.member?.member_biography,
        ["short_description"]: result?.member?.member_biography,
        ["distance"]: result?.member?.vdot?.race_type,
        // ["memberShipPurchase"]: state?.object?.is_membership_purchase,
        // ["coins_count"]: coinsCount,
        // ["referral_name"]: state?.object?.refferal_name,
        // ["is_membership_purchase"]: state?.object?.is_membership_purchase,
        // ["is_executive"]: state?.object?.is_executive,
        // ["street2"]: state?.street2,
        // ["postalCity"]: postalInfo?.city,
        // ["postalState"]: postalInfo?.state,
        // ["postalStreet"]: postalInfo?.street,
        // ["postalStreet2"]: postalInfo?.street2,
        // ["postalZip_code"]: postalInfo?.zip_code,
      }));
      setMemberVdotDataData((inputs) => ({
        ...inputs,
        ["time_interval"]: result?.member?.vdot?.time_taken,
      }));
      setMemberData(result?.member);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const consultantListData = async () => {
    setIsLoading(true);
    let result = await coachListing();
    //console.log(result, "lololololo");
    if (result.code == 200) {
      //console.log(result.consultant_list, "result.consultant");
      setCoachValue(result.coach_list);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    }
    //console.log(result, "okokoko");
  };

  const handleChangeNavItem = (event) => {
    const {
      target: { value },
    } = event;
    setNavitems(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setImageStatus(true);
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };
  const handleChangeIsPostalAddress = (event) => {
    setChecked(event.target.checked);
  };
  const audioFileChange = (e) => {
    //console.log("clicked");
    setAudio(e.target.files[0]);
  };
  const handleProgramName = (data) => {
    setProgramName(data.program_slug);
  };

  const handleVaultName = (data) => {
    setVaultName(data.vault_slug);
  };
  const handldeDeleteAudio = () => {
    setAudio();
    //console.log("reset State");
  };
  const handleChangeMembers = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    const membersName = typeof value === "string" ? value.split(",") : value;
    setInputs((input) => ({
      ...input,
      ["members"]: membersName,
    }));
  };
  const handleChangeGroups = (event) => {
    const {
      target: { value },
    } = event;
    setGroups(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    const groupName = typeof value === "string" ? value.split(",") : value;
    setInputs((input) => ({
      ...input,
      ["groups"]: groupName,
    }));
  };
  //console.log(inputs.time_zone, "inputs.time_zone");

  const handleChangeDate = (newValue) => {
    let todayDate = moment(newValue).format("YYYY-MM-DD");
    let dateType = todayDate.toString();
    //console.log(typeof dateType, "dateType");
    setDate(newValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (country == "" || country == undefined) {
      enqueueSnackbar("Country is required", {
        variant: "error",
      });
    } else {
      if (inputs.days < 0) {
        enqueueSnackbar("No of days must be positive number ", {
          variant: "error",
        });
      } else {
        let VDOT = {
          race_type: inputs?.distance,
          time_taken: memberVdotData?.time_interval,
        };
        const formData = new FormData();
        formData.append("first_name", inputs.first_name);
        formData.append("last_name", inputs.last_name);
        formData.append("member_biography", inputs.short_description);
        formData.append("email", inputs.email);
        // formData.append("password", inputs.password);
        formData.append("contact_number", inputs.contact_number);
        formData.append("status", inputs.member_status);
        formData.append("street", inputs.street);
        // formData.append("street2", inputs.street2);
        formData.append("city", inputs.city);
        formData.append("zip_code", inputs.zip_code);
        formData.append("state", inputs.state);
        formData.append("country", country?.code);

        formData.append("vdot", JSON.stringify(VDOT));

        formData.append(
          "coach_id",
          inputs.coach_id == undefined ? "" : inputs.coach_id
        );
        // formData.append(
        //   "first_payment_date",
        //   date ? moment(date).format("YYYY-MM-DD") : ""
        // );
        // formData.append("goal_statement_status", inputs.goal_status);
        // formData.append("coins_count", inputs.coins_count);
        formData.append("time_zone", timeZoneValue);
        // formData.append("refferal_name", inputs.referral_name);
        // formData.append("is_membership_purchase", inputs.is_membership_purchase);
        // formData.append("is_executive", inputs.is_executive);
        if (imageStatus == true) {
          formData.append("profile_image", inputs.image);
        }

        console.log(...formData, "form data value");

        setIsLoading(true);
        const result = await EditMemberApi(formData, state.id);
        if (result.code === 200) {
          //console.log(result, "result");
          enqueueSnackbar(result.message, { variant: "success" });
          navigate(-1);
        } else {
          //console.log(result, "error case");
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
        }
      }
    }
  };
  //console.log(inputs, "inputs");
  const handleChangeProgram = (e) => {
    setProgramName(e.target.value);
  };
  const handleChangeVault = (event) => {
    setVaultName(event.target.value);
  };
  const handleHoursChange = (e) => {
    const hours = parseInt(e.target.value) || 0;
    const minutes = Math.floor((memberVdotData.time_interval % 3600) / 60);
    const seconds = memberVdotData.time_interval % 60;
    updateIntervalTime(hours * 3600 + minutes * 60 + seconds);
  };
  const handleMinutesChange = (e) => {
    let minutes = parseInt(e.target.value) || 0;
    minutes = Math.min(Math.max(minutes, 0), 59);

    // Calculate hours separately to prevent resetting
    const currentHours = Math.floor(memberVdotData.time_interval / 3600);
    const seconds = memberVdotData.time_interval % 60;

    // Update total seconds with new minutes and current hours
    updateIntervalTime(currentHours * 3600 + minutes * 60 + seconds);
  };

  const handleSecondsChange = (e) => {
    let seconds = parseInt(e.target.value) || 0;
    seconds = Math.min(Math.max(seconds, 0), 59); // Ensure the value is between 0 and 60
    const minutes = Math.floor(memberVdotData.time_interval / 60);
    updateIntervalTime(minutes * 60 + seconds);
  };

  // Function to update intervalTime state
  const updateIntervalTime = (totalSeconds) => {
    setIntervalTime(totalSeconds);

    setMemberVdotDataData((prevState) => {
      return {
        ...prevState,
        ["time_interval"]: totalSeconds,
      };
    });
  };

  const formatWithLeadingZeros = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  console.log(
    memberData?.postal_addess_info,
    "memberData?.postal_addess_info",
    state
  );
  React.useEffect(() => {
    consultantListData();
    memberDetails();
  }, []);

  React.useEffect(() => {}, [memberData]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  // console.log(timeZoneValue, "timeZoneValue");

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          {/* <ActiveLastBreadcrumb breadCrumbMenu={breadCrumbMenu} /> */}
          {/* <button
            className="small-contained-button float-end mt-1"
            onClick={() => navigate(`/programmes/addreview/`)}
          >
            Add Programme Review
          </button> */}
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <h2>Edit Member</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="First Name"
            variant="outlined"
            fullWidth
            required
            name="first_name"
            value={inputs.first_name}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Last Name"
            variant="outlined"
            fullWidth
            name="last_name"
            value={inputs.last_name}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            defaultValue="Hello World"
            fullWidth
            required
            name="email"
            value={inputs.email}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Contact Number"
            variant="outlined"
            fullWidth
            name="contact_number"
            value={inputs.contact_number}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Member Status *
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="member_status"
              value={inputs.member_status}
              label="Member Status *"
              onChange={handleChange}
            >
              <MenuItem value="true">Active</MenuItem>
              <MenuItem value="false">Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="row w-100 div-style ms-0 pt-0">
            <div className="col-5">
              <p className="">Upload Image </p>
              <FormHelperText className="pt-0">
                Image Size(500 X 500) ("JPG", "JPEG", "PNG","WEBP")
              </FormHelperText>
            </div>
            <div className="col-2">
              {file ? (
                <img className="image-border" src={file} height="50" />
              ) : (
                oldImage && (
                  <img
                    className="image-border"
                    src={s3baseUrl + oldImage}
                    height="50"
                  ></img>
                )
              )}
            </div>
            <div className="col-5 text-end pt-2">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  name="image"
                  onChange={fileChangedHandler}
                />

                <Button
                  className="small-contained-button"
                  startIcon={<FileUploadIcon />}
                  component="span"
                >
                  Upload
                </Button>
              </label>
            </div>
          </div>
          {inputs.image.name == "" ? (
            ""
          ) : (
            <p className="text-secondary">{inputs.image.name}</p>
          )}
        </div>

        <div className="col-12">
          <h3>Address Details</h3>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <Autocomplete
            id="country-select-demo"
            // sx={{ width: 300 }}
            options={countries}
            autoHighlight
            value={country}
            onChange={(event, newValue) => {
              setCountry(newValue);
            }}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  className="image-border"
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.label} ({option.code}) +{option.phone}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a country *"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="City"
            variant="outlined"
            fullWidth
            name="city"
            required
            value={inputs.city}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="State"
            variant="outlined"
            fullWidth
            required
            name="state"
            value={inputs.state}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Street Address 1"
            variant="outlined"
            fullWidth
            required
            name="street"
            value={inputs.street}
            onChange={handleChange}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <TextField
            id="outlined-basic"
            label="Zip Code"
            variant="outlined"
            fullWidth
            required
            name="zip_code"
            value={inputs.zip_code}
            onChange={handleChange}
          />
        </div>

        <Divider className="mt-2" />
        <h3 className="mt-3">Configurations</h3>
        {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date of first payment"
              inputFormat="dd/MM/yyyy"
              value={date}
              onChange={handleChangeDate}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </div> */}
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Coach</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={inputs.coach_id}
              name="coach_id"
              label="Coach"
              onChange={handleChange}
            >
              {coachValue.map((consultant) => {
                return (
                  <MenuItem value={consultant._id}>
                    {consultant.first_name + " " + consultant.last_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <FormControl fullWidth>
            <InputLabel required id="demo-simple-select-label">
              Goal Statement Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="goal_status"
              value={inputs.goal_status}
              label="Goal Statement Status"
              onChange={handleChange}
            >
              <MenuItem value="false">Lock</MenuItem>
              <MenuItem value="true">Unlock</MenuItem>
            </Select>
          </FormControl>
        </div> */}
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <Autocomplete
            value={timeZoneValue}
            onChange={(event, newValue) => {
              setTimeZoneValue(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="controllable-states-demo"
            options={TimeZones}
            renderInput={(params) => (
              <TextField {...params} label="Time Zone *" />
            )}
          />
        </div>
        <Divider className="mt-2" />
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="mt-4">
            <h3>VDOT</h3>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="mt-4 select-box">
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-label">Distance</InputLabel>
              <Select
                className="svg-color"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={inputs.distance}
                name="distance"
                label="Distance"
                MenuProps={{
                  classes: {
                    paper: classes.paper,
                  },
                }}
                onChange={handleChange}
              >
                <MenuItem value="5km">5 (km)</MenuItem>
                <MenuItem value="10km">10 (km)</MenuItem>
                <MenuItem value="15km">15 (km)</MenuItem>
                <MenuItem value="half_marathon">Half Marathon</MenuItem>
                <MenuItem value="marathon">Marathon</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="mt-4">
            <TextField
              id="outlined-basic"
              variant="outlined"
              label="Duration "
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                // style: {
                //   fontSize: "13px",
                //   paddingLeft: "0px",
                //   paddingRight: "0px",
                //   justifyContent: "center",
                // },
                readOnly: true,
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <TextField
                      id="outlined-basic-hours"
                      type="number"
                      placeholder="Hrs"
                      value={formatWithLeadingZeros(
                        Math.floor(memberVdotData.time_interval / 3600)
                      )}
                      onChange={handleHoursChange}
                      InputProps={
                        {
                          // style: {
                          //   fontSize: "19px",
                          //   fontWeight: "500",
                          // },
                        }
                      }
                      sx={{
                        "& fieldset": {
                          border: "none",
                        },
                        "& input": {
                          textAlign: "center",
                          padding: "0px",
                        },
                        width: "20%",
                      }}
                    />
                    <div className="fs-4 fw-semibold ">:</div>

                    <TextField
                      id="outlined-basic-minutes"
                      type="number"
                      placeholder="Min"
                      value={formatWithLeadingZeros(
                        Math.floor((memberVdotData.time_interval % 3600) / 60)
                      )}
                      onChange={handleMinutesChange}
                      InputProps={
                        {
                          // style: {
                          //   fontSize: "19px",
                          //   fontWeight: "500",
                          // },
                        }
                      }
                      sx={{
                        "& fieldset": {
                          border: "none",
                        },
                        "& input": {
                          textAlign: "center",
                          padding: "0px",
                        },
                        width: "20%",
                      }}
                    />
                    <div className="fs-4 fw-semibold ">:</div>

                    <TextField
                      id="outlined-basic-seconds"
                      type="number"
                      placeholder="Sec"
                      value={formatWithLeadingZeros(
                        memberVdotData.time_interval % 60
                      )}
                      onChange={handleSecondsChange}
                      InputProps={
                        {
                          // style: {
                          //   fontSize: "19px",
                          //   fontWeight: "500",
                          // },
                        }
                      }
                      sx={{
                        "& fieldset": {
                          border: "none",
                        },
                        "& input": {
                          textAlign: "center",
                          padding: "0px",
                        },
                        width: "20%",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                borderRadius: "5px",
                fontSize: "13px",
                width: "100%", // Adjust the width as needed
              }}
            />
          </div>
        </div>
        <div className="col-12 mt-5">
          <FormControl fullWidth>
            <TextField
              id="outlined-multiline-flexible"
              label="Short Description "
              multiline
              rows={6}
              name="short_description"
              value={inputs.short_description}
              onChange={handleChange}
            />
            <FormHelperText>
              Member Biography (Maximum limit 500 characters)
            </FormHelperText>
          </FormControl>
        </div>

        <div className="text-end mt-4">
          <button onClick={handleSubmit} className="small-contained-button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
