import { CircularProgress, FormControl, TextField } from "@mui/material";

import { styled } from "@mui/material/styles";
import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import { makeStyles } from "@mui/styles";

import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { ExerciseListingApi } from "src/DAL/exercise/exercise";

import faker from "faker";

import ToshowExercise from "./component/modalToshowExercise";

import moment from "moment";
import {
  add_program_workout_api,
  update_program_workout_api,
} from "src/DAL/programmeWorkout/programmeWorkout";
import WorkoutExercisesUI from "../Workout/WorkoutExercisesUI";
import WorkoutExercisesUILogedClient from "./WorkoutExercisesUILogedClient";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  customInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed

      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },

  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      backgroundColor: "#ebebeb",
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
}));
const finalSpaceCharacters = [
  {
    id: "gary",
    name: "Gary Goodspeed",
    thumb: "/images/gary.png",
  },
  {
    id: "cato",
    name: "Little Cato",
    thumb: "/images/cato.png",
  },
  {
    id: "kvn",
    name: "KVN",
    thumb: "/images/kvn.png",
  },
  {
    id: "mooncake",
    name: "Mooncake",
    thumb: "/images/mooncake.png",
  },
  {
    id: "quinn",
    name: "Quinn Ergon",
    thumb: "/images/quinn.png",
  },
];

const AddWorkoutCalender = forwardRef((props, ref) => {
  // console.log(addArray, setaddArray, "dflkadsjjkfahds");
  const [characters, updateCharacters] = useState(finalSpaceCharacters);
  const { state } = useLocation();
  const myInputRef = useRef(null);
  const [serchText, setSerchText] = useState("");
  const [addArray, setaddArray] = useState([
    {
      exercise: "",

      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          distance: "",
          distanceType: "m",
          timeType: "sec",
          restType: "sec",
          reps: "",
          rest: "",
          tempo: "",
        },
      ],
      parameters: [
        "Duration/Pace",
        "Reps",
        "Weight",
        "Distance/Calories",
        "Rest",
        "Tempo",
      ],
      time_interval: 60,
      rounds: 10,
      type: "general",
      description: "",

      superSet: [],
      emomSets: [[]],
      superset_sets: "",
    },
  ]);

  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const [exercise, setExercise] = useState([]);
  const [workoutTitle, setWorkoutTitle] = useState("");

  const [detailsData, setDetailsData] = useState("");
  const [openDetails, setOpenDetails] = useState(false);

  const classes = useStyles();

  const [isFocused, setIsFocused] = useState(false);
  const [isFocused1, setIsFocused1] = useState(false);
  const [workoutDescription, setWorkoutDescription] = useState("");
  const isInitialRender = useRef(true);
  const handleAdd = () => {
    let list = addArray;
    list.splice(addArray.length, 0, {
      exercise: "",

      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          distance: "",
          distanceType: "m",
          timeType: "sec",
          restType: "sec",
          reps: "",
          rest: "",
          tempo: "",
        },
      ],
      parameters: [
        "Duration/Pace",
        "Reps",
        "Weight",
        "Distance/Calories",
        "Rest",
        "Tempo",
      ],
      time_interval: 60,
      rounds: 10,
      type: "general",
      description: "",

      superSet: [],
      emomSets: [[]],
      superset_sets: "",
    });

    setaddArray([...list]);
    props?.setIsLoading(false);
  };
  useImperativeHandle(ref, () => ({
    childButtonClick() {
      handleSubmit();
      console.log("Button clicked in child component");
      // Add your logic or call other functions here
    },
    childButtonClick2() {
      console.log("Button2 clicked in child component");

      handleSubmit().then((formDataObject) => {
        if (formDataObject) props.setWorkoutAsTem(formDataObject);
      });
    },
    childButtonClickUpdate() {
      console.log("Button Update clicked in child component");
      handleSubmitUpdate();
    },
  }));

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleFocus1 = () => {
    setIsFocused1(true);
  };

  const handleBlur1 = () => {
    setIsFocused1(false);
  };

  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);
    setaddArray(delValue);
  };

  // console.log(level, "levellevellevel");

  function handleOnDragEnd(result) {
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setaddArray(items);
  }

  const getExerciseList = async () => {
    let result = await ExerciseListingApi(0, 100, serchText);
    if (result.code == 200) {
      setIsLoading(false);
      props.setIsLoading(false);

      setExercise(result?.exercises);
    }
  };
  const handleSubmit = async (e) => {
    e?.preventDefault();
    setIsLoading(true);
    props.setIsLoading(true);

    let isError = false;
    let exerciesArray = addArray.map((item, mainIn) => {
      console.log(item, "sdkfljkajs");
      if (
        item.type != "emom" &&
        item.type != "amrap" &&
        item.type != "something_else" &&
        !item.exercise?._id &&
        item.superSet.length == 0
      ) {
        enqueueSnackbar("please select an exercise", { variant: "error" });
        isError = true;
      }
      if (item.type == "something_else" && !item?.title) {
        enqueueSnackbar("please add name", {
          variant: "error",
        });
        isError = true;
      }
      let data = {
        exercise_info: {
          _id: item?.superSet[0]?.exercise?._id
            ? item?.superSet[0]?.exercise?._id
            : item.exercise?._id,
          title: item?.superSet[0]?.exercise?.title
            ? item?.superSet[0]?.exercise?.title
            : item.exercise?.title,
          image: item?.superSet[0]?.exercise?.image
            ? item?.superSet[0]?.exercise?.image
            : item.exercise?.image,
        },
        title: item?.title,
        description: item.description,
        parameters: item.parameters,
      };
      if (item?._id) {
        data["_id"] = item?._id;
      }
      if (item.superset_sets) {
        data["superset_sets"] = item.superset_sets;
      }
      if (item.rounds) {
        data["emom_rounds"] = item.rounds;
      }
      if (item.type) {
        data["exercise_type"] = item.type;
      }
      if (item.time_interval) {
        data["interval_duration"] = item.time_interval;
      }
      if (item.set) {
        data["sets"] = [];
        if (item.set.length > 0) {
          data["sets"] = item.set.map((item) => {
            console.log("dskjfjksjdklfja", item);
            let data = {};
            if (item.reps) {
              data["reps"] = item.reps;
            }
            if (item.rest) {
              data["rest"] = item.rest;
            }
            if (item.weight) {
              data["weight"] = item.weight;
            }
            if (item.time) {
              data["duration"] = item.time;
            }
            if (item.weightType) {
              data["weight_unit"] = item.weightType;
            }

            if (item.distance) {
              data["distance"] = item.distance;
            }
            if (item.distanceType) {
              data["distance_unit"] = item.distanceType;
            }
            if (item.timeType) {
              data["duration_unit"] = item.timeType;
            }
            if (item.restType) {
              data["rest_unit"] = item.restType;
            }
            if (item.tempo) {
              data["tempo"] = item.tempo;
            }
            return data;
          });
        }
      }
      if (item.type == "emom" || item.type == "amrap") {
        data["emom_amrap"] = [];
        if (item.emomSets.length > 0) {
          item.emomSets.map((item1, ind) => {
            console.log(item1, "sjdkfksjdka");
            if (item1.length > 0) {
              data["emom_amrap"][ind] = item1.map((item2) => {
                if (isError) {
                  return;
                }
                if (!item2.exercise?._id) {
                  if (item.type == "emom") {
                    enqueueSnackbar(
                      `please select an exercise in EMOM,  EMOM placed on ${
                        mainIn + 1
                      } position. `,
                      {
                        variant: "error",
                      }
                    );
                  }
                  if (item.type == "amrap") {
                    enqueueSnackbar(
                      `please select an exercise in AMRAP, AMRAP placed on ${
                        mainIn + 1
                      } position. `,
                      {
                        variant: "error",
                      }
                    );
                  }

                  isError = true;

                  return;
                }
                let data = {
                  exercise_info: {
                    _id: item2.exercise?._id,
                    title: item2.exercise?.title,
                    image: item2.exercise?.image,
                  },
                  title: item2.exercise?.title,
                  description: item2.description,
                  parameters: item2.parameters,
                };
                if (item2.set) {
                  data["sets"] = [];
                  if (item2.set.length > 0) {
                    data["sets"] = item2.set.map((item3) => {
                      let data = {};
                      if (item3.reps) {
                        data["reps"] = item3.reps;
                      }
                      if (item3.rest) {
                        data["rest"] = item3.rest;
                      }
                      if (item3.weight) {
                        data["weight"] = item3.weight;
                      }
                      if (item3.time) {
                        data["duration"] = item3.time;
                      }
                      if (item3.weightType) {
                        data["weight_unit"] = item3.weightType;
                      }
                      if (item3.distance) {
                        data["distance"] = item3.distance;
                      }
                      if (item3.distanceType) {
                        data["distance_unit"] = item3.distanceType;
                      }
                      if (item3.timeType) {
                        data["duration_unit"] = item3.timeType;
                      }
                      if (item3.restType) {
                        data["rest_unit"] = item3.restType;
                      }
                      if (item3.tempo) {
                        data["tempo"] = item3.tempo;
                      }
                      return data;
                    });
                  }
                }

                return data;
              });
            } else {
              if (item.type == "emom") {
                enqueueSnackbar(
                  `please add a Movement on ${
                    ind + 1
                  } Interval in EMOM, EMOM placed on ${mainIn + 1} position.`,
                  {
                    variant: "error",
                  }
                );
              }
              if (item.type == "amrap") {
                enqueueSnackbar(
                  `please add a Movement in AMRAP,  placed on ${
                    mainIn + 1
                  } position.`,
                  {
                    variant: "error",
                  }
                );
              }

              isError = true;

              return;
            }
          });
        }
      }
      if (item.superSet) {
        data["sub_exercises"] = [];
        if (item.superSet.length > 0) {
          data["sub_exercises"] = item.superSet.map((item) => {
            if (isError) {
              return;
            }
            if (!item.exercise?._id) {
              enqueueSnackbar(
                `please select an exercise in Superset,  placed on ${
                  mainIn + 1
                } position.`,
                {
                  variant: "error",
                }
              );

              isError = true;
              return;
            }
            let data = {
              exercise_info: {
                _id: item.exercise?._id,
                title: item.exercise?.title,
                image: item.exercise?.image,
              },
              title: item.exercise?.title,
              description: item.description,
              parameters: item.parameters,
            };
            if (item.set) {
              data["sets"] = [];
              if (item.set.length > 0) {
                data["sets"] = item.set.map((item) => {
                  let data = {};
                  if (item.reps) {
                    data["reps"] = item.reps;
                  }
                  if (item.rest) {
                    data["rest"] = item.rest;
                  }
                  if (item.weight) {
                    data["weight"] = item.weight;
                  }
                  if (item.time) {
                    data["duration"] = item.time;
                  }
                  if (item.weightType) {
                    data["weight_unit"] = item.weightType;
                  }
                  if (item.distance) {
                    data["distance"] = item.distance;
                  }
                  if (item.distanceType) {
                    data["distance_unit"] = item.distanceType;
                  }
                  if (item.timeType) {
                    data["duration_unit"] = item.timeType;
                  }
                  if (item.restType) {
                    data["rest_unit"] = item.restType;
                  }
                  if (item.tempo) {
                    data["tempo"] = item.tempo;
                  }
                  return data;
                });
              }
            }

            return data;
          });
        }
      }
      return data;
    });
    if (isError) {
      setIsLoading(false);
      props.setIsLoading(false);
      return;
    }
    const formDataObject = {
      title: workoutTitle,
      workout_type: null,
      description: workoutDescription,
      status: true,
      exercises: exerciesArray,
      program_id: props.programme?._id,
      workout_day: props.day ? props?.day : 1,
      workout_date: moment(props.date).format("YYYY-MM-DD"),
    };
    console.log(formDataObject, "dslfkdjsaa");

    const result = await add_program_workout_api(formDataObject);
    if (result.code === 200) {
      props?.reloadList();
      setIsLoading(false);
      props.setIsLoading(false);
      props.setOpen(false);
      enqueueSnackbar(result.message, { variant: "success" });
      return formDataObject;
    } else {
      setIsLoading(false);
      props.setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleSubmitUpdate = async (e) => {
    e?.preventDefault();
    setIsLoading(true);
    props.setIsLoading(true);
    let isError = false;
    let exerciesArray = addArray.map((item, mainIn) => {
      console.log(item, "sdkfljkajs");
      if (
        item.type != "emom" &&
        item.type != "amrap" &&
        item.type != "something_else" &&
        !item.exercise?._id &&
        item.superSet.length == 0
      ) {
        enqueueSnackbar("please select an exercise", { variant: "error" });
        isError = true;
      }
      if (item.type == "something_else" && !item?.title) {
        enqueueSnackbar("please add name", {
          variant: "error",
        });
        isError = true;
      }
      let data = {
        exercise_info: {
          _id: item?.superSet[0]?.exercise?._id
            ? item?.superSet[0]?.exercise?._id
            : item.exercise?._id,
          title: item?.superSet[0]?.exercise?.title
            ? item?.superSet[0]?.exercise?.title
            : item.exercise?.title,
          image: item?.superSet[0]?.exercise?.image
            ? item?.superSet[0]?.exercise?.image
            : item.exercise?.image,
        },
        title: item?.title,
        description: item.description,
        parameters: item.parameters,
      };
      if (item?._id) {
        data["_id"] = item?._id;
      }
      if (item.superset_sets) {
        data["superset_sets"] = item.superset_sets;
      }
      if (item.rounds) {
        data["emom_rounds"] = item.rounds;
      }
      if (item.type) {
        data["exercise_type"] = item.type;
      }
      if (item.time_interval) {
        data["interval_duration"] = item.time_interval;
      }
      if (item.set) {
        data["sets"] = [];
        if (item.set.length > 0) {
          data["sets"] = item.set.map((item) => {
            console.log("dskjfjksjdklfja", item);
            let data = {};
            if (item.reps) {
              data["reps"] = item.reps;
            }
            if (item.rest) {
              data["rest"] = item.rest;
            }
            if (item.weight) {
              data["weight"] = item.weight;
            }
            if (item.time) {
              data["duration"] = item.time;
            }
            if (item.weightType) {
              data["weight_unit"] = item.weightType;
            }

            if (item.distance) {
              data["distance"] = item.distance;
            }
            if (item.distanceType) {
              data["distance_unit"] = item.distanceType;
            }
            if (item.timeType) {
              data["duration_unit"] = item.timeType;
            }
            if (item.restType) {
              data["rest_unit"] = item.restType;
            }
            if (item.tempo) {
              data["tempo"] = item.tempo;
            }
            return data;
          });
        }
      }
      if (item.type == "emom" || item.type == "amrap") {
        data["emom_amrap"] = [];
        if (item.emomSets.length > 0) {
          item.emomSets.map((item1, ind) => {
            console.log(item1, "sjdkfksjdka");
            if (item1.length > 0) {
              data["emom_amrap"][ind] = item1.map((item2) => {
                if (isError) {
                  return;
                }
                if (!item2.exercise?._id) {
                  if (item.type == "emom") {
                    enqueueSnackbar(
                      `please select an exercise in EMOM,  EMOM placed on ${
                        mainIn + 1
                      } position. `,
                      {
                        variant: "error",
                      }
                    );
                  }
                  if (item.type == "amrap") {
                    enqueueSnackbar(
                      `please select an exercise in AMRAP, AMRAP placed on ${
                        mainIn + 1
                      } position. `,
                      {
                        variant: "error",
                      }
                    );
                  }

                  isError = true;

                  return;
                }
                let data = {
                  exercise_info: {
                    _id: item2.exercise?._id,
                    title: item2.exercise?.title,
                    image: item2.exercise?.image,
                  },
                  title: item2.exercise?.title,
                  description: item2.description,
                  parameters: item2.parameters,
                };
                if (item2.set) {
                  data["sets"] = [];
                  if (item2.set.length > 0) {
                    data["sets"] = item2.set.map((item3) => {
                      let data = {};
                      if (item3.reps) {
                        data["reps"] = item3.reps;
                      }
                      if (item3.rest) {
                        data["rest"] = item3.rest;
                      }
                      if (item3.weight) {
                        data["weight"] = item3.weight;
                      }
                      if (item3.time) {
                        data["duration"] = item3.time;
                      }
                      if (item3.weightType) {
                        data["weight_unit"] = item3.weightType;
                      }
                      if (item3.distance) {
                        data["distance"] = item3.distance;
                      }
                      if (item3.distanceType) {
                        data["distance_unit"] = item3.distanceType;
                      }
                      if (item3.timeType) {
                        data["duration_unit"] = item3.timeType;
                      }
                      if (item3.restType) {
                        data["rest_unit"] = item3.restType;
                      }
                      if (item3.tempo) {
                        data["tempo"] = item3.tempo;
                      }
                      return data;
                    });
                  }
                }

                return data;
              });
            } else {
              if (item.type == "emom") {
                enqueueSnackbar(
                  `please add a Movement on ${
                    ind + 1
                  } Interval in EMOM, EMOM placed on ${mainIn + 1} position.`,
                  {
                    variant: "error",
                  }
                );
              }
              if (item.type == "amrap") {
                enqueueSnackbar(
                  `please add a Movement in AMRAP,  placed on ${
                    mainIn + 1
                  } position.`,
                  {
                    variant: "error",
                  }
                );
              }

              isError = true;

              return;
            }
          });
        }
      }
      if (item.superSet) {
        data["sub_exercises"] = [];
        if (item.superSet.length > 0) {
          data["sub_exercises"] = item.superSet.map((item) => {
            if (isError) {
              return;
            }
            if (!item.exercise?._id) {
              enqueueSnackbar(
                `please select an exercise in Superset,  placed on ${
                  mainIn + 1
                } position.`,
                {
                  variant: "error",
                }
              );

              isError = true;
              return;
            }
            let data = {
              exercise_info: {
                _id: item.exercise?._id,
                title: item.exercise?.title,
                image: item.exercise?.image,
              },
              title: item.exercise?.title,
              description: item.description,
              parameters: item.parameters,
            };
            if (item.set) {
              data["sets"] = [];
              if (item.set.length > 0) {
                data["sets"] = item.set.map((item) => {
                  let data = {};
                  if (item.reps) {
                    data["reps"] = item.reps;
                  }
                  if (item.rest) {
                    data["rest"] = item.rest;
                  }
                  if (item.weight) {
                    data["weight"] = item.weight;
                  }
                  if (item.time) {
                    data["duration"] = item.time;
                  }
                  if (item.weightType) {
                    data["weight_unit"] = item.weightType;
                  }
                  if (item.distance) {
                    data["distance"] = item.distance;
                  }
                  if (item.distanceType) {
                    data["distance_unit"] = item.distanceType;
                  }
                  if (item.timeType) {
                    data["duration_unit"] = item.timeType;
                  }
                  if (item.restType) {
                    data["rest_unit"] = item.restType;
                  }
                  if (item.tempo) {
                    data["tempo"] = item.tempo;
                  }
                  return data;
                });
              }
            }

            return data;
          });
        }
      }
      return data;
    });
    if (isError) {
      setIsLoading(false);
      props.setIsLoading(false);
      return;
    }
    const formDataObject = {
      title: workoutTitle,
      workout_type: null,
      description: workoutDescription,
      status: true,
      exercises: exerciesArray,
      workout_day: props.day ? props?.day : 1,
      workout_date: moment(props?.editData?.workout_date).format("YYYY-MM-DD"),
    };
    console.log(formDataObject, "dslfkdjsaa");

    const result = await update_program_workout_api(
      formDataObject,
      props?.editData?.program_workout_slug
    );
    if (result.code === 200) {
      props?.reloadList();
      setIsLoading(false);
      props.setIsLoading(false);
      props?.setOpen(false);

      enqueueSnackbar(result.message, { variant: "success" });
      return formDataObject;
    } else {
      setIsLoading(false);
      props.setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangeUp = (value) => {
    if (value == 0) {
      enqueueSnackbar("You are already on Top !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value - 1, 0, reorderedItem);
    setaddArray(items);
  };

  const handleSelectExercise = (value) => {
    const list = [...addArray];
    list[detailsData]["exercise"] = value;
    list[detailsData]["description"] = value?.instructions;
    setaddArray(list);
  };
  const handleChangeDown = (value) => {
    console.log(value, "fkdshjkfahdkjha", addArray.length);
    if (value >= addArray.length - 1) {
      enqueueSnackbar("You are already on Bottom !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value + 1, 0, reorderedItem);
    setaddArray(items);
  };

  useEffect(() => {
    // getExerciseList();
  }, [serchText]);
  useEffect(() => {
    if (props?.editData) {
      setWorkoutTitle(
        props?.editData?.workout_title
          ? props?.editData?.workout_title
          : props?.editData?.title
      );
      setWorkoutDescription(props?.editData?.description);
      setExercise(props?.editData?.exercises);
      let givenExercise = props?.editData?.exercises.map((item) => {
        let SubSet = item?.sub_exercises?.map((item) => {
          return {
            _id: item?._id,
            exercise: item?.exercise_info,
            set: item?.sets.map((item1) => {
              return {
                time: item1?.duration,
                weight: item1?.weight,
                weightType: item1?.weight_unit,
                reps: item1?.reps,
                rest: item1?.rest,
                distance: item1?.distance,
                distanceType: item1?.distance_unit,
                timeType: item1?.duration_unit,
                restType: item1?.rest_unit,
                tempo: item1?.tempo,
              };
            }),
            rounds: 10,
            emomSets: [[]],
            type: item.exercise_type ? item.exercise_type : "general",
            parameters: item?.parameters,
            description: item?.description,
            superSet: [],
          };
        });
        let emomAndAmrapSets = item?.emom_amrap?.map((item1, ind) => {
          let interval = item1?.map((item) => {
            return {
              _id: item?._id,
              exercise: item?.exercise_info,
              set: item?.sets.map((item1) => {
                return {
                  time: item1?.duration,
                  weight: item1?.weight,
                  weightType: item1?.weight_unit,
                  reps: item1?.reps,
                  rest: item1?.rest,
                  distance: item1?.distance,
                  distanceType: item1?.distance_unit,
                  timeType: item1?.duration_unit,
                  restType: item1?.rest_unit,
                  tempo: item1?.tempo,
                };
              }),

              parameters: item?.parameters,
              description: item?.description,
              superSet: [],
            };
          });
          return interval;
        });
        return {
          _id: item?._id,
          exercise: item?.exercise_info,
          set: item?.sets.map((item1) => {
            return {
              time: item1?.duration,
              weight: item1?.weight,
              weightType: item1?.weight_unit,
              reps: item1?.reps,
              rest: item1?.rest,
              distance: item1?.distance,
              distanceType: item1?.distance_unit,
              timeType: item1?.duration_unit,
              restType: item1?.rest_unit,
              tempo: item1?.tempo,
            };
          }),
          rounds: item.emom_rounds ? item.emom_rounds : 10,
          title: item.title,
          type: item.exercise_type ? item.exercise_type : "general",
          time_interval: item.interval_duration,
          emomSets: emomAndAmrapSets.length > 0 ? emomAndAmrapSets : [[]],
          description: item?.description,
          parameters: item?.parameters,
          superSet: SubSet,
          superset_sets: item?.superset_sets,
        };
      });
      setaddArray(givenExercise);
    }
  }, [props?.editData]);
  useEffect(() => {
    // Skip the first render
    if (!addArray.length > 0) {
      props?.setIsLoading(true);
    }
  }, [addArray]);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container" style={{ minWidth: "1000px" }}>
      <ToshowExercise
        open={openDetails}
        setOpen={setOpenDetails}
        handleChange={handleSelectExercise}
      />
      <div className=" ">
        <div className="row">
          {" "}
          <div className="col-10">
            {/* <h1 className="programmes-heading">{state?.title}</h1> */}
            <FormControl
              className={!isFocused ? classes.customInput : ""}
              sx={{ width: "70%" }}
            >
              <TextField
                id="outlined-basic"
                // label="Exercise"
                variant={"outlined"}
                onFocus={handleFocus}
                className="title-Test"
                onBlur={handleBlur}
                placeholder="Workout Name !"
                value={workoutTitle}
                name="workoutTitle"
                onChange={(e) => {
                  if (e.target.value.length <= 30) {
                    setWorkoutTitle(e.target.value);
                    props.setModalChangeExe(true);
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: "18px" },
                }}
                InputProps={{
                  readOnly:
                    props?.editData?.workout_loggers.length > 0 ? true : false,
                  style: {
                    fontSize: "32px",
                    fontWeight: "500",
                  },
                }}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },

                  fontSize: "13px",
                  // width: "10%",
                }}
              />
            </FormControl>
            <FormControl
              fullWidth
              className={!isFocused1 ? classes.customInput : ""}
            >
              <TextField
                id="outlined-basic"
                className="desc-Test"
                // label="Exercise"
                variant={"outlined"}
                onFocus={handleFocus1}
                onBlur={handleBlur1}
                placeholder="Workout description !"
                value={workoutDescription}
                multiline
                rows={3}
                name="workoutTitle"
                onChange={(e) => {
                  if (e.target.value.length <= 250)
                    setWorkoutDescription(e.target.value);
                  props.setModalChangeExe(true);
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: "18px" },
                }}
                InputProps={{
                  readOnly:
                    props?.editData?.workout_loggers.length > 0 ? true : false,

                  style: {
                    fontSize: "14px",
                    fontWeight: "500",
                    padding: 0,
                  },
                  maxLength: 10,
                }}
                sx={{
                  borderRadius: "5px",

                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  mb: 1,
                  // width: "10%",
                }}
              />
            </FormControl>
            {/* <div className="col-12 section-space">
            <p>
              <div
                dangerouslySetInnerHTML={{
                  // __html: lessonDetailInfo?.detailed_description,
                  __html: state?.description,
                }}
              ></div>
            </p>
          </div> */}
          </div>
          {/* <div className="col-2 d-flex justify-content-end">
            <div className="edit-workout-icon ">
              <Avatar
                alt={"I"}
                variant="square"
                src={previews ? previews : s3baseUrl + oldProfile}
                sx={{ width: 120, height: 120 }}
              >
                <div className="fs-1">{workoutTitle.charAt(0)}</div>
              </Avatar>
              <label htmlFor="icon-button-file">
                <Input
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  name="profile_image"
                  onChange={(e) => handleUpload(e)}
                />
                <IconButton
                  className="edit-profile-icon-position"
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                ></IconButton>
              </label>

              <span
                // className="small-contained-button"
                style={{
                  position: "absolute",
                  bottom: "36%",
                  right: "26%",
                  // backgroundColor: "gray",
                  // borderRadius: "50%",
                  // padding: "4px 5px",
                }}
              >
                <PhotoCamera
                  sx={{ width: 55, height: 55 }}
                  className="pointer camera-Workout"
                  // ref={anchorRef}
                  onClick={() => myInputRef.current.click()}
                />
                <input
                  hidden
                  type="file"
                  ref={myInputRef}
                  onChange={handleUpload}
                />
              </span>
            </div>
          </div> */}
        </div>

        <DragDropContext
          onDragEnd={handleOnDragEnd}
          className="h-100"
          isDragDisabled={true}
        >
          <Droppable droppableId="characters" isDragDisabled={true}>
            {(provided) => (
              <div
                className="characters"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {/* <form onSubmit={handleSubmit}> */}

                <form onSubmit={() => {}}>
                  {props?.editData?.workout_loggers.length > 0 ? (
                    <WorkoutExercisesUILogedClient
                      addArray={addArray}
                      setaddArray={setaddArray}
                      setModalChangeExe={props.setModalChangeExe}
                      message={
                        "Because member have responded, that is why you can't edit it."
                      }
                    />
                  ) : (
                    <>
                      {addArray.length > 0 ? (
                        <>
                          <WorkoutExercisesUI
                            addArray={addArray}
                            setaddArray={setaddArray}
                            setModalChangeExe={props.setModalChangeExe}
                          />
                        </>
                      ) : (
                        <div className="text-end container my-4">
                          <button
                            className="small-contained-button "
                            onClick={() => {
                              handleAdd();
                            }}
                          >
                            Add Exercise
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </form>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
});
export default AddWorkoutCalender;
